.tecnodid-registration {
  padding: 20px;
  border-radius: 25px;
  background-color: $bg-color;

  &-head {
    text-align: center;

    i {
      font-size: 64px;
      color: $light-blue;
    }
  }

  .widget { width: 100%; }

  label,
  .label {
    width: 100%;
    font-weight: 400 !important;
    color: black;
  }

  input {
    margin-bottom: 20px;
    padding-left: 15px;
    width: 100%;
    border: solid 1px gray;
    min-height: 40px;
    border-radius: 10px;
  }

  .single-checkbox-bool-widget {
    min-height: 20px;
    width: 20px !important;
  }

  .tecnodid-form > p {
    display: none;
  }

  .formControls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  #form-buttons-completa {
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 18px;
    color: white;
    line-height: 1.3333333;
    background-color: #03a87c;
    border-color: #03a87c;
  }

  #form-buttons-annulla {
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 18px;
    color: white;
    line-height: 1.3333333;
    background-color: #88a6d4;
    border-color: #88a6d4;
  }

  .error {
    margin-top: -20px;
    color: red;
  }
}
