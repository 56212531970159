//mobile
//menu on bottom animation
@keyframes megamenuFadeIn {
  0% {
    margin-top: $dropdown-menu-vertical-shift;
    opacity: 0;
  }

  100% {
    margin-top: $dropdown-menu-vertical-shift;
    opacity: 1;
  }
}

.header {
  &-social {
    font-size: 0.8em;
  }

  .list-inline {
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

    .miur {
      margin-left: 1.85em;
      font-size: 0.83em;

      a {
        display: block;
        cursor: pointer;
        color: $blue;
        transform: scale(1);
        transition: all 200ms ease-in;

        &:hover {
          box-shadow: 0 0 11px #eaf0f6;
          transform: scale(1.05);
          transition: all 200ms ease-in;
        }
      }
    }

    .dircard {
      margin-left: 1.85em;

      a {
        display: block;
        cursor: pointer;
        transform: scale(1);
        transition: all 200ms ease-in;

        &:hover {
          box-shadow: 0 0 11px #eaf0f6;
          transform: scale(1.05);
          transition: all 200ms ease-in;
        }
      }

      img {
        height: auto;
        max-width: 200px;
      }
    }
  }
}

.carta-docenti {
  font-size: 0.85em;
  color: $blue;
  text-align: left;

  a {
    padding: 0.55em 2em;
    display: block;
    border-radius: 25px;
    cursor: pointer;
    color: $white;
    background: #06c;
    transform: scale(1);
    transition: all 200ms ease-in;

    &:hover {
      box-shadow: 0 0 11px #eaf0f6;
      transform: scale(1.05);
      transition: all 200ms ease-in;
    }
  }

  img {
    padding-left: 10px;
    height: auto;
    max-width: 100px;
  }
}

.logo-container {
  align-items: center !important;
  align-self: center !important;
}

.navbar {
  z-index: 1;
  color: lighten($blue, 20%);
  background-color: $white !important;

  .navbar-toggler {
    font-size: 0.7em;
  }
  // navbar mobile
  #navbarNavB {
    z-index: 10;
    padding: 0;
    // menu vrapper
    .menu-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: $navigation-h-padding;
      pointer-events: all;
      font-size: 0.7em;
      font-weight: 700;
      text-transform: uppercase;
      background-color: $white !important;
      overflow-y: auto;
    }

    /*lista menu*/
    .navbar-nav {
      //margin-top:$navigation-links-margin-top;
      padding: 0 !important;
      flex-wrap: wrap;

      li {
        a.nav-link {
          position: relative;
          padding: 1em !important;
          font-size: 0.735rem;
          font-weight: 400;
          color: lighten($blue, 20%);
          line-height: 30px;
          text-transform: uppercase;

          &.active {
            color: $blue;
            border-left: $navigation-link-active-bar-size * 2 solid green;
          }
        }
      }
    }

    &.expanded {
      .menu-wrapper {
        transform: translateX(0);
      }

      .close-div {
        transform: translateX(0);
      }
    }
  }

  .close-div {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    left: $navigation-h-padding * 2;
    padding: $navigation-h-padding;
    background-color: $white !important;
    transform: translateX(100%);
    //- transizione de definire
    transition: all 0.3s;
    // pulsante di chiusura menu
    .close-menu {
      padding: 0;
      font-size: $navigation-close-button-text-size;
      color: $blue !important;
      color: $navigation-close-button-text-color;
      text-align: center;
      text-transform: uppercase;
      background: transparent;

      span {
        display: block;
        font-size: $navigation-close-button-icon-size;
        text-align: center;

        &::before {
          margin-right: 0;
        }
      }
    }
  }
  // Dropdown style tipo collapse
  .dropdown-menu {
    box-shadow: none;
    background: transparent;

    &::before {
      display: none;
    }

    .link-list-wrapper {
      padding-right: $navigation-h-padding;
      padding-left: $navigation-h-padding;

      ul {
        li {
          a {
            span {
              color: $white;
            }

            h3 {
              color: $white;
            }
          }

          h3 {
            color: $white;
          }
        }
      }
    }
  }

  .dropdown-toggle {
    display: flex;
    justify-content: space-between;

    &::after {
      content: '\e818';
      margin-right: 0.2em;
      display: inline-block;
      width: auto;
      height: auto;
      font-family: 'italia-icon-font';
      font-size: $navigation-dropdown-icon-size;
      font-weight: normal;
      font-style: normal;
      line-height: 1em;
      line-height: $navigation-dropdown-icon-line-height;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
      vertical-align: auto;
      transform-origin: center;
      transition: all 0.3s;
      speak: none;
      font-variant: normal;
      border-top: none;
      border-right: none;
      border-bottom: 0;
      border-left: none;
    }

    &[aria-expanded='true']::after {
      transform: scaleY(-1);
    }
  }
}

.custom-navbar-toggler {
  border: none;
  color: $color-primary;
  background: none;

  span {
    font-size: $navigation-toggle-button-icon-size;
    color: $color-primary;
  }
}
//-------------------INLINE MENU---------------------
.inline-menu {
  .link-list-wrapper {
    .link-list {
      position: relative;

      li {
        a {
          position: relative;

          i {
            color: $primary-a3;
          }

          &::after {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: $inline-menu-line-width;
            height: 100%;
            background: $color-primary;
            transform: scaleY(0);
            transform-origin: center;
            transition: all 0.3s;
          }

          &.active {
            span {
              color: $color-primary;
              text-decoration: none;
            }
          }

          &[aria-expanded='true'] {
            &::after {
              transform: scaleY(1);
            }
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: $inline-menu-line-width;
        height: 100%;
        background: linear-gradient(to bottom, rgba($neutral-2-a1,1) 0%,rgba($neutral-2-a1,0.3) 100%);
      }
    }
  }
}
//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    padding: 0;
    background: $navigation-bg-color;
    // navbar mobile
    .navbar-collapsable {
      z-index: auto;
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      display: block !important; //-sovrascrive l'inline style di jquery
      background: none;
      transition: none;
      // menu vrapper
      .menu-wrapper {
        position: inherit;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        transform: none;
        transition: none;
        overflow-y: visible;
      }
      /*lista menu*/
      .navbar-nav {
        margin-top: 0;
        padding: 0 $navigation-h-padding;

        li {
          a.nav-link {
            font-weight: 400;
            text-transform: uppercase;

            &::after {
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              margin: 0 auto;
              width: 0;
              height: 3px;
              background-color: transparent;
              transform: translate3d(0,0,0);
              transition: width 0.2s ease;
            }

            &:hover {
              &::after {
                width: 100%;
                background-color: $color-primary;
              }
            }

            &.active {
              border-left: 0;
            }

            &.disabled {
              opacity: $navigation-disabled-item-opacity;
              cursor: not-allowed;
            }
          }

          &.active {
            a::after {
              width: 100%;
              background-color: $color-primary;
            }
          }
        }
      }
    }
    //wrapper pulsante di chiusura menu
    .close-div {
      display: none;
    }
    // Dropdown style tipo collapse
    .dropdown-menu {
      @include border-radius($dropdown-border-radius);
      @include box-shadow($dropdown-box-shadow);
      z-index: $zindex-dropdown;
      float: left;
      position: absolute;
      top: 100%;
      left: 0;
      margin: $dropdown-spacer 0 0; // override default ul
      padding: $dropdown-padding-y 0;
      list-style: none;
      border: $dropdown-border-width solid $dropdown-border-color;
      font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
      color: $body-color;
      text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
      background-color: $dropdown-bg;
      min-width: $dropdown-min-width;
      background-clip: padding-box;

      &.show {
        top: calc(100% - #{$dropdown-menu-vertical-shift});
        animation: dropdownFadeIn forwards $dropdown-menu-animation-speed;
      }

      &::before {
        display: block;
      }

      .link-list-wrapper {
        padding-right: 0;
        padding-left: 0;

        ul {
          li {
            a {
              span {
                color: $color-primary;
              }

              h3 {
                color: $color-primary;
              }
            }

            h3 {
              color: $body-color;
            }
          }
        }
      }
    }

    .dropdown-toggle {
      display: block;
      justify-content: inherit;

      &::after {
        content: $dropdown-custom-button-caret-content;
        display: inline-block;
        width: auto;
        height: auto;
        border: none;
        font-family: 'italia-icon-font';
        font-size: $dropdown-custom-button-caret-font-size;
        font-weight: normal;
        font-style: normal;
        vertical-align: 0;
        transition: transform $dropdown-menu-animation-speed;
        speak: none;
      }
    }
    //--megamenu
    &.megamenu {
      .navbar-collapsable {
        width: 100%;

        .nav-item {
          position: static;

          a {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: auto;
              bottom: -$dropdown-menu-notch-base-size - 6;
              left: $dropdown-menu-notch-position-x;
              width: $dropdown-menu-notch-base-size*3;
              height: $dropdown-menu-notch-base-size*3;
              border-radius: $dropdown-menu-radius;
              opacity: 0;
              background-color: $dropdown-bg;
              transform: rotate(45deg);
              transition: opacity $dropdown-menu-animation-speed;
            }
          }

          &.show {
            a {
              &::before {
                opacity: 1;
              }
            }
          }
        }

        .dropdown-menu {
          right: $navigation-h-padding;
          left: $navigation-h-padding;
          min-width: auto;
          //hide arrow
          &::before {
            display: none;
          }

          &.show {
            animation: megamenuFadeIn forwards $dropdown-menu-animation-speed;
          }
        }
      }
    }
  }

  .custom-navbar-toggler {
    display: none;
  }
}

nav.pagination.tecnodid-nextprevious {
  ul {
    margin-top: 1em;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
    width: 100%;

    li {
      display: inline-block;
      flex: 1;

      &.previous a::before {
        content: '\00ab';
      }

      &.next { text-align: right; }

      &.next a::after {
        content: '\00bb';
      }
    }
  }
}

.collapse.in{ display: block; }
