.autocomplete-clear {
  z-index: 2;
  position: absolute;
  top: 0.5rem;
  right: 0.25rem;
  border: none;
  visibility: hidden;
  cursor: pointer;
  background: transparent;

  svg {
    fill: #a6a6a6;
  }
}

.autocomplete-wrap {
  z-index: 100;
  position: absolute;
  right: 0;
  left: 0;
  padding-left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25); // TODO use a variable
  background: $white;
  max-height: 240px;
  overflow-y: auto;
  list-style-type: none;

  li {
    padding: 12px 16px;
    cursor: pointer;
    font-size: 0.875rem;

    &:hover {
      background: rgb(238, 238, 238); // TODO use a variable
    }
  }
}
