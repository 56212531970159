.cookiebar {
  z-index: $zindex-cookiebar;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;

  &.cookiebar-top {
    top: 0;
    bottom: auto;
  }

  &.show {
    display: block;
  }
}
