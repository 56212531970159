::-moz-selection {
  color: $white;
  background-color: $red;
}

::selection {
  color: $white;
  background-color: $red;
}

html,
body {
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 1.555;
  }
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: $blue;
  letter-spacing: 0;
  line-height: 1.6;
  background-color: $bg-color;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern';
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

//
// Intestazioni
//

h1,
.h1 {
  @include media-breakpoint-up(sm) {
    font-size: 2.666rem;
    line-height: 1.25;
  }
  font-family: $font-family-serif;
  font-size: 2rem;
  color: $blue;
}

h2,
.h2 {
  @include media-breakpoint-up(sm) {
    font-size: 2.222rem;
    line-height: 1.2;
  }
  font-family: $font-family-serif;
  font-size: 1.3em;
  color: $blue;
  line-height: 1.25;
}

h3,
.h3 {
  @include media-breakpoint-up(sm) {
    font-size: 1.777rem;
    line-height: 1.25;
  }
  font-family: $font-family-serif;
  font-size: 1.2em;
  font-weight: 600;
  color: $blue;
  line-height: 1.1428;
}

h4,
.h4 {
  @include media-breakpoint-up(sm) {
    font-size: 1.555rem;
    font-weight: 600;
    line-height: 1.428;
  }
  font-family: $font-family-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: $blue;
  line-height: 1.1666;
}

h5,
.h5 {
  @include media-breakpoint-up(sm) {
    font-size: 1.333rem;
  }
  font-family: $font-family-serif;
  font-size: 1.05em;
  font-weight: 400;
  color: $blue;
}

h6,
.h6 {
  font-family: $font-family-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
}

a {
  color: $color-primary;
}

.documentFirstHeading {
  display: block;
  width: 100%;
  font-family: $font-family-serif;
  font-size: 2rem;
  font-weight: 600;
  color: $blue;
  line-height: 1.25;

  a {
    position: relative;
    display: block;
    width: 100%;
    color: $blue;

    &::after {
      content: '\e80e';
      position: absolute;
      top: 10px;
      right: 0.5em;
      display: inline-block;
      width: 1em;
      font-family: 'italia-icon-font';
      font-size: 0.8rem;
      color: $color-primary;
      speak: none;
    }
  }
}

//
// Intestazioni in evidenza
//

.display-1 {
  @include media-breakpoint-up(sm) {
    font-size: 3.111rem;
    line-height: 1.428;
  }
  font-size: 3.111rem;
}

blockquote,
.blockquote {
  margin: 1.5rem 0;
  font-family: $font-family-serif;
}

caption {
  font-size: 0.777rem;
  line-height: 1.4285;
}

b,
strong {
  font-weight: 600;
}

small,
.small {
  @include media-breakpoint-up(sm) {
    font-size: 0.8888rem;
  }
  font-size: 0.9375rem;
  font-weight: $font-weight-normal;
}

.x-small {
  @include media-breakpoint-up(sm) {
    font-size: 0.7777rem;
  }
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
}

.blockquote-footer {
  color: $blockquote-small-color;

  .bg-dark & {
    color: $blockquote-small-color-dark;
  }
}

.bg-light-green {
  background-color: #d7f1ea;
}

.badge-primary {
  margin-bottom: 7px;
  color: $white;
}
