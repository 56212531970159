#top {
  z-index: 4;
  position: sticky;
  position: -webkit-ms-sticky;
  position: -webkit-o-sticky;
  position: -webkit-sticky;
  top: 0;
  height: 60px;

  .left {
    background-color: $light-grey;
    border-bottom: solid 1px grey;

    > div {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;

      .title {
        font-size: larger;
        font-weight: 500;
        color: $secondary-color;
        text-transform: capitalize;
      }
    }
  }

  .rigth {
    background-color: #e3e7f3;
    border-bottom: solid 1px grey;

    > div {
      padding-right: 10%;
      display: flex;
      justify-content: flex-end;
      font-weight: 400;
      color: $secondary-color;

      > span {
        margin-left: 20px;
        line-height: 60px;
      }
    }
  }
}

.safari {
  #top {
    position: relative;
  }

  .left.menu {
    position: relative;
    top: 0;
    height: 100%;
  }

  #bottom {
    margin-bottom: 1em;
  }
}

#bottom {
  .left {
    .box {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 60px;
    }

    .tab-pane ul li {
      border-bottom: none;
    }
  }
}

#wishlist,
#comment {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 5px;
  font-size: 18px;
  background-color: $light-grey;

  > .title {
    font-weight: 500;
    text-transform: capitalize;

    + i {
      margin-right: 0;
      padding-right: 0;
      font-weight: 600;
      min-width: 7vw;
      text-align: right;
    }
  }
}

#ipertesto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;

  > .title {
    font-weight: 500;
    text-transform: capitalize;
  }

  .fa {
    margin-right: 0;
    padding-right: 0;
    min-width: 7vw;
    text-align: right;
  }
}

#search-norme {
  margin-bottom: 0;
  padding: 20px;

  .tab-container {
    margin: 20px 0 0;
  }

  .tab-pane {
    padding: 1em 5px;
  }

  &.contract {
    height: 60px;
    overflow: hidden;
  }
}

.closed {
  #bottom .left .box {
    height: 80px;
    overflow: hidden;
    box-sizing: border-box;

    .title {
      display: none;
    }
  }

  #ipertesto {
    padding-bottom: 34px;

    .fa.search {
      padding-left: 4px;
      line-height: 33px;
    }
  }
}

.fade {
  @include transition($transition-fade);

  &:not(.show),
  &:not(.in) {
    opacity: 0;
  }

  &:not(.show).in,
  &:not(.in).show {
    opacity: 1;
  }
}

#dashboard {
  opacity: 1;

  #top {
    .title-responsive {
      display: none;
    }
  }

  &.open {
    #top {
      .left {
        flex: 0 0 20%;
        max-width: 20%;
      }

      .rigth {
        flex: 0 0 80%;
        max-width: 80%;
      }
    }

    #bottom {
      .left {
        flex: 0 0 20%;
        max-width: 20%;
      }

      .rigth {
        flex: 0 0 70%;
        max-width: 70%;
      }
    }
  }

  &.closed {
    #top {
      .left {
        flex: 0 0 10%;
        max-width: 10%;
      }

      .rigth {
        flex: 0 0 90%;
        max-width: 90%;
      }
    }

    #bottom {
      .left {
        flex: 0 0 10%;
        max-width: 10%;
      }

      .rigth {
        flex: 0 0 80%;
        max-width: 80%;
      }
    }
  }

  .fa,
  .fas,
  .far {
    cursor: pointer;
  }

  #top .left .fas {
    font-size: 1.5rem;
  }

  .text {
    min-height: 576px;
    position: relative;
    margin: 20px;
    padding: 40px;
    background-color: white;
  }

  .locked {
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255,255,255,1));
    }

    &-popup-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 12vh;
      background: rgb(255,255,255);
      background-image: linear-gradient(180deg, rgba(255,255,255, 0.5) 0%, rgba(245,248,250,1) 44%);

      > div {
        margin: 20px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: solid 1px #b0c0d0;
        border-radius: 8px;
        background: rgb(255,255,255);
        background-image: linear-gradient(180deg, rgba(255,255,255, 0.5) 0%, rgba(245,248,250,1) 54%);

        p {
          font-weight: 500;
        }
      }
    }
  }
  // giulio
  /*formattazione per le norme*/
  .evidenzia {
    font-weight: bold;
  }

  .strutture {
    margin-top: 25px;
    font-weight: bold;
    text-align: center;
  }

  .articolo {
    margin-top: 25px;
    text-indent: 0 !important;
  }

  .section-legislazione-e-dottrina #content-core p {
    text-align: justify;
    text-indent: 15px;
  }

  .section-legislazione-e-dottrina .documentDescription {
    font-weight: bold;
  }

  .select-normative {
    max-width: 19vw;
    font-size: 10px;

    option {
      max-width: 50vw;
    }
  }

  /*tabelle norme*/

  table.tab1 {
    border-collapse: collapse !important;
    border: 1px solid grey !important;
    background-color: #ffffff !important;
  }

  table.tab1 td {
    padding: 4px !important;
    border: 1px dotted darkgray !important;
  }

  table.tab1 th {
    padding: 5px !important;
    border: 1px solid gray !important;
    font-weight: bold !important;
    background-color: #eeeeff !important;
  }

  table.tab1 tr:hover {
    color: #111 !important;
    background: #eaecee !important;
  }

  table.tab1 p {
    margin: 0 !important;
  }

  table.tab2 {
    border-collapse: collapse !important;
    margin-bottom: 20px;
    border: 1px solid grey !important;
    font-size: 8pt;
    background-color: #ffffff !important;
  }

  table.tab2 td {
    padding: 1px !important;
    border: 1px dotted darkgray !important;
  }

  table.tab2 th {
    padding: 2px !important;
    border: 1px solid gray !important;
    font-weight: bold !important;
    background-color: #eeeeff !important;
  }

  table.tab2 tr:hover {
    color: #111 !important;
    background: #eaecee !important;
  }

  table.tab2 p {
    margin: 0 !important;
  }

  a.linknormativa {
    border-bottom: 1px dotted navy;
    background: #f5fffa;
  }

  a.linknormativa:hover {
    color: black;
    background-color: yellow !important;
  }

  .rifrep {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 11px;
    text-indent: 0;
    background-color: #fff;
    border-left: 1px solid #ffa500;
    border-bottom: 5px solid #ffa500;
  }

  .rif_nota {
    font-size: 70%;
    vertical-align: super;
  }

  .notandr {
    font-size: 80%;
  }

  .note {
    border-top: 1px solid gray;
    padding-top: 10px;
    font-size: 80%;
  }

  .note p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .nota {
    margin-top: 10px;
  }

  .corpo_abrogato {
    border-left: 2px solid red;
    padding-left: 10px;
  }
}

/* .section-legislazione-e-dottrina main, */
.legislazione main {
  z-index: 3;
  position: sticky;
  position: -webkit-ms-sticky;
  position: -webkit-o-sticky;
  position: -webkit-sticky;

  main {
    margin-bottom: 150px;
  }

  .documentFirstHeading { padding-top: 20px; }
}

.left.menu {
  z-index: 3;
  position: sticky;
  top: 60px;
  padding: 0 6px 20px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  #articolo-per-estremi-selected,
  .search-results {
    padding: 0;
    list-style: none;

    > li {
      border-top: solid 1px gray;
      padding: 10px 0;
      font-size: 16px;
      line-height: 125%;
    }

    a.active {
      padding-left: 5px;
      display: block;
      width: 100%;
      border-radius: 5px;
      color: white;
      background-color: #03a87c;
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.in .nav-link,
  .nav-tabs .nav-item.active .nav-link {
    background-color: #fff;
    border-color: #b1b1b3 #b1b1b3 #fff;
  }

  .paginate-pagination ul {
    padding: 0;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
    list-style: none;

    a {
      margin-left: 10px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: transparent;
      background-color: white;

      &.active {
        background-color: $secondary-color;
      }
    }

    > li:first-child,
    > li:last-child {
      display: none;
    }
  }
}

#search-result {
  margin: 20px 0;
  display: block;
  font-size: 21px;
  font-weight: 600;
}

/*media queries*/
// > 1001 & < 1400
@media screen and (max-width: 1400px) {
  #dashboard {
    #top {
      .title-responsive {
        display: none;
      }

      .title {
        display: block;
      }
    }

    &.open {
      #top {
        .left {
          flex: 0 0 30%;
          max-width: 30%;
        }

        .rigth {
          flex: 0 0 70%;
          max-width: 70%;
        }
      }

      #bottom {
        .left {
          padding: 0;
          flex: 0 0 30%;
          max-width: 30%;
        }

        .rigth {
          flex: 0 0 70%;
          max-width: 70%;
        }
      }
    }

    &.closed {
      #top {
        .left {
          flex: 0 0 10%;
          max-width: 10%;
        }

        .rigth {
          flex: 0 0 90%;
          max-width: 90%;
        }
      }

      #bottom {
        .left {
          padding: 0;
          flex: 0 0 10%;
          max-width: 10%;
        }

        .rigth {
          flex: 0 0 90%;
          max-width: 90%;
        }
      }
    }
  }
}
//  < 1001
@media screen and (max-width: 1000px) {
  #dashboard {
    .rigth,
    .left {
      transition: visibility 0s, opacity 0.5s linear;
    }

    .content > .container-fluid {
      padding-right: 0;
    }

    #top {
      .title {
        display: none;
      }

      .title-responsive {
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        color: $secondary-color;
        line-height: 1;
        text-shadow: 0 1px 0 #fff;
      }
    }

    &.open {
      #top {
        .left {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .rigth {
          height: 0;
          visibility: hidden;
          opacity: 0;
        }
      }

      #bottom {
        .left {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .rigth {
          display: none;
          /*
          display none qui, evita che la pagina abbia una lunghezza ingiustificata
          ma annulla l'effetto della transizione alla chiusura della sidebar
          */
          height: 0;
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    &.closed {
      #top {
        .rigth {
          height: 0;
          visibility: hidden;
          opacity: 0;
        }

        .left {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      #bottom {
        .left {
          height: 0;
          visibility: hidden;
          opacity: 0;
        }

        .rigth {
          padding-left: 0%;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}
