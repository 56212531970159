@include media-breakpoint-down(sm) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 1em;
    width: 100%;
    background-color: $gray-800;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;

    &.open {
      transform: translateX(-1rem);
    }
  }
}

