//mobile
.skiplinks {
  text-align: center;
  background-color: $analogue-2-b1;

  a {
    padding: $skiplink-padding-y $skiplink-padding-x;
    display: inline-block;
    display: block;
    font-weight: 600;
    color: $neutral-1-a7;
    text-decoration: underline;
  }
}
