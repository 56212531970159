.breadcrumb-container {
  .breadcrumb {
    padding: 0;
    border-radius: $breadcrumb-border-radius;
    font-size: 0.7rem;

    .breadcrumb-item {
      padding-left: 0;

      & +.breadcrumb-item::before {
        display: none;
      }

      i {
        padding-right: $breadcrumb-link-x-padding;
      }

      a {
        font-weight: $breadcrumb-link-font-weight;
        color: $breadcrumb-link-color;
      }

      &.active {
        a {
          pointer-events: none;
          font-weight: normal;
        }
      }

      span.separator {
        padding: 0 $breadcrumb-link-x-padding;
        display: inline-block;
        font-weight: $breadcrumb-link-font-weight;
      }
    }
    // dark version
    &.dark {
      background: $breadcrumb-bg-dark;

      .breadcrumb-item {
        a {
          color: $breadcrumb-link-color-dark;
        }

        span.separator {
          color: $breadcrumb-link-color-dark;
        }

        i {
          color: $breadcrumb-icon-color-dark;
        }
      }
    }
  }
}
