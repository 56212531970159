.footer {
  padding-top: 1em;
  font-size: 0.75em;
  color: #a2b2c3;
  background: $blue;
  background-image: -webkit-gradient(linear,left top, left bottom,from($blue),to(#345981))!important;
  background-image: linear-gradient($blue,#345981)!important;
  background-image: -webkit-gradient(linear,left top, right top,from($blue),to(#345981))!important;
  background-image: linear-gradient(to right,$blue,#345981)!important;

  h5 {
    color: $white;
  }

  h6 {
    margin-bottom: 10px !important;
    color: $white;
    letter-spacing: 1px;
  }

  .title {
    font-size: 1.1em;
    font-weight: 500;
  }

  p.colophon {
    text-align: right;
  }

  .list-group-item {
    padding: 0.45em 0 0.45em 1.45em;
    border: none;
    background-color: transparent;

    a {
      border: none;
      opacity: 0.75;
      color: $light-blue;

      &:hover {
        opacity: 1;
      }
    }
  }

  a {
    color: $white;
  }

  .col.my-3 {
    padding: 0 3em 0 0;
  }

  .list-inline {
    margin-top: 1em;

    &.info {
      text-align: right;
    }

    &-item {
      &::after {
        content: '/';
        padding-left: 0.5em;
        width: 100%;
        height: 100%;
        color: $color-primary;
      }

      &:last-child {
        margin-top: 1em;

        &::after {
          display: none;
        }
      }
    }
  }
}

.it-chevron-right {
  color: $color-primary;
}

.bd-footer {

  @include media-breakpoint-up(sm) {
    text-align: left;
  }
  color: $white;

  .footer-social {
    font-size: 0;

    & [class^='it-'] {
      font-size: initial;
    }
  }

  .team-logo {
    &--icon {
      width: 72px;
    }

    &--text {
      font-size: 16px;
      line-height: 1rem;
      max-width: 160px;
      text-transform: uppercase;
    }
  }

  .small-prints {
    font-weight: 600;
    color: #00fff8;
  }
}
