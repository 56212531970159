// HSB (https://it.wikipedia.org/wiki/Hue_Saturation_Brightness, coincidente con il modello HSV)
// è lo spazio colore scelto per ottenere le varianti colore della tonalità primaria.
// Color system
$white: rgb(255, 255, 255) !default;
$gray-100: hsb(225, 0, 90) !default;
$gray-200: hsb(225, 0, 80) !default;
$gray-300: hsb(225, 0, 70) !default;
$gray-400: hsb(225, 0, 60) !default;
$gray-500: hsb(225, 0, 50) !default;
$gray-600: hsb(225, 0, 40) !default;
$gray-700: hsb(225, 0, 30) !default;
$gray-800: hsb(225, 0, 20) !default;
$gray-900: hsb(225, 0, 10) !default;

$black: rgb(0, 0, 0) !default;

$grays: () !default;
$grays: map-merge((
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900
), $grays);

@function main($key: '80') {
  @return map-get($mains, $key);
}

$italia:  hsb(158, 85, 36) !default;
$indigo:  hsb(243, 70, 100) !default;
$purple:  hsb(243, 40, 100) !default;
$pink:    hsb(350, 30, 100) !default;
$red:     hsb(0, 66, 83) !default;
$orange:  hsb(0, 64, 89) !default;
$yellow:  hsb(38, 68, 98) !default;
$green:   hsb(159, 100, 80) !default;
$teal:    hsb(178, 95, 85) !default;
$cyan:    hsb(214, 16, 74) !default;
$blue:    hsb(211, 46, 36) !default; // #32475d
$light:   hsb(255, 5, 95) !default;
$dark:    hsb(210, 70, 30) !default; // #17324D
$bg-color: #f5f8fa;
$light-blue: #cbd6e2;
$secondary-color: #03a87c;
$light-grey: #d6dfe9;

$gray-primary:          hsb(210, 38.9, 44.3) !default; // #455b71
$gray-secondary:        hsb(210, 29, 51) !default; // #5c6f82
$gray-tertiary:         hsb(205, 35, 54) !default; // #5A768A
$gray-quaternary:       hsb(238, 1, 100) !default;
$gray-disabled:         hsb(225, 5, 95) !default;
$gray-label-disabled:   hsb(222, 5, 79) !default;
$gray-border-disabled:  hsb(224, 8, 95) !default;

$colors: () !default;
$colors: map-merge((
  'italia': $italia,
  'bg-color': $bg-color,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
  'blue': $blue,
  'light-blue': $light-blue,
  'gray-secondary': $gray-secondary,
  'gray-tertiary': $gray-tertiary,
  'gray-quaternary': $gray-quaternary
), $colors);

// Main Palette
$primary:     #12b961 !default;
$secondary:   $gray-secondary !default;

// Info Colors
$success:     $green !default;
$info:        $gray-400 !default;
$warning:     $orange !default;
$danger:      $red !default;

$theme-colors: () !default;
$theme-colors: map-merge((
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900
), $theme-colors);

// PRIMARY COLORS DEFINITION //
$primary-h : 164;
$primary-s : 98;
$primary-b : 66;

$color-primary : hsb($primary-h, $primary-s, $primary-b) !default;
$white : hsb(255, 255, 255) !default;

// ACCENT COLORS DEFINITIONS
// analoghi
$analogue-1-h : 146;
$analogue-1-s : 70;
$analogue-1-b : 72;
$analogue-1 : hsb($analogue-1-h, $analogue-1-s, $analogue-1-b) !default;
$analogue-2-h : 178;
$analogue-2-s : 95;
$analogue-2-b : 85;
$analogue-2 : hsb($analogue-2-h, $analogue-2-s, $analogue-2-b) !default;
// Complementari e triadici
$complementary-1-h : 200;
$complementary-1-s : 86;
$complementary-1-b : 62;
$complementary-1 : hsb($complementary-1-h, $complementary-1-s, $complementary-1-b) !default;
$complementary-2-h : 199;
$complementary-2-s : 96;
$complementary-2-b : 42;
$complementary-2 : hsb($complementary-2-h, $complementary-2-s, $complementary-2-b) !default;
$complementary-3-h : 340;
$complementary-3-s : 100;
$complementary-3-b : 81;
$complementary-3 : hsb($complementary-3-h, $complementary-3-s, $complementary-3-b) !default;

// NEUTRAL COLORS DEFINITIONS
$neutral-1-h : 154;
$neutral-1-s : 60;
$neutral-1-b : 28;
$neutral-1 : hsb($neutral-1-h, $neutral-1-s, $neutral-1-b) !default;
$neutral-2-h : 210;
$neutral-2-s : 5;
$neutral-2-b : 95;
$neutral-2 : hsb($neutral-2-h, $neutral-2-s, $neutral-2-b) !default;

// Light Greys A
$lightgrey-a1 : #e8f2fc;
$lightgrey-a2 : #edf5fc;
$lightgrey-a3 : #f2f7fc;
$lightgrey-a4 : #f5f9fc;

// Light Greys B
$lightgrey-b1 : #e6f0fa;
$lightgrey-b2 : #ebf2fa;
$lightgrey-b3 : #edf4fa;
$lightgrey-b4 : #f2f6fa;

// Light Greys C
$lightgrey-c1 : #f7f9fa;
$lightgrey-c2 : #f5f6f7;

//***********************************************************************
//********************Primary color variations***************************
//***********************************************************************

//-------------------------Tinta e ombre---------------------------------
$primary-a12 : hsb($primary-h, $primary-s, 20);
$primary-a11 : hsb($primary-h, $primary-s, 30);
$primary-a10 : hsb($primary-h, $primary-s, 40);
$primary-a9  : hsb($primary-h, $primary-s, 50);
$primary-a8  : hsb($primary-h, $primary-s, 60);
$primary-a7  : hsb($primary-h, $primary-s, 70);
$primary-a6  : hsb($primary-h, $primary-s, 80);
$primary-a5  : hsb($primary-h, $primary-s - 15, 84);
$primary-a4  : hsb($primary-h, $primary-s - 30, 88);
$primary-a3  : hsb($primary-h, $primary-s - 45, 92);
$primary-a2  : hsb($primary-h, $primary-s - 60, 96);
$primary-a1  : hsb($primary-h, $primary-s - 75, 100);

//-------------------------Toni------------------------------------------
$primary-b1 : hsb($primary-h, $primary-s, $primary-b);
$primary-b2 : hsb($primary-h, $primary-s - 10, $primary-b - 10);
$primary-b3 : hsb($primary-h, $primary-s - 20, $primary-b - 20);
$primary-b4 : hsb($primary-h, $primary-s - 30, $primary-b - 30);
$primary-b5 : hsb($primary-h, $primary-s - 40, $primary-b - 40);
$primary-b6 : hsb($primary-h, $primary-s - 50, $primary-b - 50);
$primary-b7 : hsb($primary-h, $primary-s - 60, $primary-b - 60);
$primary-b8 : hsb($primary-h, $primary-s - 70, $primary-b - 70);

//-------------------------Saturazione------------------------------------------
$primary-c1 : hsb($primary-h, $primary-s - ($primary-s*90/100), $primary-b + ($primary-b * 20 / 100));
$primary-c2 : hsb($primary-h, $primary-s - ($primary-s*80/100), $primary-b + ($primary-b * 20 / 100));
$primary-c3 : hsb($primary-h, $primary-s - ($primary-s*70/100), $primary-b + ($primary-b * 20 / 100));
$primary-c4 : hsb($primary-h, $primary-s - ($primary-s*60/100), $primary-b + ($primary-b * 20 / 100));
$primary-c5 : hsb($primary-h, $primary-s - ($primary-s*50/100), $primary-b + ($primary-b * 20 / 100));
$primary-c6 : hsb($primary-h, $primary-s - ($primary-s*40/100), $primary-b + ($primary-b * 20 / 100));
$primary-c7 : hsb($primary-h, $primary-s - ($primary-s*30/100), $primary-b + ($primary-b * 20 / 100));
$primary-c8 : hsb($primary-h, $primary-s - ($primary-s*20/100), $primary-b + ($primary-b * 20 / 100));
$primary-c9 : hsb($primary-h, $primary-s - ($primary-s*10/100), $primary-b + ($primary-b * 20 / 100));
$primary-c10 : hsb($primary-h, $primary-s, $primary-b + ($primary-b * 20 / 100));
$primary-c11 : hsb($primary-h, $primary-s, $primary-b + ($primary-b * 10 / 100));
$primary-c12 : hsb($primary-h, $primary-s, $primary-b);

//***********************************************************************
//********************Accent colors variations***************************
//***********************************************************************
// analoghi
// tinte e ombre
/***************************** 1 ****************************************/
$analogue-1-a12 : hsb($analogue-1-h, $analogue-1-s, 20);
$analogue-1-a11 : hsb($analogue-1-h, $analogue-1-s, 30);
$analogue-1-a10 : hsb($analogue-1-h, $analogue-1-s, 40);
$analogue-1-a9  : hsb($analogue-1-h, $analogue-1-s, 50);
$analogue-1-a8  : hsb($analogue-1-h, $analogue-1-s, 60);
$analogue-1-a7  : hsb($analogue-1-h, $analogue-1-s, 70);
$analogue-1-a6  : hsb($analogue-1-h, $analogue-1-s, 80);
$analogue-1-a5  : hsb($analogue-1-h, $analogue-1-s - 15, 84);
$analogue-1-a4  : hsb($analogue-1-h, $analogue-1-s - 30, 88);
$analogue-1-a3  : hsb($analogue-1-h, $analogue-1-s - 45, 92);
$analogue-1-a2  : hsb($analogue-1-h, $analogue-1-s - 60, 96);
$analogue-1-a1  : hsb($analogue-1-h, $analogue-1-s - 75, 100);

/***************************** 2 ****************************************/
$analogue-2-a12 : hsb($analogue-2-h, $analogue-2-s, 20);
$analogue-2-a11 : hsb($analogue-2-h, $analogue-2-s, 30);
$analogue-2-a10 : hsb($analogue-2-h, $analogue-2-s, 40);
$analogue-2-a9  : hsb($analogue-2-h, $analogue-2-s, 50);
$analogue-2-a8  : hsb($analogue-2-h, $analogue-2-s, 60);
$analogue-2-a7  : hsb($analogue-2-h, $analogue-2-s, 70);
$analogue-2-a6  : hsb($analogue-2-h, $analogue-2-s, 80);
$analogue-2-a5  : hsb($analogue-2-h, $analogue-2-s - 15, 84);
$analogue-2-a4  : hsb($analogue-2-h, $analogue-2-s - 30, 88);
$analogue-2-a3  : hsb($analogue-2-h, $analogue-2-s - 45, 92);
$analogue-2-a2  : hsb($analogue-2-h, $analogue-2-s - 60, 96);
$analogue-2-a1  : hsb($analogue-2-h, $analogue-2-s - 75, 100);

// toni
/***************************** 1 ****************************************/
$analogue-1-b1 : hsb($analogue-1-h, $analogue-1-s, $analogue-1-b);
$analogue-1-b2 : hsb($analogue-1-h, $analogue-1-s - 10, $analogue-1-b - 10);
$analogue-1-b3 : hsb($analogue-1-h, $analogue-1-s - 20, $analogue-1-b - 20);
$analogue-1-b4 : hsb($analogue-1-h, $analogue-1-s - 30, $analogue-1-b - 30);
$analogue-1-b5 : hsb($analogue-1-h, $analogue-1-s - 40, $analogue-1-b - 40);
$analogue-1-b6 : hsb($analogue-1-h, $analogue-1-s - 50, $analogue-1-b - 50);
$analogue-1-b7 : hsb($analogue-1-h, $analogue-1-s - 60, $analogue-1-b - 60);
$analogue-1-b8 : hsb($analogue-1-h, $analogue-1-s - 70, $analogue-1-b - 70);

/***************************** 2 ****************************************/
$analogue-2-b1 : hsb($analogue-2-h, $analogue-2-s, $analogue-2-b);
$analogue-2-b2 : hsb($analogue-2-h, $analogue-2-s - 10, $analogue-2-b - 10);
$analogue-2-b3 : hsb($analogue-2-h, $analogue-2-s - 20, $analogue-2-b - 20);
$analogue-2-b4 : hsb($analogue-2-h, $analogue-2-s - 30, $analogue-2-b - 30);
$analogue-2-b5 : hsb($analogue-2-h, $analogue-2-s - 40, $analogue-2-b - 40);
$analogue-2-b6 : hsb($analogue-2-h, $analogue-2-s - 50, $analogue-2-b - 50);
$analogue-2-b7 : hsb($analogue-2-h, $analogue-2-s - 60, $analogue-2-b - 60);
$analogue-2-b8 : hsb($analogue-2-h, $analogue-2-s - 70, $analogue-2-b - 70);

// complementari
// tinte e ombre
/***************************** 1 ****************************************/
$complementary-1-a12 : hsb($complementary-1-h, $complementary-1-s, 20);
$complementary-1-a11 : hsb($complementary-1-h, $complementary-1-s, 30);
$complementary-1-a10 : hsb($complementary-1-h, $complementary-1-s, 40);
$complementary-1-a9  : hsb($complementary-1-h, $complementary-1-s, 50);
$complementary-1-a8  : hsb($complementary-1-h, $complementary-1-s, 60);
$complementary-1-a7  : hsb($complementary-1-h, $complementary-1-s, 70);
$complementary-1-a6  : hsb($complementary-1-h, $complementary-1-s, 80);
$complementary-1-a5  : hsb($complementary-1-h, $complementary-1-s - 15, 84);
$complementary-1-a4  : hsb($complementary-1-h, $complementary-1-s - 30, 88);
$complementary-1-a3  : hsb($complementary-1-h, $complementary-1-s - 45, 92);
$complementary-1-a2  : hsb($complementary-1-h, $complementary-1-s - 60, 96);
$complementary-1-a1  : hsb($complementary-1-h, $complementary-1-s - 75, 100);

/***************************** 2 ****************************************/
$complementary-2-a12 : hsb($complementary-2-h, $complementary-2-s, 20);
$complementary-2-a11 : hsb($complementary-2-h, $complementary-2-s, 30);
$complementary-2-a10 : hsb($complementary-2-h, $complementary-2-s, 40);
$complementary-2-a9  : hsb($complementary-2-h, $complementary-2-s, 50);
$complementary-2-a8  : hsb($complementary-2-h, $complementary-2-s, 60);
$complementary-2-a7  : hsb($complementary-2-h, $complementary-2-s, 70);
$complementary-2-a6  : hsb($complementary-2-h, $complementary-2-s, 80);
$complementary-2-a5  : hsb($complementary-2-h, $complementary-2-s - 15, 84);
$complementary-2-a4  : hsb($complementary-2-h, $complementary-2-s - 30, 88);
$complementary-2-a3  : hsb($complementary-2-h, $complementary-2-s - 45, 92);
$complementary-2-a2  : hsb($complementary-2-h, $complementary-2-s - 60, 96);
$complementary-2-a1  : hsb($complementary-2-h, $complementary-2-s - 75, 100);

/***************************** 3 ****************************************/
$complementary-3-a12 : hsb($complementary-3-h, $complementary-3-s, 20);
$complementary-3-a11 : hsb($complementary-3-h, $complementary-3-s, 30);
$complementary-3-a10 : hsb($complementary-3-h, $complementary-3-s, 40);
$complementary-3-a9  : hsb($complementary-3-h, $complementary-3-s, 50);
$complementary-3-a8  : hsb($complementary-3-h, $complementary-3-s, 60);
$complementary-3-a7  : hsb($complementary-3-h, $complementary-3-s, 70);
$complementary-3-a6  : hsb($complementary-3-h, $complementary-3-s, 80);
$complementary-3-a5  : hsb($complementary-3-h, $complementary-3-s - 15, 84);
$complementary-3-a4  : hsb($complementary-3-h, $complementary-3-s - 30, 88);
$complementary-3-a3  : hsb($complementary-3-h, $complementary-3-s - 45, 92);
$complementary-3-a2  : hsb($complementary-3-h, $complementary-3-s - 60, 96);
$complementary-3-a1  : hsb($complementary-3-h, $complementary-3-s - 75, 100);

//-------------------------- toni -----------------------------------------
/***************************** 1 ****************************************/
$complementary-1-b1 : hsb($complementary-1-h, $complementary-1-s, $complementary-1-b);
$complementary-1-b2 : hsb($complementary-1-h, $complementary-1-s - 10, $complementary-1-b - 10);
$complementary-1-b3 : hsb($complementary-1-h, $complementary-1-s - 20, $complementary-1-b - 20);
$complementary-1-b4 : hsb($complementary-1-h, $complementary-1-s - 30, $complementary-1-b - 30);
$complementary-1-b5 : hsb($complementary-1-h, $complementary-1-s - 40, $complementary-1-b - 40);
$complementary-1-b6 : hsb($complementary-1-h, $complementary-1-s - 50, $complementary-1-b - 50);
$complementary-1-b7 : hsb($complementary-1-h, $complementary-1-s - 60, $complementary-1-b - 60);
$complementary-1-b8 : hsb($complementary-1-h, $complementary-1-s - 70, $complementary-1-b - 70);

/***************************** 2 ****************************************/
$complementary-2-b1 : hsb($complementary-2-h, $complementary-2-s, $complementary-2-b);
$complementary-2-b2 : hsb($complementary-2-h, $complementary-2-s - 10, $complementary-2-b - 10);
$complementary-2-b3 : hsb($complementary-2-h, $complementary-2-s - 20, $complementary-2-b - 20);
$complementary-2-b4 : hsb($complementary-2-h, $complementary-2-s - 30, $complementary-2-b - 30);
$complementary-2-b5 : hsb($complementary-2-h, $complementary-2-s - 40, $complementary-2-b - 40);
$complementary-2-b6 : hsb($complementary-2-h, $complementary-2-s - 50, $complementary-2-b - 50);
$complementary-2-b7 : hsb($complementary-2-h, $complementary-2-s - 60, $complementary-2-b - 60);
$complementary-2-b8 : hsb($complementary-2-h, $complementary-2-s - 70, $complementary-2-b - 70);

/***************************** 3 ****************************************/
$complementary-3-b1 : hsb($complementary-3-h, $complementary-3-s, $complementary-3-b);
$complementary-3-b2 : hsb($complementary-3-h, $complementary-3-s - 10, $complementary-3-b - 10);
$complementary-3-b3 : hsb($complementary-3-h, $complementary-3-s - 20, $complementary-3-b - 20);
$complementary-3-b4 : hsb($complementary-3-h, $complementary-3-s - 30, $complementary-3-b - 30);
$complementary-3-b5 : hsb($complementary-3-h, $complementary-3-s - 40, $complementary-3-b - 40);
$complementary-3-b6 : hsb($complementary-3-h, $complementary-3-s - 50, $complementary-3-b - 50);
$complementary-3-b7 : hsb($complementary-3-h, $complementary-3-s - 60, $complementary-3-b - 60);
$complementary-3-b8 : hsb($complementary-3-h, $complementary-3-s - 70, $complementary-3-b - 70);

//***********************************************************************
//********************Neutral color variations***************************
//***********************************************************************

// tinte e ombre
/***************************** NEUTRAL 1 ****************************************/
$neutral-1-a10 :  hsb($neutral-1-h, $neutral-1-s, $neutral-1-b);
$neutral-1-a9 :   hsb($neutral-1-h, $neutral-1-s - 20, $neutral-1-b + 7);
$neutral-1-a8 :   hsb($neutral-1-h, $neutral-1-s - 30, $neutral-1-b + 14);
$neutral-1-a7 :   hsb($neutral-1-h, $neutral-1-s - 40, $neutral-1-b + 21);
$neutral-1-a6 :   hsb($neutral-1-h, $neutral-1-s - 50, $neutral-1-b + 28);
$neutral-1-a5 :   hsb($neutral-1-h, $neutral-1-s - 60, $neutral-1-b + 35);
$neutral-1-a4 :   hsb($neutral-1-h, $neutral-1-s - 64, $neutral-1-b + 42);
$neutral-1-a3 :   hsb($neutral-1-h, $neutral-1-s - 68, $neutral-1-b + 49);
$neutral-1-a2 :   hsb($neutral-1-h, $neutral-1-s - 71, $neutral-1-b + 56);
$neutral-1-a1 :   hsb($neutral-1-h, $neutral-1-s - 73, $neutral-1-b + 63);

/***************************** NEUTRAL 2 ****************************************/
$neutral-2-b1 :   hsb($neutral-2-h, $neutral-2-s, $neutral-2-b);
$neutral-2-b2 :   hsb($neutral-2-h, $neutral-2-s, $neutral-2-b - 15);
$neutral-2-b3 :   hsb($neutral-2-h, $neutral-2-s, $neutral-2-b - 30);
$neutral-2-b4 :   hsb($neutral-2-h, $neutral-2-s, $neutral-2-b - 45);
$neutral-2-b5 :   hsb($neutral-2-h, $neutral-2-s - 1, $neutral-2-b - 60);
$neutral-2-b6 :   hsb($neutral-2-h, $neutral-2-s + 2, $neutral-2-b - 75);
$neutral-2-b7 :   hsb($neutral-2-h, $neutral-2-s + 4, $neutral-2-b - 90);

/***************************** NEUTRAL 2 / 3 ****************************************/
$neutral-2-a1 :   hsb($neutral-2-h, $neutral-2-s, $neutral-2-b);
$neutral-2-a2 :   hsb($neutral-2-h, $neutral-2-s + 3, $neutral-2-b - 15);
$neutral-2-a3 :   hsb($neutral-2-h, $neutral-2-s + 2, $neutral-2-b - 30);
$neutral-2-a4 :   hsb($neutral-2-h, $neutral-2-s + 2, $neutral-2-b - 45);
$neutral-2-a5 :   hsb($neutral-2-h, $neutral-2-s + 2, $neutral-2-b - 60);
$neutral-2-a6 :   hsb($neutral-2-h, $neutral-2-s + 2, $neutral-2-b - 75);
$neutral-2-a7 :   hsb($neutral-2-h, $neutral-2-s + 4, $neutral-2-b - 85);
