.listing-items {
  .listing-item {
    margin: 1em 0;
    border-bottom: 2px solid $light-grey;
  }

  .titles {
    font-family: $font-family-sans-serif;
    font-size: 0.8em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .item {
    margin-bottom: 0.5em;
    padding: 0.5em 0 0;

    a {
      font-weight: 600;
    }

    p {
      font-size: 0.85em;
    }
  }
}
