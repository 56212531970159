

@media screen and (max-width: 575px) {
  #carouselExampleIndicators {
    .carousel-item {
      height: 300px;
    }

    .carousel-caption-content {
      max-width: 90vw;
    }
  }

  .dizionario-normativo {
    min-height: 200px;

    .ribbon {
      height: 40px;
    }
  }

  .card-deck#catalogo {
    .card {
      margin-bottom: 15px;
      border-radius: 15px;
      max-height: 70px;

      img {
        opacity: 1;
      }

      h5 {
        bottom: 22px;
        margin: 0 auto;
        padding: 0;
        font-size: 1.3em;
        font-weight: 900;
        background-image: none;
      }
    }
  }

  .services .card-deck {
    .card {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  .home {
    .books {
      .listing {
        .listing-card {
          width: 50%;
        }
      }
    }
  }

  .news.legislazione-e-dottrina {
    padding: 10px;
  }

  .border-portlet {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .logo-container img {
    padding: 10px;
    width: 200px;
  }

  .box-inserimenti {
    flex-direction: column;

    .tileItem {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .main-nav .nav-link {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 991px) and (min-width: 576px) {
  .header-utils a {
    .list-inline-item {
      width: 28%;
    }

    img {
      width: 80px;
    }
  }
}
