.badge-secondary {
  background-color: $color-primary;
}

.calendar {
  margin: 5vh 0;
  width: 100%;

  p {
    font-size: 0.7rem;
    text-transform: uppercase;
  }

  h6 {
    font-family: $font-family-serif;
    font-weight: 600;

    a {
      color: $blue;
    }
  }

  .list-inline-item {
    font-size: 0.85rem;
  }

  &.scadenze {
    .badge-secondary {
      background-color: $red;
    }
  }
}

.row-striped:nth-of-type(odd) {
  background-color: #f3f3f3;
  border-left: 4px $blue solid;
}

.row-striped:nth-of-type(even) {
  background-color: $white;
  border-left: 4px #efefef solid;
}

.row-striped {
  padding: 15px 0;
}

.box-inserimenti {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  .tileItem {
    margin-bottom: 0.5em;
    padding: 1em;
    width: 33%;
  }
}
