.nav-tabs {
  position: relative;
  border-radius: 5px 5px 0 0;
  background-color: lighten($light-grey, 5%);
  border-bottom: 0;

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    right: 10%;
    bottom: 0;
    left: 10%;
    width: 80%;
    height: 50%;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
    border-radius: 100%;
  }

  .nav-link {
    border: none;
    border-radius: 0;
    font-family: $font-family-serif;
    font-weight: 600;
    color: $gray-secondary;
    border-bottom: 2px solid transparent;

    &.disabled {
      font-weight: 400;
      color: $gray-300;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link,
  .nav-item.active .nav-link,
  .nav-item.in .nav-link {
    cursor: inherit;
    color: $italia;
    border-bottom: 2px solid $italia;
    background-color: white;
  }

  .nav-item {
    margin-bottom: 0;
  }

  &.nav-dark {
    background-color: $gray-primary;

    .nav-link {
      color: #c7d1d0; // TODO quando i colori dark saranno definiti, gestire questo colore come variabile
    }

    .nav-link.active,
    .nav-item.active .nav-link,
    .nav-item.in .nav-link,
    .nav-item.show .nav-link {
      color: $cyan;
      background-color: $gray-primary;
      border-bottom: 2px solid $cyan;
    }
  }
}

.it-ico-sm {
  font-size: $h3-font-size;
}

.it-ico {
  font-size: $h5-font-size;
}

.it-ico-lg {
  font-size: $h4-font-size;
}

.tab-container {
  margin: 1em 0;
  overflow: hidden;
}

.tab-pane {
  padding: 1em 1.5em;

  ul {
    padding: 0.5rem;
    list-style-type: none;

    li {
      margin-bottom: 0.35em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 30px;
      border-bottom: solid 1px darken($light-grey, 5%);

      &:last-child {
        border-bottom: none;
      }

      span {
        align-self: center;
        font-size: 13px;
        text-align: right;
      }

      a {
        padding: 0.25em 0;
        font-family: $font-family-serif;
        font-weight: 500;
      }
    }
  }
}

.column .tab-container {
  margin-top: 0;
  overflow: hidden;
  border-radius: 5px;
  background-color: $white;
}
