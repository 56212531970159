/*back to top*/
.return-to-top {
  z-index: 1;
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: block;
  display: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  background: $primary;
  background: rgba($primary, 0.7);
  transition: $transition-base;

  i {
    position: relative;
    top: 8px;
    margin: 0;
    font-size: 20px;
    color: $white;
    transition: $transition-base;

    &::before  {
      margin: 0;
    }
  }

  &:hover {
    text-decoration: none;
    background: rgba($primary, 0.9);

    i {
      top: 4px;
      color: $white;
    }
  }
}
