// work-in-progress

$arrow-size: $font-size-base/2;

*[data-toggle='collapse'] {
  .collapse-icon::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: $arrow-size solid;
    border-right: $arrow-size solid transparent;
    border-bottom: 0;
    border-left: $arrow-size solid transparent;
    vertical-align: $btn-padding-y/2;
  }

  &.collapsed {
    .collapse-icon::before {
      transform: rotate(180deg);
    }
  }
}

.collapse-div {
  border-bottom: 1px solid $gray-100;

  .collapse-div {
    border: 1px solid $gray-100;
    border-top: 0;
  }
}

.collapse-header {
  position: relative;

  *[data-toggle='collapse'] {
    padding: 14px 24px;
    width: 100%;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    color: $gray-secondary;
    text-align: left;
    background-color: transparent;
    border-top: 1px solid $gray-100;

    &[aria-expanded='false'] {
      color: $primary;

      &::after {
        content: '\e818';
      }
    }

    &[aria-expanded='true']::before {
      content: '';
      position: absolute;
      top: 0;
      left: 20px;
      display: block;
      width: 56px;
      height: 2px;
      border-radius: 2px;
      background-color: $primary;
    }

    &[aria-expanded='false']:hover::after,
    &[aria-expanded='true']:hover::after {
      text-decoration: none;
    }

    &::after {
      content: '\e810';
      float: right;
      margin-top: 5px;
      margin-right: 0.2em;
      width: 1em;
      font-family: 'italia-icon-font';
      font-weight: normal;
      font-style: normal;
      line-height: 1em;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
      font-variant: normal;
    }

    &:hover {
      text-decoration: underline;
      background-color: #e6ecf2;
    }

    &:active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.collapse-body {
  padding: 12px 24px 42px;

  .collapse-header button[aria-expanded='true']::before {
    width: 0;
    height: 0;
  }
}
