.border-portlet {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border: 3px solid #fff;

  .documentFirstHeading a::after {
    display: none;
  }

  .column-title {
    margin-right: 3em;
  }

  h2 {
    min-width: 220px;
  }

  .column-link a {
    font-weight: 500;
  }

  .column-icon {
    margin-right: 1.5em;

    i {
      font-size: 3.5rem;
      color: #acd8cd;
    }
  }

  .column-link p {
    margin: 0;

    &.date {
      font-size: 12px;
    }
  }
}

#edit-zone .plone-toolbar-main li.plonetoolbar-portlet-manager a[id^='portlet-manager-ContentWellPortlets'] {
  display: none;
}
