@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500,500i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700");
/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 3 ****************************************/
/***************************** 1 ****************************************/
/***************************** 2 ****************************************/
/***************************** 3 ****************************************/
/***************************** NEUTRAL 1 ****************************************/
/***************************** NEUTRAL 2 ****************************************/
/***************************** NEUTRAL 2 / 3 ****************************************/
@import url(italia-icon-font.css);
:root {
  --blue: #32465c;
  --indigo: #554dff;
  --purple: #9e99ff;
  --pink: #ffb3bf;
  --red: #d44a48;
  --orange: #e35452;
  --yellow: #fabc50;
  --green: #00cc85;
  --teal: #0bd9d2;
  --cyan: #9facbd;
  --white: white;
  --gray: #656566;
  --gray-dark: #323333;
  --italia: #0e5c3f;
  --bg-color: #f5f8fa;
  --light-blue: #cbd6e2;
  --gray-secondary: #5c6f82;
  --gray-tertiary: #5a768a;
  --gray-quaternary: #fcfdff;
  --primary: #12b961;
  --secondary: #5c6f82;
  --success: #00cc85;
  --info: #979899;
  --warning: #e35452;
  --danger: #d44a48;
  --light: #e9e6f2;
  --dark: #17324d;
  --100: #e3e4e6;
  --200: #cacacc;
  --300: #b1b1b3;
  --400: #979899;
  --500: #7e7f80;
  --600: #656566;
  --700: #4c4c4d;
  --800: #323333;
  --900: #19191a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Rubik", Geneva, Tahoma, sans-serif;
  --font-family-monospace: "Roboto Mono", monospace; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #7e7f80;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid black; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: white !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #b1b1b3 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #d6dce3; }
  .table .thead-dark th {
    color: inherit;
    border-color: #d6dce3; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Rubik", Geneva, Tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #19191a;
  text-align: left;
  background-color: white; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 8px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #12b961;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0b733c;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: "Roboto Mono", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1em;
  padding-bottom: 1em;
  color: #5a768a;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2; }

.display-2 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2; }

.display-3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2; }

.display-4 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2; }

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.777rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 16px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.777rem;
  color: #656566; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: white;
  border: 1px solid #b1b1b3;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 8px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #656566; }

code {
  font-size: 87.5%;
  color: #17324d;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: white;
  background-color: #19191a;
  border-radius: 2px;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #19191a; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  margin-left: -6px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 16px;
  color: #19191a; }
  .table th,
  .table td {
    padding: 1em;
    vertical-align: top;
    border-top: 1px solid #d6dce3; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #d6dce3; }
  .table tbody + tbody {
    border-top: 2px solid #d6dce3; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #d6dce3; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #d6dce3; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f7f9; }

.table-hover tbody tr:hover {
  color: #19191a;
  background-color: #e5f1fa; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bdebd3; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #84dbad; }

.table-hover .table-primary:hover {
  background-color: #a9e5c6; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a9e5c6; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d1d7dc; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #aab4be; }

.table-hover .table-secondary:hover {
  background-color: #c3cad1; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c3cad1; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f1dd; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae4bf; }

.table-hover .table-success:hover {
  background-color: #a3edd3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a3edd3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e2e2e2; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c9c9ca; }

.table-hover .table-info:hover {
  background-color: #d5d5d5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d5d5d5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7cfce; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f0a6a5; }

.table-hover .table-warning:hover {
  background-color: #f3bab8; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f3bab8; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f3cccc; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e8a1a0; }

.table-hover .table-danger:hover {
  background-color: #eeb7b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eeb7b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9f8fb; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f2f8; }

.table-hover .table-light:hover {
  background-color: #ebe8f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ebe8f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bec6cd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8694a2; }

.table-hover .table-dark:hover {
  background-color: #b0b9c2; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b0b9c2; }

.table-100,
.table-100 > th,
.table-100 > td {
  background-color: #f7f7f8; }

.table-100 th,
.table-100 td,
.table-100 thead th,
.table-100 tbody + tbody {
  border-color: #f1f1f2; }

.table-hover .table-100:hover {
  background-color: #e9e9ec; }
  .table-hover .table-100:hover > td,
  .table-hover .table-100:hover > th {
    background-color: #e9e9ec; }

.table-200,
.table-200 > th,
.table-200 > td {
  background-color: #f0f0f1; }

.table-200 th,
.table-200 td,
.table-200 thead th,
.table-200 tbody + tbody {
  border-color: #e3e4e4; }

.table-hover .table-200:hover {
  background-color: #e3e3e5; }
  .table-hover .table-200:hover > td,
  .table-hover .table-200:hover > th {
    background-color: #e3e3e5; }

.table-300,
.table-300 > th,
.table-300 > td {
  background-color: #e9e9ea; }

.table-300 th,
.table-300 td,
.table-300 thead th,
.table-300 tbody + tbody {
  border-color: #d6d7d7; }

.table-hover .table-300:hover {
  background-color: #dcdcde; }
  .table-hover .table-300:hover > td,
  .table-hover .table-300:hover > th {
    background-color: #dcdcde; }

.table-400,
.table-400 > th,
.table-400 > td {
  background-color: #e2e2e2; }

.table-400 th,
.table-400 td,
.table-400 thead th,
.table-400 tbody + tbody {
  border-color: #c9c9ca; }

.table-hover .table-400:hover {
  background-color: #d5d5d5; }
  .table-hover .table-400:hover > td,
  .table-hover .table-400:hover > th {
    background-color: #d5d5d5; }

.table-500,
.table-500 > th,
.table-500 > td {
  background-color: #dbdbdb; }

.table-500 th,
.table-500 td,
.table-500 thead th,
.table-500 tbody + tbody {
  border-color: #bcbcbd; }

.table-hover .table-500:hover {
  background-color: #cecece; }
  .table-hover .table-500:hover > td,
  .table-hover .table-500:hover > th {
    background-color: #cecece; }

.table-600,
.table-600 > th,
.table-600 > td {
  background-color: #d4d4d4; }

.table-600 th,
.table-600 td,
.table-600 thead th,
.table-600 tbody + tbody {
  border-color: #afafaf; }

.table-hover .table-600:hover {
  background-color: #c7c7c7; }
  .table-hover .table-600:hover > td,
  .table-hover .table-600:hover > th {
    background-color: #c7c7c7; }

.table-700,
.table-700 > th,
.table-700 > td {
  background-color: #cdcdcd; }

.table-700 th,
.table-700 td,
.table-700 thead th,
.table-700 tbody + tbody {
  border-color: #a2a2a2; }

.table-hover .table-700:hover {
  background-color: silver; }
  .table-hover .table-700:hover > td,
  .table-hover .table-700:hover > th {
    background-color: silver; }

.table-800,
.table-800 > th,
.table-800 > td {
  background-color: #c6c6c6; }

.table-800 th,
.table-800 td,
.table-800 thead th,
.table-800 tbody + tbody {
  border-color: #959595; }

.table-hover .table-800:hover {
  background-color: #b9b9b9; }
  .table-hover .table-800:hover > td,
  .table-hover .table-800:hover > th {
    background-color: #b9b9b9; }

.table-900,
.table-900 > th,
.table-900 > td {
  background-color: #bfbfbf; }

.table-900 th,
.table-900 td,
.table-900 thead th,
.table-900 tbody + tbody {
  border-color: #888888; }

.table-hover .table-900:hover {
  background-color: #b2b2b2; }
  .table-hover .table-900:hover > td,
  .table-hover .table-900:hover > th {
    background-color: #b2b2b2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #e5f1fa; }

.table-hover .table-active:hover {
  background-color: #d0e5f6; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #d0e5f6; }

.table .thead-dark th {
  color: white;
  background-color: #323333;
  border-color: #464646; }

.table .thead-light th {
  color: #4c4c4d;
  background-color: #cacacc;
  border-color: #d6dce3; }

.table-dark {
  color: white;
  background-color: #323333; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #464646; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #4c4c4d;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #979899;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #4c4c4d;
    background-color: white;
    border-color: #5c6f82;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
  .form-control::placeholder {
    color: #656566;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #cacacc;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #4c4c4d;
  background-color: white; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.556; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.428; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #19191a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.428em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.428;
  border-radius: 2px; }

.form-control-lg {
  height: calc(1.556em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.556;
  border-radius: 8px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #5a768a; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.777rem;
  color: #00cc85; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1rem 1rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #19191a;
  background-color: #00cc85;
  border-radius: 4px; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00cc85;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cc85' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00cc85;
    box-shadow: 0 0 0 0.2rem rgba(0, 204, 133, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00cc85;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23323333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cc85' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") white no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #00cc85;
    box-shadow: 0 0 0 0.2rem rgba(0, 204, 133, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00cc85; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00cc85; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00cc85; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00ffa6;
  background-color: #00ffa6; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 204, 133, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00cc85; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00cc85; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00cc85;
  box-shadow: 0 0 0 0.2rem rgba(0, 204, 133, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.777rem;
  color: #d44a48; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 1rem 1rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: white;
  background-color: #d44a48;
  border-radius: 4px; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d44a48;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d44a48' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d44a48' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d44a48;
    box-shadow: 0 0 0 0.2rem rgba(212, 74, 72, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d44a48;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23323333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d44a48' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d44a48' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") white no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d44a48;
    box-shadow: 0 0 0 0.2rem rgba(212, 74, 72, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d44a48; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d44a48; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d44a48; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #dd7371;
  background-color: #dd7371; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(212, 74, 72, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d44a48; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d44a48; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d44a48;
  box-shadow: 0 0 0 0.2rem rgba(212, 74, 72, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 600;
  color: #19191a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #19191a;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(18, 185, 97, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  background-color: #12b961;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #12b961; }
  .btn-primary:hover {
    background-color: #0f964f;
    color: white;
    border-color: #0d8b49; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 185, 97, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #19191a;
    background-color: #12b961;
    border-color: #12b961; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: white;
    background-color: #0d8b49;
    border-color: #0c7f43; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(18, 185, 97, 0.5); }

.btn-secondary {
  background-color: #5c6f82;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
  border-color: #5c6f82; }
  .btn-secondary:hover {
    background-color: #4c5c6c;
    color: white;
    border-color: #475664; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(92, 111, 130, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: white;
    background-color: #5c6f82;
    border-color: #5c6f82; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: white;
    background-color: #475664;
    border-color: #424f5d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 111, 130, 0.5); }

.btn-success {
  background-color: #00cc85;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #00cc85; }
  .btn-success:hover {
    background-color: #00a66c;
    color: white;
    border-color: #009963; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 204, 133, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #19191a;
    background-color: #00cc85;
    border-color: #00cc85; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: white;
    background-color: #009963;
    border-color: #008c5b; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 204, 133, 0.5); }

.btn-info {
  background-color: #979899;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #979899; }
  .btn-info:hover {
    background-color: #848586;
    color: #19191a;
    border-color: #7e7e80; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #19191a;
    background-color: #979899;
    border-color: #979899; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #19191a;
    background-color: #7e7e80;
    border-color: #777879; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }

.btn-warning {
  background-color: #e35452;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #e35452; }
  .btn-warning:hover {
    background-color: #de3431;
    color: white;
    border-color: #dc2926; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(227, 84, 82, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #19191a;
    background-color: #e35452;
    border-color: #e35452; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: white;
    background-color: #dc2926;
    border-color: #d32522; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(227, 84, 82, 0.5); }

.btn-danger {
  background-color: #d44a48;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
  border-color: #d44a48; }
  .btn-danger:hover {
    background-color: #c6322f;
    color: white;
    border-color: #bc2f2d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(212, 74, 72, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: white;
    background-color: #d44a48;
    border-color: #d44a48; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: white;
    background-color: #bc2f2d;
    border-color: #b22c2a; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 74, 72, 0.5); }

.btn-light {
  background-color: #e9e6f2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #e9e6f2; }
  .btn-light:hover {
    background-color: #d3cde5;
    color: #19191a;
    border-color: #ccc4e1; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 230, 242, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #19191a;
    background-color: #e9e6f2;
    border-color: #e9e6f2; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #19191a;
    background-color: #ccc4e1;
    border-color: #c4bcdd; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 230, 242, 0.5); }

.btn-dark {
  background-color: #17324d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
  border-color: #17324d; }
  .btn-dark:hover {
    background-color: #0e1f2f;
    color: white;
    border-color: #0b1825; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(23, 50, 77, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: white;
    background-color: #17324d;
    border-color: #17324d; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: white;
    background-color: #0b1825;
    border-color: #08121b; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 50, 77, 0.5); }

.btn-100 {
  background-color: #e3e4e6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #e3e4e6; }
  .btn-100:hover {
    background-color: #cfd0d3;
    color: #19191a;
    border-color: #c9cacd; }
  .btn-100:focus, .btn-100.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(227, 228, 230, 0.5); }
  .btn-100.disabled, .btn-100:disabled {
    color: #19191a;
    background-color: #e3e4e6;
    border-color: #e3e4e6; }
  .btn-100:not(:disabled):not(.disabled):active, .btn-100:not(:disabled):not(.disabled).active,
  .show > .btn-100.dropdown-toggle {
    color: #19191a;
    background-color: #c9cacd;
    border-color: #c2c3c7; }
    .btn-100:not(:disabled):not(.disabled):active:focus, .btn-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-100.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(227, 228, 230, 0.5); }

.btn-200 {
  background-color: #cacacc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #cacacc; }
  .btn-200:hover {
    background-color: #b6b7b9;
    color: #19191a;
    border-color: #b0b1b3; }
  .btn-200:focus, .btn-200.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(202, 202, 204, 0.5); }
  .btn-200.disabled, .btn-200:disabled {
    color: #19191a;
    background-color: #cacacc;
    border-color: #cacacc; }
  .btn-200:not(:disabled):not(.disabled):active, .btn-200:not(:disabled):not(.disabled).active,
  .show > .btn-200.dropdown-toggle {
    color: #19191a;
    background-color: #b0b1b3;
    border-color: #a9aaad; }
    .btn-200:not(:disabled):not(.disabled):active:focus, .btn-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-200.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(202, 202, 204, 0.5); }

.btn-300 {
  background-color: #b1b1b3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #b1b1b3; }
  .btn-300:hover {
    background-color: #9d9ea0;
    color: #19191a;
    border-color: #979899; }
  .btn-300:focus, .btn-300.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(177, 177, 179, 0.5); }
  .btn-300.disabled, .btn-300:disabled {
    color: #19191a;
    background-color: #b1b1b3;
    border-color: #b1b1b3; }
  .btn-300:not(:disabled):not(.disabled):active, .btn-300:not(:disabled):not(.disabled).active,
  .show > .btn-300.dropdown-toggle {
    color: #19191a;
    background-color: #979899;
    border-color: #909193; }
    .btn-300:not(:disabled):not(.disabled):active:focus, .btn-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-300.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(177, 177, 179, 0.5); }

.btn-400 {
  background-color: #979899;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #979899; }
  .btn-400:hover {
    background-color: #848586;
    color: #19191a;
    border-color: #7e7e80; }
  .btn-400:focus, .btn-400.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }
  .btn-400.disabled, .btn-400:disabled {
    color: #19191a;
    background-color: #979899;
    border-color: #979899; }
  .btn-400:not(:disabled):not(.disabled):active, .btn-400:not(:disabled):not(.disabled).active,
  .show > .btn-400.dropdown-toggle {
    color: #19191a;
    background-color: #7e7e80;
    border-color: #777879; }
    .btn-400:not(:disabled):not(.disabled):active:focus, .btn-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-400.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }

.btn-500 {
  background-color: #7e7f80;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #7e7f80; }
  .btn-500:hover {
    background-color: #6b6b6c;
    color: white;
    border-color: #656566; }
  .btn-500:focus, .btn-500.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(126, 127, 128, 0.5); }
  .btn-500.disabled, .btn-500:disabled {
    color: #19191a;
    background-color: #7e7f80;
    border-color: #7e7f80; }
  .btn-500:not(:disabled):not(.disabled):active, .btn-500:not(:disabled):not(.disabled).active,
  .show > .btn-500.dropdown-toggle {
    color: white;
    background-color: #656566;
    border-color: #5f5f5f; }
    .btn-500:not(:disabled):not(.disabled):active:focus, .btn-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-500.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(126, 127, 128, 0.5); }

.btn-600 {
  background-color: #656566;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
  border-color: #656566; }
  .btn-600:hover {
    background-color: #525253;
    color: white;
    border-color: #4c4c4c; }
  .btn-600:focus, .btn-600.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(101, 101, 102, 0.5); }
  .btn-600.disabled, .btn-600:disabled {
    color: white;
    background-color: #656566;
    border-color: #656566; }
  .btn-600:not(:disabled):not(.disabled):active, .btn-600:not(:disabled):not(.disabled).active,
  .show > .btn-600.dropdown-toggle {
    color: white;
    background-color: #4c4c4c;
    border-color: #454546; }
    .btn-600:not(:disabled):not(.disabled):active:focus, .btn-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-600.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(101, 101, 102, 0.5); }

.btn-700 {
  background-color: #4c4c4d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
  border-color: #4c4c4d; }
  .btn-700:hover {
    background-color: #393939;
    color: white;
    border-color: #323233; }
  .btn-700:focus, .btn-700.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 76, 77, 0.5); }
  .btn-700.disabled, .btn-700:disabled {
    color: white;
    background-color: #4c4c4d;
    border-color: #4c4c4d; }
  .btn-700:not(:disabled):not(.disabled):active, .btn-700:not(:disabled):not(.disabled).active,
  .show > .btn-700.dropdown-toggle {
    color: white;
    background-color: #323233;
    border-color: #2c2c2c; }
    .btn-700:not(:disabled):not(.disabled):active:focus, .btn-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-700.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 76, 77, 0.5); }

.btn-800 {
  background-color: #323333;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
  border-color: #323333; }
  .btn-800:hover {
    background-color: #1f2020;
    color: white;
    border-color: #191919; }
  .btn-800:focus, .btn-800.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(50, 51, 51, 0.5); }
  .btn-800.disabled, .btn-800:disabled {
    color: white;
    background-color: #323333;
    border-color: #323333; }
  .btn-800:not(:disabled):not(.disabled):active, .btn-800:not(:disabled):not(.disabled).active,
  .show > .btn-800.dropdown-toggle {
    color: white;
    background-color: #191919;
    border-color: #131313; }
    .btn-800:not(:disabled):not(.disabled):active:focus, .btn-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-800.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(50, 51, 51, 0.5); }

.btn-900 {
  background-color: #19191a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: white;
  border-color: #19191a; }
  .btn-900:hover {
    background-color: #060606;
    color: white;
    border-color: black; }
  .btn-900:focus, .btn-900.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(25, 25, 26, 0.5); }
  .btn-900.disabled, .btn-900:disabled {
    color: white;
    background-color: #19191a;
    border-color: #19191a; }
  .btn-900:not(:disabled):not(.disabled):active, .btn-900:not(:disabled):not(.disabled).active,
  .show > .btn-900.dropdown-toggle {
    color: white;
    background-color: black;
    border-color: black; }
    .btn-900:not(:disabled):not(.disabled):active:focus, .btn-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-900.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(25, 25, 26, 0.5); }

.btn-outline-primary {
  box-shadow: inset 0 0 0 1px #12b961;
  color: #12b961;
  background-color: transparent;
  background-image: none; }
  .btn-outline-primary:hover {
    box-shadow: inset 0 0 0 1px #158349;
    color: #158349; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: inset 0 0 0 1px #12b961, 0 0 0 0.2rem rgba(18, 185, 97, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #12b961;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #12b961;
    color: #12b961;
    background-color: transparent;
    background-image: none; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(18, 185, 97, 0.5); }

.btn-outline-secondary {
  box-shadow: inset 0 0 0 1px #5c6f82;
  color: #5c6f82;
  background-color: transparent;
  background-image: none; }
  .btn-outline-secondary:hover {
    box-shadow: inset 0 0 0 1px #50565c;
    color: #50565c; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: inset 0 0 0 1px #5c6f82, 0 0 0 0.2rem rgba(92, 111, 130, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #5c6f82;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #5c6f82;
    color: #5c6f82;
    background-color: transparent;
    background-image: none; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 111, 130, 0.5); }

.btn-outline-success {
  box-shadow: inset 0 0 0 1px #00cc85;
  color: #00cc85;
  background-color: transparent;
  background-image: none; }
  .btn-outline-success:hover {
    box-shadow: inset 0 0 0 1px #089161;
    color: #089161; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: inset 0 0 0 1px #00cc85, 0 0 0 0.2rem rgba(0, 204, 133, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00cc85;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #00cc85;
    color: #00cc85;
    background-color: transparent;
    background-image: none; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 204, 133, 0.5); }

.btn-outline-info {
  box-shadow: inset 0 0 0 1px #979899;
  color: #979899;
  background-color: transparent;
  background-image: none; }
  .btn-outline-info:hover {
    box-shadow: inset 0 0 0 1px #7f7f7f;
    color: #7f7f7f; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: inset 0 0 0 1px #979899, 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #979899;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #979899;
    color: #979899;
    background-color: transparent;
    background-image: none; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }

.btn-outline-warning {
  box-shadow: inset 0 0 0 1px #e35452;
  color: #e35452;
  background-color: transparent;
  background-image: none; }
  .btn-outline-warning:hover {
    box-shadow: inset 0 0 0 1px #cf3532;
    color: #cf3532; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: inset 0 0 0 1px #e35452, 0 0 0 0.2rem rgba(227, 84, 82, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #e35452;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #e35452;
    color: #e35452;
    background-color: transparent;
    background-image: none; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(227, 84, 82, 0.5); }

.btn-outline-danger {
  box-shadow: inset 0 0 0 1px #d44a48;
  color: #d44a48;
  background-color: transparent;
  background-image: none; }
  .btn-outline-danger:hover {
    box-shadow: inset 0 0 0 1px #b03a38;
    color: #b03a38; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: inset 0 0 0 1px #d44a48, 0 0 0 0.2rem rgba(212, 74, 72, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d44a48;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #d44a48;
    color: #d44a48;
    background-color: transparent;
    background-image: none; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 74, 72, 0.5); }

.btn-outline-light {
  box-shadow: inset 0 0 0 1px #e9e6f2;
  color: #e9e6f2;
  background-color: transparent;
  background-image: none; }
  .btn-outline-light:hover {
    box-shadow: inset 0 0 0 1px #cec9dd;
    color: #cec9dd; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: inset 0 0 0 1px #e9e6f2, 0 0 0 0.2rem rgba(233, 230, 242, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e9e6f2;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #e9e6f2;
    color: #e9e6f2;
    background-color: transparent;
    background-image: none; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 230, 242, 0.5); }

.btn-outline-dark {
  box-shadow: inset 0 0 0 1px #17324d;
  color: #17324d;
  background-color: transparent;
  background-image: none; }
  .btn-outline-dark:hover {
    box-shadow: inset 0 0 0 1px #0e1823;
    color: #0e1823; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: inset 0 0 0 1px #17324d, 0 0 0 0.2rem rgba(23, 50, 77, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #17324d;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #17324d;
    color: #17324d;
    background-color: transparent;
    background-image: none; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 50, 77, 0.5); }

.btn-outline-100 {
  box-shadow: inset 0 0 0 1px #e3e4e6;
  color: #e3e4e6;
  background-color: transparent;
  background-image: none; }
  .btn-outline-100:hover {
    box-shadow: inset 0 0 0 1px #cbcbcb;
    color: #cbcbcb; }
  .btn-outline-100:focus, .btn-outline-100.focus {
    box-shadow: inset 0 0 0 1px #e3e4e6, 0 0 0 0.2rem rgba(227, 228, 230, 0.5); }
  .btn-outline-100.disabled, .btn-outline-100:disabled {
    color: #e3e4e6;
    background-color: transparent; }
  .btn-outline-100:not(:disabled):not(.disabled):active, .btn-outline-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-100.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #e3e4e6;
    color: #e3e4e6;
    background-color: transparent;
    background-image: none; }
    .btn-outline-100:not(:disabled):not(.disabled):active:focus, .btn-outline-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-100.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(227, 228, 230, 0.5); }

.btn-outline-200 {
  box-shadow: inset 0 0 0 1px #cacacc;
  color: #cacacc;
  background-color: transparent;
  background-image: none; }
  .btn-outline-200:hover {
    box-shadow: inset 0 0 0 1px #b1b1b1;
    color: #b1b1b1; }
  .btn-outline-200:focus, .btn-outline-200.focus {
    box-shadow: inset 0 0 0 1px #cacacc, 0 0 0 0.2rem rgba(202, 202, 204, 0.5); }
  .btn-outline-200.disabled, .btn-outline-200:disabled {
    color: #cacacc;
    background-color: transparent; }
  .btn-outline-200:not(:disabled):not(.disabled):active, .btn-outline-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-200.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #cacacc;
    color: #cacacc;
    background-color: transparent;
    background-image: none; }
    .btn-outline-200:not(:disabled):not(.disabled):active:focus, .btn-outline-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-200.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(202, 202, 204, 0.5); }

.btn-outline-300 {
  box-shadow: inset 0 0 0 1px #b1b1b3;
  color: #b1b1b3;
  background-color: transparent;
  background-image: none; }
  .btn-outline-300:hover {
    box-shadow: inset 0 0 0 1px #989898;
    color: #989898; }
  .btn-outline-300:focus, .btn-outline-300.focus {
    box-shadow: inset 0 0 0 1px #b1b1b3, 0 0 0 0.2rem rgba(177, 177, 179, 0.5); }
  .btn-outline-300.disabled, .btn-outline-300:disabled {
    color: #b1b1b3;
    background-color: transparent; }
  .btn-outline-300:not(:disabled):not(.disabled):active, .btn-outline-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-300.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #b1b1b3;
    color: #b1b1b3;
    background-color: transparent;
    background-image: none; }
    .btn-outline-300:not(:disabled):not(.disabled):active:focus, .btn-outline-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-300.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(177, 177, 179, 0.5); }

.btn-outline-400 {
  box-shadow: inset 0 0 0 1px #979899;
  color: #979899;
  background-color: transparent;
  background-image: none; }
  .btn-outline-400:hover {
    box-shadow: inset 0 0 0 1px #7f7f7f;
    color: #7f7f7f; }
  .btn-outline-400:focus, .btn-outline-400.focus {
    box-shadow: inset 0 0 0 1px #979899, 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }
  .btn-outline-400.disabled, .btn-outline-400:disabled {
    color: #979899;
    background-color: transparent; }
  .btn-outline-400:not(:disabled):not(.disabled):active, .btn-outline-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-400.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #979899;
    color: #979899;
    background-color: transparent;
    background-image: none; }
    .btn-outline-400:not(:disabled):not(.disabled):active:focus, .btn-outline-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-400.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }

.btn-outline-500 {
  box-shadow: inset 0 0 0 1px #7e7f80;
  color: #7e7f80;
  background-color: transparent;
  background-image: none; }
  .btn-outline-500:hover {
    box-shadow: inset 0 0 0 1px #656565;
    color: #656565; }
  .btn-outline-500:focus, .btn-outline-500.focus {
    box-shadow: inset 0 0 0 1px #7e7f80, 0 0 0 0.2rem rgba(126, 127, 128, 0.5); }
  .btn-outline-500.disabled, .btn-outline-500:disabled {
    color: #7e7f80;
    background-color: transparent; }
  .btn-outline-500:not(:disabled):not(.disabled):active, .btn-outline-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-500.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #7e7f80;
    color: #7e7f80;
    background-color: transparent;
    background-image: none; }
    .btn-outline-500:not(:disabled):not(.disabled):active:focus, .btn-outline-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-500.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(126, 127, 128, 0.5); }

.btn-outline-600 {
  box-shadow: inset 0 0 0 1px #656566;
  color: #656566;
  background-color: transparent;
  background-image: none; }
  .btn-outline-600:hover {
    box-shadow: inset 0 0 0 1px #4c4c4c;
    color: #4c4c4c; }
  .btn-outline-600:focus, .btn-outline-600.focus {
    box-shadow: inset 0 0 0 1px #656566, 0 0 0 0.2rem rgba(101, 101, 102, 0.5); }
  .btn-outline-600.disabled, .btn-outline-600:disabled {
    color: #656566;
    background-color: transparent; }
  .btn-outline-600:not(:disabled):not(.disabled):active, .btn-outline-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-600.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #656566;
    color: #656566;
    background-color: transparent;
    background-image: none; }
    .btn-outline-600:not(:disabled):not(.disabled):active:focus, .btn-outline-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-600.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(101, 101, 102, 0.5); }

.btn-outline-700 {
  box-shadow: inset 0 0 0 1px #4c4c4d;
  color: #4c4c4d;
  background-color: transparent;
  background-image: none; }
  .btn-outline-700:hover {
    box-shadow: inset 0 0 0 1px #333333;
    color: #333333; }
  .btn-outline-700:focus, .btn-outline-700.focus {
    box-shadow: inset 0 0 0 1px #4c4c4d, 0 0 0 0.2rem rgba(76, 76, 77, 0.5); }
  .btn-outline-700.disabled, .btn-outline-700:disabled {
    color: #4c4c4d;
    background-color: transparent; }
  .btn-outline-700:not(:disabled):not(.disabled):active, .btn-outline-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-700.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #4c4c4d;
    color: #4c4c4d;
    background-color: transparent;
    background-image: none; }
    .btn-outline-700:not(:disabled):not(.disabled):active:focus, .btn-outline-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-700.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 76, 77, 0.5); }

.btn-outline-800 {
  box-shadow: inset 0 0 0 1px #323333;
  color: #323333;
  background-color: transparent;
  background-image: none; }
  .btn-outline-800:hover {
    box-shadow: inset 0 0 0 1px #191919;
    color: #191919; }
  .btn-outline-800:focus, .btn-outline-800.focus {
    box-shadow: inset 0 0 0 1px #323333, 0 0 0 0.2rem rgba(50, 51, 51, 0.5); }
  .btn-outline-800.disabled, .btn-outline-800:disabled {
    color: #323333;
    background-color: transparent; }
  .btn-outline-800:not(:disabled):not(.disabled):active, .btn-outline-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-800.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #323333;
    color: #323333;
    background-color: transparent;
    background-image: none; }
    .btn-outline-800:not(:disabled):not(.disabled):active:focus, .btn-outline-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-800.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(50, 51, 51, 0.5); }

.btn-outline-900 {
  box-shadow: inset 0 0 0 1px #19191a;
  color: #19191a;
  background-color: transparent;
  background-image: none; }
  .btn-outline-900:hover {
    box-shadow: inset 0 0 0 1px black;
    color: black; }
  .btn-outline-900:focus, .btn-outline-900.focus {
    box-shadow: inset 0 0 0 1px #19191a, 0 0 0 0.2rem rgba(25, 25, 26, 0.5); }
  .btn-outline-900.disabled, .btn-outline-900:disabled {
    color: #19191a;
    background-color: transparent; }
  .btn-outline-900:not(:disabled):not(.disabled):active, .btn-outline-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-900.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #19191a;
    color: #19191a;
    background-color: transparent;
    background-image: none; }
    .btn-outline-900:not(:disabled):not(.disabled):active:focus, .btn-outline-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-900.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(25, 25, 26, 0.5); }

.btn-link {
  font-weight: 400;
  color: #12b961;
  text-decoration: none; }
  .btn-link:hover {
    color: #0b733c;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #656566;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.556;
  border-radius: 8px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.428;
  border-radius: 2px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #19191a;
  text-align: left;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 8px 0;
  overflow: hidden;
  border-top: 1px solid #cacacc; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 12px 24px;
  clear: both;
  font-weight: 400;
  color: #17324d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #17324d;
    text-decoration: none;
    background-color: #e6ecf2; }
  .dropdown-item.active, .dropdown-item:active {
    color: white;
    text-decoration: none;
    background-color: #12b961; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #656566;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 24px;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #17324d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 12px 24px;
  color: #17324d; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4c4c4d;
  text-align: center;
  white-space: nowrap;
  background-color: #cacacc;
  border: 1px solid #979899;
  border-radius: 4px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.556em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.556;
  border-radius: 8px; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.428em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.428;
  border-radius: 2px; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: white;
    border-color: #12b961;
    background-color: #12b961;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #5c6f82; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: white;
    background-color: #8af4bc;
    border-color: #8af4bc;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #656566; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #cacacc; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: white;
    border: #7e7f80 solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 4px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #12b961;
  background-color: #12b961;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='white' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(18, 185, 97, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(18, 185, 97, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(18, 185, 97, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #7e7f80;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: white;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(18, 185, 97, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #4c4c4d;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23323333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: white;
  border: 1px solid #979899;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #5c6f82;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
    .custom-select:focus::-ms-value {
      color: #4c4c4d;
      background-color: white; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #656566;
    background-color: #cacacc; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.428em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.556em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #5c6f82;
    box-shadow: 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #cacacc; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  color: #4c4c4d;
  background-color: white;
  border: 1px solid #979899;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #4c4c4d;
    content: "Browse";
    background-color: #cacacc;
    border-left: inherit;
    border-radius: 0 4px 4px 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #12b961;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #8af4bc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #b1b1b3;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #12b961;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #8af4bc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #b1b1b3;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #12b961;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #8af4bc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #b1b1b3;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #b1b1b3;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #7e7f80; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #7e7f80; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #7e7f80; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #656566;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #b1b1b3; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #cacacc #cacacc #b1b1b3; }
    .nav-tabs .nav-link.disabled {
      color: #656566;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #4c4c4d;
    background-color: white;
    border-color: #b1b1b3 #b1b1b3 white; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 4px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background-color: #12b961; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 16px;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: transparent; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkuMnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMiAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSIxMDI0dXAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSItLWhvbWUtLS1wYXJhbGxheC0tLW1vYmlsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0LjAwMDAwMCwgLTIwLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0iLW5ldHdvcmstc2xpbS1oZWFkZXIiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTI0LDI0IEwzNiwyNCBMMzYsMjYgTDI0LDI2IEwyNCwyNCBaIE0yNCwyMCBMMzIsMjAgTDMyLDIyIEwyNCwyMiBMMjQsMjAgWiBNMjQsMjggTDMyLDI4IEwzMiwzMCBMMjQsMzAgTDI0LDI4IFoiIGlkPSJpY29uLXNtYWxsLWJ1cmdlciI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: white; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: white; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: white;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 2rem; }

.card-title {
  margin-bottom: 1rem; }

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 2rem; }

.card-header {
  padding: 1rem 2rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0 - 1px) calc(0 - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1rem 2rem;
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0 - 1px) calc(0 - 1px); }

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 6px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -6px;
      margin-left: -6px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 6px;
        margin-bottom: 0;
        margin-left: 6px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 6px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #656566;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #656566; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #12b961;
  background-color: white;
  border: 1px solid #b1b1b3; }
  .page-link:hover {
    z-index: 2;
    color: #0b733c;
    text-decoration: none;
    background-color: #cacacc;
    border-color: #b1b1b3; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 185, 97, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.page-item.active .page-link {
  z-index: 1;
  color: white;
  background-color: #12b961;
  border-color: #12b961; }

.page-item.disabled .page-link {
  color: #656566;
  pointer-events: none;
  cursor: auto;
  background-color: white;
  border-color: #b1b1b3; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #19191a;
  background-color: #12b961; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #19191a;
    background-color: #0d8b49; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 185, 97, 0.5); }

.badge-secondary {
  color: white;
  background-color: #5c6f82; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: white;
    background-color: #475664; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(92, 111, 130, 0.5); }

.badge-success {
  color: #19191a;
  background-color: #00cc85; }
  a.badge-success:hover, a.badge-success:focus {
    color: #19191a;
    background-color: #009963; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 204, 133, 0.5); }

.badge-info {
  color: #19191a;
  background-color: #979899; }
  a.badge-info:hover, a.badge-info:focus {
    color: #19191a;
    background-color: #7e7e80; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }

.badge-warning {
  color: #19191a;
  background-color: #e35452; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #19191a;
    background-color: #dc2926; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(227, 84, 82, 0.5); }

.badge-danger {
  color: white;
  background-color: #d44a48; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: white;
    background-color: #bc2f2d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(212, 74, 72, 0.5); }

.badge-light {
  color: #19191a;
  background-color: #e9e6f2; }
  a.badge-light:hover, a.badge-light:focus {
    color: #19191a;
    background-color: #ccc4e1; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 230, 242, 0.5); }

.badge-dark {
  color: white;
  background-color: #17324d; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: white;
    background-color: #0b1825; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 50, 77, 0.5); }

.badge-100 {
  color: #19191a;
  background-color: #e3e4e6; }
  a.badge-100:hover, a.badge-100:focus {
    color: #19191a;
    background-color: #c9cacd; }
  a.badge-100:focus, a.badge-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(227, 228, 230, 0.5); }

.badge-200 {
  color: #19191a;
  background-color: #cacacc; }
  a.badge-200:hover, a.badge-200:focus {
    color: #19191a;
    background-color: #b0b1b3; }
  a.badge-200:focus, a.badge-200.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(202, 202, 204, 0.5); }

.badge-300 {
  color: #19191a;
  background-color: #b1b1b3; }
  a.badge-300:hover, a.badge-300:focus {
    color: #19191a;
    background-color: #979899; }
  a.badge-300:focus, a.badge-300.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(177, 177, 179, 0.5); }

.badge-400 {
  color: #19191a;
  background-color: #979899; }
  a.badge-400:hover, a.badge-400:focus {
    color: #19191a;
    background-color: #7e7e80; }
  a.badge-400:focus, a.badge-400.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(151, 152, 153, 0.5); }

.badge-500 {
  color: #19191a;
  background-color: #7e7f80; }
  a.badge-500:hover, a.badge-500:focus {
    color: #19191a;
    background-color: #656566; }
  a.badge-500:focus, a.badge-500.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(126, 127, 128, 0.5); }

.badge-600 {
  color: white;
  background-color: #656566; }
  a.badge-600:hover, a.badge-600:focus {
    color: white;
    background-color: #4c4c4c; }
  a.badge-600:focus, a.badge-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(101, 101, 102, 0.5); }

.badge-700 {
  color: white;
  background-color: #4c4c4d; }
  a.badge-700:hover, a.badge-700:focus {
    color: white;
    background-color: #323233; }
  a.badge-700:focus, a.badge-700.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(76, 76, 77, 0.5); }

.badge-800 {
  color: white;
  background-color: #323333; }
  a.badge-800:hover, a.badge-800:focus {
    color: white;
    background-color: #191919; }
  a.badge-800:focus, a.badge-800.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 51, 51, 0.5); }

.badge-900 {
  color: white;
  background-color: #19191a; }
  a.badge-900:hover, a.badge-900:focus {
    color: white;
    background-color: black; }
  a.badge-900:focus, a.badge-900.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(25, 25, 26, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #cacacc;
  border-radius: 8px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.5rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1rem;
    color: inherit; }

.alert-primary {
  color: #096032;
  background-color: #d0f1df;
  border-color: #bdebd3; }
  .alert-primary hr {
    border-top-color: #a9e5c6; }
  .alert-primary .alert-link {
    color: #05311a; }

.alert-secondary {
  color: #303a44;
  background-color: #dee2e6;
  border-color: #d1d7dc; }
  .alert-secondary hr {
    border-top-color: #c3cad1; }
  .alert-secondary .alert-link {
    color: #1b2126; }

.alert-success {
  color: #006a45;
  background-color: #ccf5e7;
  border-color: #b8f1dd; }
  .alert-success hr {
    border-top-color: #a3edd3; }
  .alert-success .alert-link {
    color: #003724; }

.alert-info {
  color: #4f4f50;
  background-color: #eaeaeb;
  border-color: #e2e2e2; }
  .alert-info hr {
    border-top-color: #d5d5d5; }
  .alert-info .alert-link {
    color: #363636; }

.alert-warning {
  color: #762c2a;
  background-color: #f9dddc;
  border-color: #f7cfce; }
  .alert-warning hr {
    border-top-color: #f3bab8; }
  .alert-warning .alert-link {
    color: #501e1d; }

.alert-danger {
  color: #6e2725;
  background-color: #f6dbda;
  border-color: #f3cccc; }
  .alert-danger hr {
    border-top-color: #eeb7b7; }
  .alert-danger .alert-link {
    color: #481918; }

.alert-light {
  color: #79787e;
  background-color: #fbfafc;
  border-color: #f9f8fb; }
  .alert-light hr {
    border-top-color: #ebe8f2; }
  .alert-light .alert-link {
    color: #605f64; }

.alert-dark {
  color: #0c1a28;
  background-color: #d1d6db;
  border-color: #bec6cd; }
  .alert-dark hr {
    border-top-color: #b0b9c2; }
  .alert-dark .alert-link {
    color: #000101; }

.alert-100 {
  color: #767677;
  background-color: #f9fafa;
  border-color: #f7f7f8; }
  .alert-100 hr {
    border-top-color: #e9e9ec; }
  .alert-100 .alert-link {
    color: #5d5d5d; }

.alert-200 {
  color: #69696a;
  background-color: #f4f4f5;
  border-color: #f0f0f1; }
  .alert-200 hr {
    border-top-color: #e3e3e5; }
  .alert-200 .alert-link {
    color: #505050; }

.alert-300 {
  color: #5c5c5d;
  background-color: #efeff0;
  border-color: #e9e9ea; }
  .alert-300 hr {
    border-top-color: #dcdcde; }
  .alert-300 .alert-link {
    color: #434343; }

.alert-400 {
  color: #4f4f50;
  background-color: #eaeaeb;
  border-color: #e2e2e2; }
  .alert-400 hr {
    border-top-color: #d5d5d5; }
  .alert-400 .alert-link {
    color: #363636; }

.alert-500 {
  color: #424242;
  background-color: #e5e5e6;
  border-color: #dbdbdb; }
  .alert-500 hr {
    border-top-color: #cecece; }
  .alert-500 .alert-link {
    color: #292929; }

.alert-600 {
  color: #353535;
  background-color: #e0e0e0;
  border-color: #d4d4d4; }
  .alert-600 hr {
    border-top-color: #c7c7c7; }
  .alert-600 .alert-link {
    color: #1c1c1c; }

.alert-700 {
  color: #272728;
  background-color: #dbdbdb;
  border-color: #cdcdcd; }
  .alert-700 hr {
    border-top-color: silver; }
  .alert-700 .alert-link {
    color: #0e0e0e; }

.alert-800 {
  color: #1a1a1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6; }
  .alert-800 hr {
    border-top-color: #b9b9b9; }
  .alert-800 .alert-link {
    color: #010101; }

.alert-900 {
  color: #0d0d0d;
  background-color: #d1d1d1;
  border-color: #bfbfbf; }
  .alert-900 hr {
    border-top-color: #b2b2b2; }
  .alert-900 .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 16px 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 16px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #cacacc;
  border-radius: 0;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  text-align: center;
  white-space: nowrap;
  background-color: #12b961;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 16px 16px; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #12b961;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #00264d;
    text-decoration: none;
    background-color: #d9e6f2; }
  .list-group-item-action:active {
    color: #19191a;
    background-color: #cacacc; }

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1.25rem;
  margin-bottom: -1px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #656566;
    pointer-events: none;
    background-color: white; }
  .list-group-item.active {
    z-index: 2;
    color: white;
    background-color: #12b961;
    border-color: #12b961; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #096032;
  background-color: #bdebd3; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #096032;
    background-color: #a9e5c6; }
  .list-group-item-primary.list-group-item-action.active {
    color: white;
    background-color: #096032;
    border-color: #096032; }

.list-group-item-secondary {
  color: #303a44;
  background-color: #d1d7dc; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #303a44;
    background-color: #c3cad1; }
  .list-group-item-secondary.list-group-item-action.active {
    color: white;
    background-color: #303a44;
    border-color: #303a44; }

.list-group-item-success {
  color: #006a45;
  background-color: #b8f1dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #006a45;
    background-color: #a3edd3; }
  .list-group-item-success.list-group-item-action.active {
    color: white;
    background-color: #006a45;
    border-color: #006a45; }

.list-group-item-info {
  color: #4f4f50;
  background-color: #e2e2e2; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #4f4f50;
    background-color: #d5d5d5; }
  .list-group-item-info.list-group-item-action.active {
    color: white;
    background-color: #4f4f50;
    border-color: #4f4f50; }

.list-group-item-warning {
  color: #762c2a;
  background-color: #f7cfce; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #762c2a;
    background-color: #f3bab8; }
  .list-group-item-warning.list-group-item-action.active {
    color: white;
    background-color: #762c2a;
    border-color: #762c2a; }

.list-group-item-danger {
  color: #6e2725;
  background-color: #f3cccc; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6e2725;
    background-color: #eeb7b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: white;
    background-color: #6e2725;
    border-color: #6e2725; }

.list-group-item-light {
  color: #79787e;
  background-color: #f9f8fb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #79787e;
    background-color: #ebe8f2; }
  .list-group-item-light.list-group-item-action.active {
    color: white;
    background-color: #79787e;
    border-color: #79787e; }

.list-group-item-dark {
  color: #0c1a28;
  background-color: #bec6cd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0c1a28;
    background-color: #b0b9c2; }
  .list-group-item-dark.list-group-item-action.active {
    color: white;
    background-color: #0c1a28;
    border-color: #0c1a28; }

.list-group-item-100 {
  color: #767677;
  background-color: #f7f7f8; }
  .list-group-item-100.list-group-item-action:hover, .list-group-item-100.list-group-item-action:focus {
    color: #767677;
    background-color: #e9e9ec; }
  .list-group-item-100.list-group-item-action.active {
    color: white;
    background-color: #767677;
    border-color: #767677; }

.list-group-item-200 {
  color: #69696a;
  background-color: #f0f0f1; }
  .list-group-item-200.list-group-item-action:hover, .list-group-item-200.list-group-item-action:focus {
    color: #69696a;
    background-color: #e3e3e5; }
  .list-group-item-200.list-group-item-action.active {
    color: white;
    background-color: #69696a;
    border-color: #69696a; }

.list-group-item-300 {
  color: #5c5c5d;
  background-color: #e9e9ea; }
  .list-group-item-300.list-group-item-action:hover, .list-group-item-300.list-group-item-action:focus {
    color: #5c5c5d;
    background-color: #dcdcde; }
  .list-group-item-300.list-group-item-action.active {
    color: white;
    background-color: #5c5c5d;
    border-color: #5c5c5d; }

.list-group-item-400 {
  color: #4f4f50;
  background-color: #e2e2e2; }
  .list-group-item-400.list-group-item-action:hover, .list-group-item-400.list-group-item-action:focus {
    color: #4f4f50;
    background-color: #d5d5d5; }
  .list-group-item-400.list-group-item-action.active {
    color: white;
    background-color: #4f4f50;
    border-color: #4f4f50; }

.list-group-item-500 {
  color: #424242;
  background-color: #dbdbdb; }
  .list-group-item-500.list-group-item-action:hover, .list-group-item-500.list-group-item-action:focus {
    color: #424242;
    background-color: #cecece; }
  .list-group-item-500.list-group-item-action.active {
    color: white;
    background-color: #424242;
    border-color: #424242; }

.list-group-item-600 {
  color: #353535;
  background-color: #d4d4d4; }
  .list-group-item-600.list-group-item-action:hover, .list-group-item-600.list-group-item-action:focus {
    color: #353535;
    background-color: #c7c7c7; }
  .list-group-item-600.list-group-item-action.active {
    color: white;
    background-color: #353535;
    border-color: #353535; }

.list-group-item-700 {
  color: #272728;
  background-color: #cdcdcd; }
  .list-group-item-700.list-group-item-action:hover, .list-group-item-700.list-group-item-action:focus {
    color: #272728;
    background-color: silver; }
  .list-group-item-700.list-group-item-action.active {
    color: white;
    background-color: #272728;
    border-color: #272728; }

.list-group-item-800 {
  color: #1a1a1b;
  background-color: #c6c6c6; }
  .list-group-item-800.list-group-item-action:hover, .list-group-item-800.list-group-item-action:focus {
    color: #1a1a1b;
    background-color: #b9b9b9; }
  .list-group-item-800.list-group-item-action.active {
    color: white;
    background-color: #1a1a1b;
    border-color: #1a1a1b; }

.list-group-item-900 {
  color: #0d0d0d;
  background-color: #bfbfbf; }
  .list-group-item-900.list-group-item-action:hover, .list-group-item-900.list-group-item-action:focus {
    color: #0d0d0d;
    background-color: #b2b2b2; }
  .list-group-item-900.list-group-item-action.active {
    color: white;
    background-color: #0d0d0d;
    border-color: #0d0d0d; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: black;
  text-shadow: 0 1px 0 white;
  opacity: .5; }
  .close:hover {
    color: black;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: black; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.8; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 0 solid #b1b1b3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }
  .modal-header .close {
    padding: 1.5rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 0 solid #b1b1b3;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.5rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3rem); }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rubik", Geneva, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #004a4d; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #004a4d; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #004a4d; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #004a4d; }

.tooltip-inner {
  max-width: 32em;
  padding: 1rem 1rem;
  color: white;
  text-align: center;
  background-color: #004a4d;
  border-radius: 4px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rubik", Geneva, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 8px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: white; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 8px 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: white; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: white; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 8px 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: white; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(8px - 1px);
  border-top-right-radius: calc(8px - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #19191a; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  color: #5c6f82;
  text-align: center;
  opacity: 1;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #5c6f82;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%235c6f82' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%235c6f82' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: white;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #12b961 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0d8b49 !important; }

.bg-secondary {
  background-color: #5c6f82 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #475664 !important; }

.bg-success {
  background-color: #00cc85 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #009963 !important; }

.bg-info {
  background-color: #979899 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #7e7e80 !important; }

.bg-warning {
  background-color: #e35452 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #dc2926 !important; }

.bg-danger {
  background-color: #d44a48 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bc2f2d !important; }

.bg-light {
  background-color: #e9e6f2 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #ccc4e1 !important; }

.bg-dark {
  background-color: #17324d !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0b1825 !important; }

.bg-100 {
  background-color: #e3e4e6 !important; }

a.bg-100:hover, a.bg-100:focus,
button.bg-100:hover,
button.bg-100:focus {
  background-color: #c9cacd !important; }

.bg-200 {
  background-color: #cacacc !important; }

a.bg-200:hover, a.bg-200:focus,
button.bg-200:hover,
button.bg-200:focus {
  background-color: #b0b1b3 !important; }

.bg-300 {
  background-color: #b1b1b3 !important; }

a.bg-300:hover, a.bg-300:focus,
button.bg-300:hover,
button.bg-300:focus {
  background-color: #979899 !important; }

.bg-400 {
  background-color: #979899 !important; }

a.bg-400:hover, a.bg-400:focus,
button.bg-400:hover,
button.bg-400:focus {
  background-color: #7e7e80 !important; }

.bg-500 {
  background-color: #7e7f80 !important; }

a.bg-500:hover, a.bg-500:focus,
button.bg-500:hover,
button.bg-500:focus {
  background-color: #656566 !important; }

.bg-600 {
  background-color: #656566 !important; }

a.bg-600:hover, a.bg-600:focus,
button.bg-600:hover,
button.bg-600:focus {
  background-color: #4c4c4c !important; }

.bg-700 {
  background-color: #4c4c4d !important; }

a.bg-700:hover, a.bg-700:focus,
button.bg-700:hover,
button.bg-700:focus {
  background-color: #323233 !important; }

.bg-800 {
  background-color: #323333 !important; }

a.bg-800:hover, a.bg-800:focus,
button.bg-800:hover,
button.bg-800:focus {
  background-color: #191919 !important; }

.bg-900 {
  background-color: #19191a !important; }

a.bg-900:hover, a.bg-900:focus,
button.bg-900:hover,
button.bg-900:focus {
  background-color: black !important; }

.bg-white {
  background-color: white !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #b1b1b3 !important; }

.border-top {
  border-top: 1px solid #b1b1b3 !important; }

.border-right {
  border-right: 1px solid #b1b1b3 !important; }

.border-bottom {
  border-bottom: 1px solid #b1b1b3 !important; }

.border-left {
  border-left: 1px solid #b1b1b3 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #12b961 !important; }

.border-secondary {
  border-color: #5c6f82 !important; }

.border-success {
  border-color: #00cc85 !important; }

.border-info {
  border-color: #979899 !important; }

.border-warning {
  border-color: #e35452 !important; }

.border-danger {
  border-color: #d44a48 !important; }

.border-light {
  border-color: #e9e6f2 !important; }

.border-dark {
  border-color: #17324d !important; }

.border-100 {
  border-color: #e3e4e6 !important; }

.border-200 {
  border-color: #cacacc !important; }

.border-300 {
  border-color: #b1b1b3 !important; }

.border-400 {
  border-color: #979899 !important; }

.border-500 {
  border-color: #7e7f80 !important; }

.border-600 {
  border-color: #656566 !important; }

.border-700 {
  border-color: #4c4c4d !important; }

.border-800 {
  border-color: #323333 !important; }

.border-900 {
  border-color: #19191a !important; }

.border-white {
  border-color: white !important; }

.rounded-sm {
  border-radius: 2px !important; }

.rounded {
  border-radius: 4px !important; }

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-lg {
  border-radius: 8px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 4px !important; }

.mt-1,
.my-1 {
  margin-top: 4px !important; }

.mr-1,
.mx-1 {
  margin-right: 4px !important; }

.mb-1,
.my-1 {
  margin-bottom: 4px !important; }

.ml-1,
.mx-1 {
  margin-left: 4px !important; }

.m-2 {
  margin: 8px !important; }

.mt-2,
.my-2 {
  margin-top: 8px !important; }

.mr-2,
.mx-2 {
  margin-right: 8px !important; }

.mb-2,
.my-2 {
  margin-bottom: 8px !important; }

.ml-2,
.mx-2 {
  margin-left: 8px !important; }

.m-3 {
  margin: 16px !important; }

.mt-3,
.my-3 {
  margin-top: 16px !important; }

.mr-3,
.mx-3 {
  margin-right: 16px !important; }

.mb-3,
.my-3 {
  margin-bottom: 16px !important; }

.ml-3,
.mx-3 {
  margin-left: 16px !important; }

.m-4 {
  margin: 24px !important; }

.mt-4,
.my-4 {
  margin-top: 24px !important; }

.mr-4,
.mx-4 {
  margin-right: 24px !important; }

.mb-4,
.my-4 {
  margin-bottom: 24px !important; }

.ml-4,
.mx-4 {
  margin-left: 24px !important; }

.m-5 {
  margin: 48px !important; }

.mt-5,
.my-5 {
  margin-top: 48px !important; }

.mr-5,
.mx-5 {
  margin-right: 48px !important; }

.mb-5,
.my-5 {
  margin-bottom: 48px !important; }

.ml-5,
.mx-5 {
  margin-left: 48px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 4px !important; }

.pt-1,
.py-1 {
  padding-top: 4px !important; }

.pr-1,
.px-1 {
  padding-right: 4px !important; }

.pb-1,
.py-1 {
  padding-bottom: 4px !important; }

.pl-1,
.px-1 {
  padding-left: 4px !important; }

.p-2 {
  padding: 8px !important; }

.pt-2,
.py-2 {
  padding-top: 8px !important; }

.pr-2,
.px-2 {
  padding-right: 8px !important; }

.pb-2,
.py-2 {
  padding-bottom: 8px !important; }

.pl-2,
.px-2 {
  padding-left: 8px !important; }

.p-3 {
  padding: 16px !important; }

.pt-3,
.py-3 {
  padding-top: 16px !important; }

.pr-3,
.px-3 {
  padding-right: 16px !important; }

.pb-3,
.py-3 {
  padding-bottom: 16px !important; }

.pl-3,
.px-3 {
  padding-left: 16px !important; }

.p-4 {
  padding: 24px !important; }

.pt-4,
.py-4 {
  padding-top: 24px !important; }

.pr-4,
.px-4 {
  padding-right: 24px !important; }

.pb-4,
.py-4 {
  padding-bottom: 24px !important; }

.pl-4,
.px-4 {
  padding-left: 24px !important; }

.p-5 {
  padding: 48px !important; }

.pt-5,
.py-5 {
  padding-top: 48px !important; }

.pr-5,
.px-5 {
  padding-right: 48px !important; }

.pb-5,
.py-5 {
  padding-bottom: 48px !important; }

.pl-5,
.px-5 {
  padding-left: 48px !important; }

.m-n1 {
  margin: -4px !important; }

.mt-n1,
.my-n1 {
  margin-top: -4px !important; }

.mr-n1,
.mx-n1 {
  margin-right: -4px !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -4px !important; }

.ml-n1,
.mx-n1 {
  margin-left: -4px !important; }

.m-n2 {
  margin: -8px !important; }

.mt-n2,
.my-n2 {
  margin-top: -8px !important; }

.mr-n2,
.mx-n2 {
  margin-right: -8px !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -8px !important; }

.ml-n2,
.mx-n2 {
  margin-left: -8px !important; }

.m-n3 {
  margin: -16px !important; }

.mt-n3,
.my-n3 {
  margin-top: -16px !important; }

.mr-n3,
.mx-n3 {
  margin-right: -16px !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -16px !important; }

.ml-n3,
.mx-n3 {
  margin-left: -16px !important; }

.m-n4 {
  margin: -24px !important; }

.mt-n4,
.my-n4 {
  margin-top: -24px !important; }

.mr-n4,
.mx-n4 {
  margin-right: -24px !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -24px !important; }

.ml-n4,
.mx-n4 {
  margin-left: -24px !important; }

.m-n5 {
  margin: -48px !important; }

.mt-n5,
.my-n5 {
  margin-top: -48px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -48px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -48px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -48px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 4px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 4px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 4px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 4px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 4px !important; }
  .m-sm-2 {
    margin: 8px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 8px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 8px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 8px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 8px !important; }
  .m-sm-3 {
    margin: 16px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 16px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 16px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 16px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 16px !important; }
  .m-sm-4 {
    margin: 24px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 24px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 24px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 24px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 24px !important; }
  .m-sm-5 {
    margin: 48px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 48px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 48px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 48px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 48px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 4px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 4px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 4px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 4px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 4px !important; }
  .p-sm-2 {
    padding: 8px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 8px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 8px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 8px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 8px !important; }
  .p-sm-3 {
    padding: 16px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 16px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 16px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 16px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 16px !important; }
  .p-sm-4 {
    padding: 24px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 24px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 24px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 24px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 24px !important; }
  .p-sm-5 {
    padding: 48px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 48px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 48px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 48px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 48px !important; }
  .m-sm-n1 {
    margin: -4px !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -4px !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -4px !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -4px !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -4px !important; }
  .m-sm-n2 {
    margin: -8px !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -8px !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -8px !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -8px !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -8px !important; }
  .m-sm-n3 {
    margin: -16px !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -16px !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -16px !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -16px !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -16px !important; }
  .m-sm-n4 {
    margin: -24px !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -24px !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -24px !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -24px !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -24px !important; }
  .m-sm-n5 {
    margin: -48px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -48px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -48px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -48px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -48px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 4px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 4px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 4px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 4px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 4px !important; }
  .m-md-2 {
    margin: 8px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 8px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 8px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 8px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 8px !important; }
  .m-md-3 {
    margin: 16px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 16px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 16px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 16px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 16px !important; }
  .m-md-4 {
    margin: 24px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 24px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 24px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 24px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 24px !important; }
  .m-md-5 {
    margin: 48px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 48px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 48px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 48px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 48px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 4px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 4px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 4px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 4px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 4px !important; }
  .p-md-2 {
    padding: 8px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 8px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 8px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 8px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 8px !important; }
  .p-md-3 {
    padding: 16px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 16px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 16px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 16px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 16px !important; }
  .p-md-4 {
    padding: 24px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 24px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 24px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 24px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 24px !important; }
  .p-md-5 {
    padding: 48px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 48px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 48px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 48px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 48px !important; }
  .m-md-n1 {
    margin: -4px !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -4px !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -4px !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -4px !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -4px !important; }
  .m-md-n2 {
    margin: -8px !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -8px !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -8px !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -8px !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -8px !important; }
  .m-md-n3 {
    margin: -16px !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -16px !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -16px !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -16px !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -16px !important; }
  .m-md-n4 {
    margin: -24px !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -24px !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -24px !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -24px !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -24px !important; }
  .m-md-n5 {
    margin: -48px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -48px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -48px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -48px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -48px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 4px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 4px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 4px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 4px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 4px !important; }
  .m-lg-2 {
    margin: 8px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 8px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 8px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 8px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 8px !important; }
  .m-lg-3 {
    margin: 16px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 16px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 16px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 16px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 16px !important; }
  .m-lg-4 {
    margin: 24px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 24px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 24px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 24px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 24px !important; }
  .m-lg-5 {
    margin: 48px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 48px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 48px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 48px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 48px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 4px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 4px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 4px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 4px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 4px !important; }
  .p-lg-2 {
    padding: 8px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 8px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 8px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 8px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 8px !important; }
  .p-lg-3 {
    padding: 16px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 16px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 16px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 16px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 16px !important; }
  .p-lg-4 {
    padding: 24px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 24px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 24px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 24px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 24px !important; }
  .p-lg-5 {
    padding: 48px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 48px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 48px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 48px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 48px !important; }
  .m-lg-n1 {
    margin: -4px !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -4px !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -4px !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -4px !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -4px !important; }
  .m-lg-n2 {
    margin: -8px !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -8px !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -8px !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -8px !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -8px !important; }
  .m-lg-n3 {
    margin: -16px !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -16px !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -16px !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -16px !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -16px !important; }
  .m-lg-n4 {
    margin: -24px !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -24px !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -24px !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -24px !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -24px !important; }
  .m-lg-n5 {
    margin: -48px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -48px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -48px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -48px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -48px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 4px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 4px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 4px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 4px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 4px !important; }
  .m-xl-2 {
    margin: 8px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 8px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 8px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 8px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 8px !important; }
  .m-xl-3 {
    margin: 16px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 16px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 16px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 16px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 16px !important; }
  .m-xl-4 {
    margin: 24px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 24px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 24px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 24px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 24px !important; }
  .m-xl-5 {
    margin: 48px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 48px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 48px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 48px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 48px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 4px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 4px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 4px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 4px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 4px !important; }
  .p-xl-2 {
    padding: 8px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 8px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 8px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 8px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 8px !important; }
  .p-xl-3 {
    padding: 16px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 16px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 16px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 16px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 16px !important; }
  .p-xl-4 {
    padding: 24px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 24px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 24px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 24px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 24px !important; }
  .p-xl-5 {
    padding: 48px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 48px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 48px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 48px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 48px !important; }
  .m-xl-n1 {
    margin: -4px !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -4px !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -4px !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -4px !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -4px !important; }
  .m-xl-n2 {
    margin: -8px !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -8px !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -8px !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -8px !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -8px !important; }
  .m-xl-n3 {
    margin: -16px !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -16px !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -16px !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -16px !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -16px !important; }
  .m-xl-n4 {
    margin: -24px !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -24px !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -24px !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -24px !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -24px !important; }
  .m-xl-n5 {
    margin: -48px !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -48px !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -48px !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -48px !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -48px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: "Roboto Mono", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: white !important; }

.text-primary {
  color: #0b733c !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #095c30 !important; }

.text-secondary {
  color: #3d4955 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #323c46 !important; }

.text-success {
  color: #008053 !important; }

a.text-success:hover, a.text-success:focus {
  color: #006642 !important; }

.text-info {
  color: #717273 !important; }

a.text-info:hover, a.text-info:focus {
  color: #646566 !important; }

.text-warning {
  color: #bb211e !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b21f1d !important; }

.text-danger {
  color: #a72a28 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #932523 !important; }

.text-light {
  color: #bdb4d8 !important; }

a.text-light:hover, a.text-light:focus {
  color: #aea3d0 !important; }

.text-dark {
  color: #050b12 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-100 {
  color: #bbbdc1 !important; }

a.text-100:hover, a.text-100:focus {
  color: #aeb0b5 !important; }

.text-200 {
  color: #a3a4a7 !important; }

a.text-200:hover, a.text-200:focus {
  color: #96979a !important; }

.text-300 {
  color: #8a8b8d !important; }

a.text-300:hover, a.text-300:focus {
  color: #7d7e80 !important; }

.text-400 {
  color: #717273 !important; }

a.text-400:hover, a.text-400:focus {
  color: #646566 !important; }

.text-500 {
  color: #585859 !important; }

a.text-500:hover, a.text-500:focus {
  color: #4b4c4c !important; }

.text-600 {
  color: #3f3f40 !important; }

a.text-600:hover, a.text-600:focus {
  color: #323233 !important; }

.text-700 {
  color: #262626 !important; }

a.text-700:hover, a.text-700:focus {
  color: #191919 !important; }

.text-800 {
  color: #0c0c0d !important; }

a.text-800:hover, a.text-800:focus {
  color: black !important; }

.text-900 {
  color: black !important; }

a.text-900:hover, a.text-900:focus {
  color: black !important; }

.text-body {
  color: #19191a !important; }

.text-muted {
  color: #5a768a !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

::-moz-selection {
  color: white;
  background-color: #d44a48; }

::selection {
  color: white;
  background-color: #d44a48; }

html,
body {
  font-family: "Rubik", Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #32465c;
  letter-spacing: 0;
  line-height: 1.6;
  background-color: #f5f8fa;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern';
  font-kerning: normal;
  font-variant-ligatures: common-ligatures; }
  @media (min-width: 576px) {
    html,
    body {
      font-size: 18px;
      line-height: 1.555; } }

h1, .h1 {
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-size: 2rem;
  color: #32465c; }
  @media (min-width: 576px) {
    h1, .h1 {
      font-size: 2.666rem;
      line-height: 1.25; } }

h2, .h2 {
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-size: 1.3em;
  color: #32465c;
  line-height: 1.25; }
  @media (min-width: 576px) {
    h2, .h2 {
      font-size: 2.222rem;
      line-height: 1.2; } }

h3, .h3 {
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-size: 1.2em;
  font-weight: 600;
  color: #32465c;
  line-height: 1.1428; }
  @media (min-width: 576px) {
    h3, .h3 {
      font-size: 1.777rem;
      line-height: 1.25; } }

h4, .h4 {
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-size: 1.1em;
  font-weight: 600;
  color: #32465c;
  line-height: 1.1666; }
  @media (min-width: 576px) {
    h4, .h4 {
      font-size: 1.555rem;
      font-weight: 600;
      line-height: 1.428; } }

h5, .h5 {
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-size: 1.05em;
  font-weight: 400;
  color: #32465c; }
  @media (min-width: 576px) {
    h5, .h5 {
      font-size: 1.333rem; } }

h6, .h6 {
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5; }

a {
  color: #03a87c; }

.documentFirstHeading {
  display: block;
  width: 100%;
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-size: 2rem;
  font-weight: 600;
  color: #32465c;
  line-height: 1.25; }
  .documentFirstHeading a {
    position: relative;
    display: block;
    width: 100%;
    color: #32465c; }
    .documentFirstHeading a::after {
      content: '\e80e';
      position: absolute;
      top: 10px;
      right: 0.5em;
      display: inline-block;
      width: 1em;
      font-family: 'italia-icon-font';
      font-size: 0.8rem;
      color: #03a87c;
      speak: none; }

.display-1 {
  font-size: 3.111rem; }
  @media (min-width: 576px) {
    .display-1 {
      font-size: 3.111rem;
      line-height: 1.428; } }

blockquote,
.blockquote {
  margin: 1.5rem 0;
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif; }

caption {
  font-size: 0.777rem;
  line-height: 1.4285; }

b,
strong {
  font-weight: 600; }

small,
.small {
  font-size: 0.9375rem;
  font-weight: 400; }
  @media (min-width: 576px) {
    small,
    .small {
      font-size: 0.8888rem; } }

.x-small {
  font-size: 0.875rem;
  font-weight: 400; }
  @media (min-width: 576px) {
    .x-small {
      font-size: 0.7777rem; } }

.blockquote-footer {
  color: #656566; }
  .bg-dark .blockquote-footer {
    color: #979899; }

.bg-light-green {
  background-color: #d7f1ea; }

.badge-primary {
  margin-bottom: 7px;
  color: white; }

.row.variable-gutters {
  margin-right: -6px;
  margin-left: -6px; }
  @media (min-width: 576px) {
    .row.variable-gutters {
      margin-right: -6px;
      margin-left: -6px; }
      .row.variable-gutters > .col,
      .row.variable-gutters > [class*='col-'] {
        padding-right: 6px;
        padding-left: 6px; } }
  @media (min-width: 768px) {
    .row.variable-gutters {
      margin-right: -10px;
      margin-left: -10px; }
      .row.variable-gutters > .col,
      .row.variable-gutters > [class*='col-'] {
        padding-right: 10px;
        padding-left: 10px; } }
  @media (min-width: 992px) {
    .row.variable-gutters {
      margin-right: -10px;
      margin-left: -10px; }
      .row.variable-gutters > .col,
      .row.variable-gutters > [class*='col-'] {
        padding-right: 10px;
        padding-left: 10px; } }
  @media (min-width: 1200px) {
    .row.variable-gutters {
      margin-right: -14px;
      margin-left: -14px; }
      .row.variable-gutters > .col,
      .row.variable-gutters > [class*='col-'] {
        padding-right: 14px;
        padding-left: 14px; } }
  .row.variable-gutters > .col,
  .row.variable-gutters > [class*='col-'] {
    padding-right: 6px;
    padding-left: 6px; }

.table th,
.table td {
  text-align: left;
  border-bottom: 1px solid #d6dce3;
  border-top: none; }

.breadcrumb-container .breadcrumb {
  padding: 0;
  border-radius: 0;
  font-size: 0.7rem; }
  .breadcrumb-container .breadcrumb .breadcrumb-item {
    padding-left: 0; }
    .breadcrumb-container .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      display: none; }
    .breadcrumb-container .breadcrumb .breadcrumb-item i {
      padding-right: 0.5em; }
    .breadcrumb-container .breadcrumb .breadcrumb-item a {
      font-weight: 600;
      color: #647d72; }
    .breadcrumb-container .breadcrumb .breadcrumb-item.active a {
      pointer-events: none;
      font-weight: normal; }
    .breadcrumb-container .breadcrumb .breadcrumb-item span.separator {
      padding: 0 0.5em;
      display: inline-block;
      font-weight: 600; }
  .breadcrumb-container .breadcrumb.dark {
    background: #4b6b5d; }
    .breadcrumb-container .breadcrumb.dark .breadcrumb-item a {
      color: white; }
    .breadcrumb-container .breadcrumb.dark .breadcrumb-item span.separator {
      color: white; }
    .breadcrumb-container .breadcrumb.dark .breadcrumb-item i {
      color: #0bd9d2; }

.modal-content,
.modal-header {
  border-radius: 0; }

*[data-toggle='collapse'] .collapse-icon::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 0.5rem solid;
  border-right: 0.5rem solid transparent;
  border-bottom: 0;
  border-left: 0.5rem solid transparent;
  vertical-align: 0.1875rem; }

*[data-toggle='collapse'].collapsed .collapse-icon::before {
  transform: rotate(180deg); }

.collapse-div {
  border-bottom: 1px solid #e3e4e6; }
  .collapse-div .collapse-div {
    border: 1px solid #e3e4e6;
    border-top: 0; }

.collapse-header {
  position: relative; }
  .collapse-header *[data-toggle='collapse'] {
    padding: 14px 24px;
    width: 100%;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    color: #5c6f82;
    text-align: left;
    background-color: transparent;
    border-top: 1px solid #e3e4e6; }
    .collapse-header *[data-toggle='collapse'][aria-expanded='false'] {
      color: #12b961; }
      .collapse-header *[data-toggle='collapse'][aria-expanded='false']::after {
        content: '\e818'; }
    .collapse-header *[data-toggle='collapse'][aria-expanded='true']::before {
      content: '';
      position: absolute;
      top: 0;
      left: 20px;
      display: block;
      width: 56px;
      height: 2px;
      border-radius: 2px;
      background-color: #12b961; }
    .collapse-header *[data-toggle='collapse'][aria-expanded='false']:hover::after, .collapse-header *[data-toggle='collapse'][aria-expanded='true']:hover::after {
      text-decoration: none; }
    .collapse-header *[data-toggle='collapse']::after {
      content: '\e810';
      float: right;
      margin-top: 5px;
      margin-right: 0.2em;
      width: 1em;
      font-family: 'italia-icon-font';
      font-weight: normal;
      font-style: normal;
      line-height: 1em;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
      font-variant: normal; }
    .collapse-header *[data-toggle='collapse']:hover {
      text-decoration: underline;
      background-color: #e6ecf2; }
    .collapse-header *[data-toggle='collapse']:active {
      color: white;
      background-color: #12b961;
      border-color: #12b961; }

.collapse-body {
  padding: 12px 24px 42px; }
  .collapse-body .collapse-header button[aria-expanded='true']::before {
    width: 0;
    height: 0; }

.carousel {
  padding: 0;
  background-color: #444e57; }
  .carousel-item {
    max-height: 400px; }
    .carousel-item img {
      width: 100%;
      height: 400px;
      object-fit: cover; }
  .carousel a {
    color: white; }
  .carousel .documentFirstHeading::after {
    display: none; }

.carousel-control-prev,
.carousel-control-next {
  top: 1em;
  align-items: flex-start; }

.carousel-control-prev {
  right: 6em;
  left: auto; }

.carousel-control-next {
  right: 3em; }

.carousel-caption {
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 0.75em;
  text-align: left;
  background: #32465c;
  background: linear-gradient(to right, rgba(45, 105, 169, 0.71), rgba(50, 71, 93, 0.48)) !important;
  background-color: #32465c;
  background-image: linear-gradient(to right, rgba(45, 105, 169, 0.71), rgba(50, 71, 93, 0.48)) !important; }
  .carousel-caption-content {
    margin: 2vh auto;
    max-width: 40vw; }
    .carousel-caption-content h5 {
      color: white; }
      .carousel-caption-content h5 a {
        color: white; }
    .carousel-caption-content p {
      margin-bottom: 0;
      font-weight: 300;
      color: white; }
      .carousel-caption-content p span {
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase; }

.carousel-indicators li {
  background-color: rgba(18, 185, 97, 0.7); }

.carousel-indicators .active {
  background-color: #5c6f82; }

ul.list-group {
  margin-bottom: 40px; }

.list-group-item {
  padding: 1rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    cursor: default; }

.list-group-item-action:hover, .list-group-item-action:focus {
  text-decoration: underline;
  background-color: #d9e6f2; }

.list-group-item-action.disabled:hover, .list-group-item-action.disabled:focus, .list-group-item-action:disabled:hover, .list-group-item-action:disabled:focus {
  border-top-color: transparent;
  text-decoration: none;
  background-color: transparent; }

.list-group-item.active:hover, .list-group-item.active:focus {
  background-color: #12b961; }

.alert {
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem 1rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.5rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.8rem 1rem;
    color: inherit; }

.alert-success {
  background-repeat: no-repeat;
  background-position: 1em 0.8em;
  border-left: 8px solid #00cc85; }

.alert-info {
  background-repeat: no-repeat;
  background-position: 1em 0.8em;
  border-left: 8px solid #979899; }

.alert-warning {
  background-repeat: no-repeat;
  background-position: 1em 0.8em;
  border-left: 8px solid #e35452; }

.alert-danger {
  background-repeat: no-repeat;
  background-position: 1em 0.8em;
  border-left: 8px solid #d44a48; }

.alert-danger {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eerror%3C/title%3E%3Cpath d='M16 1.6C24 1.6 30.4 8 30.4 16S24 30.4 16 30.4 1.6 24 1.6 16 8 1.6 16 1.6zM16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z'/%3E%3Cpath d='M14.4 24c0-.96.64-1.6 1.6-1.6s1.6.64 1.6 1.6c0 .96-.64 1.6-1.6 1.6s-1.6-.64-1.6-1.6zM15.04 20.8l-.32-14.4h2.56l-.32 14.4z'/%3E%3C/svg%3E"); }

.alert-warning {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Ewarning%3C/title%3E%3Cpath d='M14.333 26c0-1 .667-1.667 1.667-1.667S17.667 25 17.667 26 17 27.667 16 27.667 14.333 27 14.333 26zM15 22.667l-.333-15h2.667l-.333 15z'/%3E%3Cpath d='M16 2.667c1 0 2 .5 2.5 1.333l10.333 20.833c.5.833.5 2 0 2.833s-1.5 1.5-2.5 1.5H5.666c-1 0-2-.5-2.5-1.5-.5-.833-.5-2 0-2.833L13.499 4c.5-.833 1.5-1.333 2.5-1.333zM16 1c-1.5 0-3 .833-4 2.333L1.667 24.166c-1.667 3 .5 6.833 4 6.833h20.667c3.5 0 5.667-3.833 4-6.833L20.001 3.333c-1-1.667-2.5-2.333-4-2.333z'/%3E%3C/svg%3E"); }

.alert-success {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Echeck%3C/title%3E%3Cpath d='M32 6.682l-2.824-2.635-18.447 18.635-8.094-8.094L0 17.412l8.094 7.906h-.188l2.824 2.635 2.635-2.635z'/%3E%3C/svg%3E"); }

.alert-info {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Einfo%3C/title%3E%3Cpath d='M16 1.6C24 1.6 30.4 8 30.4 16S24 30.4 16 30.4 1.6 24 1.6 16 8.16 1.6 16 1.6zM16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z'/%3E%3Cpath d='M14.72 9.76V6.72h2.88v3.04h-2.88zm0 15.84V12h2.88v13.6h-2.88z'/%3E%3C/svg%3E"); }

.btn {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 300;
  color: white;
  white-space: nowrap; }

.btn-xs, .btn-sm, .btn-group-sm > .btn, .btn-lg, .btn-group-lg > .btn {
  border-radius: 4px;
  line-height: 1.5; }

.btn-xs {
  padding: 8px;
  font-size: 14px;
  line-height: 1.428; }

.btn-sm, .btn-group-sm > .btn {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.428; }

.btn-lg, .btn-group-lg > .btn {
  padding: 14px 28px;
  font-size: 18px;
  line-height: 1.556; }

.btn-block {
  border-radius: 0; }

.btn-primary:disabled, .btn-primary.disabled {
  color: #bfc2c9;
  background-color: #e6e9f2;
  border-color: #dfe4f2; }

.btn-outline-secondary {
  box-shadow: inset 0 0 0 1px #e6e9f2;
  background: #fcfdff; }
  .btn-outline-secondary:hover {
    box-shadow: inset 0 0 0 1px #c9cedc; }

.bg-dark .btn-link {
  color: white; }

.bg-dark .btn-primary {
  background-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: #12b961;
  color: #12b961; }
  .bg-dark .btn-primary:hover {
    background-color: white;
    color: #19191a;
    border-color: #0d8b49; }
  .bg-dark .btn-primary:focus, .bg-dark .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 185, 97, 0.5); }
  .bg-dark .btn-primary.disabled, .bg-dark .btn-primary:disabled {
    color: #19191a;
    background-color: white;
    border-color: #12b961; }
  .bg-dark .btn-primary:not(:disabled):not(.disabled):active, .bg-dark .btn-primary:not(:disabled):not(.disabled).active,
  .show > .bg-dark .btn-primary.dropdown-toggle {
    color: #19191a;
    background-color: white;
    border-color: #0c7f43; }
    .bg-dark .btn-primary:not(:disabled):not(.disabled):active:focus, .bg-dark .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .bg-dark .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(18, 185, 97, 0.5); }
  .bg-dark .btn-primary:hover {
    color: #0d8b49; }
  .bg-dark .btn-primary.disabled, .bg-dark .btn-primary:disabled {
    color: #0d8b49; }

.bg-dark .btn-outline-primary {
  box-shadow: inset 0 0 0 1px white;
  color: white;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 2px white; }
  .bg-dark .btn-outline-primary:hover {
    box-shadow: inset 0 0 0 1px #e6e6e6;
    color: #e6e6e6; }
  .bg-dark .btn-outline-primary:focus, .bg-dark .btn-outline-primary.focus {
    box-shadow: inset 0 0 0 1px #e6e6e6, 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-outline-primary.disabled, .bg-dark .btn-outline-primary:disabled {
    color: white;
    background-color: transparent; }
  .bg-dark .btn-outline-primary:not(:disabled):not(.disabled):active, .bg-dark .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .bg-dark .btn-outline-primary.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #e6e6e6;
    color: #e6e6e6;
    background-color: transparent;
    background-image: none; }
    .bg-dark .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .bg-dark .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .bg-dark .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-outline-primary:hover {
    box-shadow: inset 0 0 0 2px #e6e6e6; }
  .bg-dark .btn-outline-primary:focus, .bg-dark .btn-outline-primary.focus {
    box-shadow: inset 0 0 0 2px white, 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-outline-primary:not(:disabled):not(.disabled).active, .bg-dark .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .bg-dark .btn-outline-primary.dropdown-toggle {
    box-shadow: inset 0 0 0 2px white; }

.bg-dark .btn-secondary {
  background-color: #12b961;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #19191a;
  border-color: white;
  color: white; }
  .bg-dark .btn-secondary:hover {
    background-color: #15dc73;
    color: #19191a;
    border-color: #e6e6e6; }
  .bg-dark .btn-secondary:focus, .bg-dark .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-secondary.disabled, .bg-dark .btn-secondary:disabled {
    color: #19191a;
    background-color: #12b961;
    border-color: white; }
  .bg-dark .btn-secondary:not(:disabled):not(.disabled):active, .bg-dark .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .bg-dark .btn-secondary.dropdown-toggle {
    color: #19191a;
    background-color: #17e779;
    border-color: #dfdfdf; }
    .bg-dark .btn-secondary:not(:disabled):not(.disabled):active:focus, .bg-dark .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .bg-dark .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-secondary.btn-tooltip-round {
    background: #d44a48; }

.bg-dark .btn-outline-secondary {
  box-shadow: inset 0 0 0 1px white;
  color: white;
  background-color: transparent;
  background-image: none;
  box-shadow: none; }
  .bg-dark .btn-outline-secondary:hover {
    box-shadow: inset 0 0 0 1px #e6e6e6;
    color: #e6e6e6; }
  .bg-dark .btn-outline-secondary:focus, .bg-dark .btn-outline-secondary.focus {
    box-shadow: inset 0 0 0 1px #e6e6e6, 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-outline-secondary.disabled, .bg-dark .btn-outline-secondary:disabled {
    color: white;
    background-color: transparent; }
  .bg-dark .btn-outline-secondary:not(:disabled):not(.disabled):active, .bg-dark .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .bg-dark .btn-outline-secondary.dropdown-toggle {
    box-shadow: inset 0 0 0 1px #e6e6e6;
    color: #e6e6e6;
    background-color: transparent;
    background-image: none; }
    .bg-dark .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .bg-dark .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .bg-dark .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-outline-secondary:hover {
    box-shadow: none; }
  .bg-dark .btn-outline-secondary:focus, .bg-dark .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .bg-dark .btn-outline-secondary:not(:disabled):not(.disabled).active, .bg-dark .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show > .bg-dark .btn-outline-secondary.dropdown-toggle {
    box-shadow: none; }

.btn-secondary.btn-tooltip-round {
  z-index: 5;
  position: absolute;
  top: -7px;
  left: -4%;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  text-align: center;
  background-color: #d44a48; }

.tooltip {
  color: #32465c;
  background-color: transparent; }
  .tooltip-inner {
    box-shadow: 0 0 11px #ccc;
    border: none;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 300;
    color: #32465c;
    background-color: white; }
  .tooltip .arrow::before,
  .tooltip .arrow::before {
    bottom: 0;
    border-top-color: #fff; }

.callout {
  position: relative;
  margin: 1.75rem 0 1rem;
  padding: 2.5rem 2.222rem;
  border: 1px solid #647d72;
  border-radius: 8px; }
  .callout.callout-highlight {
    padding: 0 2.222rem;
    border: none;
    border-left: 2px solid rgba(223, 228, 242, 0.3);
    border-radius: 0; }
    .callout.callout-highlight .callout-title {
      margin-bottom: 1.556rem; }
    .callout.callout-highlight.success {
      border-color: rgba(0, 204, 133, 0.2); }
    .callout.callout-highlight.warning {
      border-color: rgba(227, 84, 82, 0.2); }
    .callout.callout-highlight.danger {
      border-color: rgba(212, 74, 72, 0.2); }
    .callout.callout-highlight.important {
      border-color: rgba(128, 0, 43, 0.2); }
    .callout.callout-highlight.note {
      border-color: rgba(3, 128, 94, 0.2); }
  .callout.callout-more {
    position: relative;
    padding: 2.2rem;
    border: none;
    border-radius: 0;
    background: #dae5ec; }
    .callout.callout-more::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 48px 48px 0;
      border-color: transparent #f5f8fa transparent transparent; }
    .callout.callout-more::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 48px 0 0 48px;
      border-color: transparent transparent transparent #b8cbd8; }
    .callout.callout-more .callout-title {
      margin-bottom: 2.222rem;
      display: flex;
      align-items: flex-start; }
      .callout.callout-more .callout-title i {
        font-size: 1.25rem; }
      .callout.callout-more .callout-title span {
        margin-top: -0.111rem;
        border-bottom: 2px solid #19191a;
        padding-bottom: 0.1rem;
        display: inline-block; }
  .callout.success {
    border-color: #00cc85; }
    .callout.success .callout-title {
      color: #00cc85; }
      .callout.success .callout-title .icon {
        fill: #00cc85; }
      .callout.success .callout-title span {
        border-color: #00cc85; }
  .callout.warning {
    border-color: #e35452; }
    .callout.warning .callout-title {
      color: #e35452; }
      .callout.warning .callout-title .icon {
        fill: #e35452; }
      .callout.warning .callout-title span {
        border-color: #e35452; }
  .callout.danger {
    border-color: #d44a48; }
    .callout.danger .callout-title {
      color: #d44a48; }
      .callout.danger .callout-title .icon {
        fill: #d44a48; }
      .callout.danger .callout-title span {
        border-color: #d44a48; }
  .callout.important {
    border-color: #80002b; }
    .callout.important .callout-title {
      color: #80002b; }
      .callout.important .callout-title .icon {
        fill: #80002b; }
      .callout.important .callout-title span {
        border-color: #80002b; }
  .callout.note {
    border-color: #03a87c; }
    .callout.note .callout-title {
      color: #03a87c; }
      .callout.note .callout-title .icon {
        fill: #03a87c; }
      .callout.note .callout-title span {
        border-color: #03a87c; }
    .callout.note .collapse-div .collapse-header .callout-more-toggle {
      color: #03a87c; }
      .callout.note .collapse-div .collapse-header .callout-more-toggle:active, .callout.note .collapse-div .collapse-header .callout-more-toggle:hover {
        color: #03a87c; }
      .callout.note .collapse-div .collapse-header .callout-more-toggle span {
        border-color: #03a87c; }
        .callout.note .collapse-div .collapse-header .callout-more-toggle span::before, .callout.note .collapse-div .collapse-header .callout-more-toggle span::after {
          background: #03a87c; }
    .callout.note a {
      color: #03a87c; }
    .callout.note .icon {
      fill: #03a87c; }
  .callout .callout-title {
    margin-right: 2.222rem;
    font-size: 1rem;
    font-weight: bold;
    color: #32465c;
    letter-spacing: 0.06rem;
    text-transform: uppercase; }
    .callout .callout-title .icon {
      margin-right: 0.444rem;
      color: #32465c; }
  .callout:not(.callout-highlight):not(.callout-more) .callout-title {
    position: absolute;
    top: -0.78em;
    padding: 0 1.389rem;
    font-size: 1rem;
    background: white; }
  .callout p:last-child {
    margin: 0; }
  .callout p.callout-big-text {
    font-size: 1.111rem;
    font-weight: bold; }
  .callout .collapse-div {
    display: flex;
    flex-direction: column-reverse;
    border-bottom: none; }
    .callout .collapse-div .collapse-header {
      margin-top: 0;
      padding: 1.333rem 0 0;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #dfe4f2; }
      .callout .collapse-div .collapse-header .callout-more-toggle {
        padding: 0;
        display: flex;
        align-items: center;
        width: auto;
        border: none;
        font-weight: normal;
        color: #12b961; }
        .callout .collapse-div .collapse-header .callout-more-toggle:hover, .callout .collapse-div .collapse-header .callout-more-toggle:focus, .callout .collapse-div .collapse-header .callout-more-toggle:active {
          background: none; }
        .callout .collapse-div .collapse-header .callout-more-toggle:hover {
          color: #0b733c; }
          .callout .collapse-div .collapse-header .callout-more-toggle:hover span {
            border-color: #0b733c; }
            .callout .collapse-div .collapse-header .callout-more-toggle:hover span::before, .callout .collapse-div .collapse-header .callout-more-toggle:hover span::after {
              background: #0b733c; }
        .callout .collapse-div .collapse-header .callout-more-toggle:active {
          color: #12b961; }
        .callout .collapse-div .collapse-header .callout-more-toggle::before, .callout .collapse-div .collapse-header .callout-more-toggle::after {
          display: none; }
        .callout .collapse-div .collapse-header .callout-more-toggle span {
          position: relative;
          margin-top: 0.111rem;
          margin-left: 0.444rem;
          display: inline-block;
          width: 15px;
          height: 15px;
          border: 1px solid #12b961;
          border-radius: 50%; }
          .callout .collapse-div .collapse-header .callout-more-toggle span::before, .callout .collapse-div .collapse-header .callout-more-toggle span::after {
            content: '';
            position: absolute;
            background: #12b961; }
          .callout .collapse-div .collapse-header .callout-more-toggle span::before {
            top: 6px;
            left: 2px;
            width: 9px;
            height: 1px; }
          .callout .collapse-div .collapse-header .callout-more-toggle span::after {
            top: 0;
            left: 6px;
            width: 1px;
            height: 9px;
            transform: translateY(2px);
            transition: transform 0.2s; }
        .callout .collapse-div .collapse-header .callout-more-toggle[aria-expanded='true'] span::after {
          transform: translateY(2px) rotate(90deg); }
      .callout .collapse-div .collapse-header .callout-more-download {
        margin-left: auto;
        font-weight: normal; }
    .callout .collapse-div .collapse-body {
      padding: 0; }
      .callout .collapse-div .collapse-body p:last-child {
        margin-bottom: 1rem; }

/* stylelint-disable */
_:-ms-fullscreen,
.callout {
  max-width: 32rem; }

@media (min-width: 768px) {
  .callout.callout-highlight {
    padding: 0 0 0 2.222rem; } }

#searchForm input[type='text'] {
  margin-right: 0;
  height: 2rem;
  border: 1px solid #d6dfe9;
  border-radius: 25px;
  font-size: 0.7em;
  font-weight: 400;
  color: #32465c;
  letter-spacing: 1px;
  background-color: white;
  min-width: 250px; }

.btn.btn-search {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 35px;
  height: 35px;
  min-width: 35px;
  box-shadow: 0 0 11px #eaf0f6;
  border-radius: 50%;
  text-align: center;
  background-color: white; }
  .btn.btn-search i {
    font-size: 0.95em;
    font-weight: 700;
    color: #03a87c;
    background-color: transparent; }

#catalogo-search {
  margin-bottom: 40px;
  padding: 1.5em;
  border-radius: 5px;
  background-color: #d6dfe9; }
  #catalogo-search form {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
  #catalogo-search .form-control {
    margin-right: 0;
    height: 2rem;
    border: 1px solid #d6dfe9;
    border-radius: 25px;
    font-size: 0.7em;
    font-weight: 400;
    color: #32465c;
    letter-spacing: 1px;
    background-color: white;
    min-width: 40%; }

.form-control,
.custom-select {
  border: 1px solid #b5c5d7; }

.btn-full {
  display: block;
  width: 100%; }

.btn-eye {
  z-index: 10;
  position: absolute;
  top: 2.25rem;
  right: 0.5rem;
  width: 28px;
  height: 22px;
  cursor: pointer;
  color: #5c6f82;
  background-repeat: no-repeat !important;
  background-position: center !important; }

.eye-on {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%235c6f82' viewBox='0 0 576 512'%3E%3Cpath d='M272.702 359.139c-80.483-9.011-136.212-86.886-116.93-167.042l116.93 167.042zM288 392c-102.556 0-192.092-54.701-240-136 21.755-36.917 52.1-68.342 88.344-91.658l-27.541-39.343C67.001 152.234 31.921 188.741 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.004 376.006 168.14 440 288 440a332.89 332.89 0 0 0 39.648-2.367l-32.021-45.744A284.16 284.16 0 0 1 288 392zm281.354-111.631c-33.232 56.394-83.421 101.742-143.554 129.492l48.116 68.74c3.801 5.429 2.48 12.912-2.949 16.712L450.23 509.83c-5.429 3.801-12.912 2.48-16.712-2.949L102.084 33.399c-3.801-5.429-2.48-12.912 2.949-16.712L125.77 2.17c5.429-3.801 12.912-2.48 16.712 2.949l55.526 79.325C226.612 76.343 256.808 72 288 72c119.86 0 224.996 63.994 281.354 159.631a48.002 48.002 0 0 1 0 48.738zM528 256c-44.157-74.933-123.677-127.27-216.162-135.007C302.042 131.078 296 144.83 296 160c0 30.928 25.072 56 56 56s56-25.072 56-56l-.001-.042c30.632 57.277 16.739 130.26-36.928 171.719l26.695 38.135C452.626 346.551 498.308 306.386 528 256z'/%3E%3C/svg%3E"); }

.eye-off {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%235c6f82' viewBox='0 0 576 512'%3E%3Cpath d='M569.354 231.631C512.97 135.949 407.81 72 288 72 168.14 72 63.004 135.994 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.031 376.051 168.19 440 288 440c119.86 0 224.996-63.994 281.354-159.631a47.997 47.997 0 0 0 0-48.738zM288 392c-102.556 0-192.091-54.701-240-136 44.157-74.933 123.677-127.27 216.162-135.007C273.958 131.078 280 144.83 280 160c0 30.928-25.072 56-56 56s-56-25.072-56-56l.001-.042C157.794 179.043 152 200.844 152 224c0 75.111 60.889 136 136 136s136-60.889 136-136c0-31.031-10.4-59.629-27.895-82.515C451.704 164.638 498.009 205.106 528 256c-47.908 81.299-137.444 136-240 136z'/%3E%3C/svg%3E"); }

/* Password Strength Meter */
.psw-wrapper {
  padding: 0 0.75rem; }

.psw-meter {
  height: 3px;
  max-width: 180px; }

.psw-meter .col-3 {
  height: 3px; }

.psw-percent,
.psw-text {
  padding: 0.25rem 0;
  display: block;
  font-size: 0.75rem;
  font-weight: 500; }

.psw-percent {
  margin-right: 5px; }

@keyframes dropdownFadeIn {
  0% {
    margin-top: 0;
    opacity: 0; }
  100% {
    margin-top: 16px;
    opacity: 1; } }

@keyframes dropdownFadeInTop {
  0% {
    margin-top: 0;
    opacity: 0; }
  100% {
    margin-top: -16px;
    opacity: 1; } }

.dropdown .btn-group.show .dropdown-menu[x-placement='bottom-start'] {
  animation: dropdownFadeIn forwards 0.3s; }

.dropdown .btn-group.show .dropdown-menu[x-placement='top-start'] {
  animation: dropdownFadeInTop forwards 0.3s; }

.btn-dropdown {
  padding: 0 4px;
  border-radius: 0;
  font-size: 1rem;
  color: #0e5c3f;
  background-color: transparent; }
  .btn-dropdown:not(:disabled):not(.disabled):active {
    box-shadow: none; }
  .btn-dropdown::after {
    content: "";
    display: inline-block;
    width: auto;
    height: auto;
    border: none;
    font-family: 'italia-icon-font';
    font-size: 0.8rem;
    font-weight: normal;
    font-style: normal;
    vertical-align: 0;
    transition: transform 0.3s;
    speak: none; }
  .btn-dropdown[aria-expanded=true]::after {
    transform: scaleY(-1); }

.dropdown-menu {
  border-radius: 0 0 4px 4px; }
  .dropdown-menu.full-width {
    width: 100%; }
    .dropdown-menu.full-width .link-list li {
      display: inline-block;
      width: auto; }
      .dropdown-menu.full-width .link-list li:hover, .dropdown-menu.full-width .link-list li:focus {
        text-decoration: underline;
        background: none; }
  .dropdown-menu .link-list {
    margin-bottom: 0; }
    .dropdown-menu .link-list h3 {
      margin-bottom: 0;
      line-height: 2.3em; }
  .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 24px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: white;
    transform: rotate(45deg); }
  .dropdown-menu[x-placement='top-start'] {
    border-radius: 4px 4px 0 0; }
    .dropdown-menu[x-placement='top-start']::before {
      top: auto;
      bottom: -6px; }
  .dropdown-menu.dark {
    background-color: #4b6b5d; }
    .dropdown-menu.dark .link-list-wrapper ul span.divider {
      background: #2e465e; }
    .dropdown-menu.dark .link-list-wrapper ul li a span,
    .dropdown-menu.dark .link-list-wrapper ul li a:hover span,
    .dropdown-menu.dark .link-list-wrapper ul li h3,
    .dropdown-menu.dark .link-list-wrapper ul li i {
      color: #fff; }
    .dropdown-menu.dark .link-list-wrapper ul li a:not(.active):not(.disabled):hover i {
      color: #4fe0dc; }
    .dropdown-menu.dark .link-list-wrapper ul li a.disabled span {
      color: #b1b3b2; }
    .dropdown-menu.dark::before {
      background-color: #4b6b5d; }

nav.pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  nav.pagination-wrapper.pagination-total {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center; }
    nav.pagination-wrapper.pagination-total ul {
      margin-bottom: 0.5rem; }
    nav.pagination-wrapper.pagination-total p {
      margin-bottom: 1rem;
      font-size: 0.88889rem;
      font-weight: 600;
      color: #647d72;
      text-align: center; }
  nav.pagination-wrapper .form-group {
    margin-top: 0;
    margin-left: 2.5rem;
    width: 4.5rem; }
    nav.pagination-wrapper .form-group label {
      font-size: 0.88889rem;
      color: #9da2a6; }
    nav.pagination-wrapper .form-group.active label {
      font-size: 0.8rem; }
  nav.pagination-wrapper .btn.dropdown-toggle {
    padding: 12px;
    width: 100%;
    height: 2.5rem;
    border: none;
    font-size: 0.88889rem;
    font-weight: 600;
    color: #647d72;
    border-bottom: 1px solid #5c6f82; }
    nav.pagination-wrapper .btn.dropdown-toggle::after {
      margin-left: 0.5rem;
      color: #12b961; }

.pagination {
  flex-wrap: wrap; }
  .pagination .page-item {
    margin-right: 5px; }
    .pagination .page-item.disabled a.page-link {
      color: #c2c7cc; }
      .pagination .page-item.disabled a.page-link i[class^='it-'] {
        color: #c2c7cc; }
    .pagination .page-item span.page-link {
      pointer-events: none; }
    .pagination .page-item .page-link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      border: none;
      border-radius: 4px;
      font-size: 0.88889rem;
      font-weight: 600;
      color: #647d72;
      background-color: transparent;
      min-width: 2rem; }
      .pagination .page-item .page-link[aria-current] {
        border: 1px solid #0e5c3f;
        pointer-events: none;
        color: #0e5c3f; }
      .pagination .page-item .page-link i[class^='it-'], .pagination .page-item .page-link.text {
        color: #12b961; }
      .pagination .page-item .page-link i[class^='it-'] {
        font-size: 0.7rem; }
      .pagination .page-item .page-link span.d-inline-block {
        margin-right: 0.5rem; }
      .pagination .page-item .page-link:hover {
        color: #0e5c3f;
        background-color: transparent; }
        .pagination .page-item .page-link:hover i[class^='it-'] {
          color: #0e5c3f; }

@media (min-width: 576px) {
  nav.pagination-wrapper .btn.dropdown-toggle {
    height: 2.66667rem; } }

@media (min-width: 768px) {
  nav.pagination-wrapper {
    justify-content: flex-start; }
    nav.pagination-wrapper .dropdown {
      margin-left: 2.66667rem; } }

@media (max-width: 767.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 1em;
    width: 100%;
    background-color: #323333;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto; }
    .offcanvas-collapse.open {
      transform: translateX(-1rem); } }

.nav-tabs {
  position: relative;
  border-radius: 5px 5px 0 0;
  background-color: #e7ecf2;
  border-bottom: 0; }
  .nav-tabs::after {
    content: '';
    z-index: -1;
    position: absolute;
    right: 10%;
    bottom: 0;
    left: 10%;
    width: 80%;
    height: 50%;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
    border-radius: 100%; }
  .nav-tabs .nav-link {
    border: none;
    border-radius: 0;
    font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
    font-weight: 600;
    color: #5c6f82;
    border-bottom: 2px solid transparent; }
    .nav-tabs .nav-link.disabled {
      font-weight: 400;
      color: #b1b1b3; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-item.active .nav-link,
  .nav-tabs .nav-item.in .nav-link {
    cursor: inherit;
    color: #0e5c3f;
    border-bottom: 2px solid #0e5c3f;
    background-color: white; }
  .nav-tabs .nav-item {
    margin-bottom: 0; }
  .nav-tabs.nav-dark {
    background-color: #455b71; }
    .nav-tabs.nav-dark .nav-link {
      color: #c7d1d0; }
    .nav-tabs.nav-dark .nav-link.active,
    .nav-tabs.nav-dark .nav-item.active .nav-link,
    .nav-tabs.nav-dark .nav-item.in .nav-link,
    .nav-tabs.nav-dark .nav-item.show .nav-link {
      color: #9facbd;
      background-color: #455b71;
      border-bottom: 2px solid #9facbd; }

.it-ico-sm {
  font-size: 1.75rem; }

.it-ico {
  font-size: 1.25rem; }

.it-ico-lg {
  font-size: 1.5rem; }

.tab-container {
  margin: 1em 0;
  overflow: hidden; }

.tab-pane {
  padding: 1em 1.5em; }
  .tab-pane ul {
    padding: 0.5rem;
    list-style-type: none; }
    .tab-pane ul li {
      margin-bottom: 0.35em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 30px;
      border-bottom: solid 1px #c5d2e0; }
      .tab-pane ul li:last-child {
        border-bottom: none; }
      .tab-pane ul li span {
        align-self: center;
        font-size: 13px;
        text-align: right; }
      .tab-pane ul li a {
        padding: 0.25em 0;
        font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
        font-weight: 500; }

.column .tab-container {
  margin-top: 0;
  overflow: hidden;
  border-radius: 5px;
  background-color: white; }

.cookiebar {
  z-index: 1100;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%; }
  .cookiebar.cookiebar-top {
    top: 0;
    bottom: auto; }
  .cookiebar.show {
    display: block; }

.hero {
  border-radius: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #cacacc; }
  @media (min-width: 576px) {
    .hero {
      padding: 1rem; } }
  .hero .hero-heading {
    margin-bottom: 0.8rem;
    font-size: 2rem;
    font-weight: 700;
    color: #00264d; }

.hero-fluid {
  border-radius: 0;
  padding-right: 0;
  padding-left: 0; }

.forward {
  display: block;
  font-size: 3rem;
  text-align: center;
  transition: all 0.2s ease-in-out; }
  .forward:hover {
    text-decoration: none; }

/*back to top*/
.return-to-top {
  z-index: 1;
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: block;
  display: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  background: #12b961;
  background: rgba(18, 185, 97, 0.7);
  transition: all 0.2s ease-in-out; }
  .return-to-top i {
    position: relative;
    top: 8px;
    margin: 0;
    font-size: 20px;
    color: white;
    transition: all 0.2s ease-in-out; }
    .return-to-top i::before  {
      margin: 0; }
  .return-to-top:hover {
    text-decoration: none;
    background: rgba(18, 185, 97, 0.9); }
    .return-to-top:hover i {
      top: 4px;
      color: white; }

.autocomplete-clear {
  z-index: 2;
  position: absolute;
  top: 0.5rem;
  right: 0.25rem;
  border: none;
  visibility: hidden;
  cursor: pointer;
  background: transparent; }
  .autocomplete-clear svg {
    fill: #a6a6a6; }

.autocomplete-wrap {
  z-index: 100;
  position: absolute;
  right: 0;
  left: 0;
  padding-left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  background: white;
  max-height: 240px;
  overflow-y: auto;
  list-style-type: none; }
  .autocomplete-wrap li {
    padding: 12px 16px;
    cursor: pointer;
    font-size: 0.875rem; }
    .autocomplete-wrap li:hover {
      background: #eeeeee; }

.link-list-wrapper ul {
  padding: 0;
  list-style-type: none; }
  .link-list-wrapper ul.link-sublist {
    padding-left: 24px; }
  .link-list-wrapper ul li a {
    position: relative;
    padding: 0 24px;
    display: block;
    font-size: 0.889em;
    line-height: 2.3em; }
    .link-list-wrapper ul li a:hover:not(.disabled) {
      text-decoration: none; }
      .link-list-wrapper ul li a:hover:not(.disabled) span {
        color: #03a87c;
        text-decoration: underline; }
      .link-list-wrapper ul li a:hover:not(.disabled) p {
        color: #03a87c;
        text-decoration: none; }
    .link-list-wrapper ul li a.disabled:hover {
      text-decoration: none; }
    .link-list-wrapper ul li a.medium {
      font-weight: 600; }
    .link-list-wrapper ul li a span {
      margin-right: 24px;
      display: block;
      color: #03a87c; }
    .link-list-wrapper ul li a.right-icon i.right {
      position: absolute;
      top: 0;
      right: 24px;
      transition: transform 0.3s; }
      .link-list-wrapper ul li a.right-icon i.right.secondary {
        color: #9da2a6; }
    .link-list-wrapper ul li a.right-icon[aria-expanded='true'] i.right {
      transform: scaleY(-1); }
    .link-list-wrapper ul li a.active span {
      color: #1d4735;
      text-decoration: underline; }
    .link-list-wrapper ul li a.disabled {
      cursor: not-allowed; }
      .link-list-wrapper ul li a.disabled span {
        color: #b1b3b2; }
      .link-list-wrapper ul li a.disabled.right-icon i, .link-list-wrapper ul li a.disabled.left-icon i {
        color: #b1b3b2; }
        .link-list-wrapper ul li a.disabled.right-icon i.secondary, .link-list-wrapper ul li a.disabled.left-icon i.secondary {
          color: #b1b3b2; }
    .link-list-wrapper ul li a.left-icon {
      display: flex; }
      .link-list-wrapper ul li a.left-icon i.left {
        margin-right: 8px; }
    .link-list-wrapper ul li a.large {
      font-size: 1em; }
    .link-list-wrapper ul li a p {
      font-size: 0.778em;
      color: #647d72;
      line-height: initial; }
    .link-list-wrapper ul li a.avatar {
      margin-bottom: 8px;
      display: flex; }
      .link-list-wrapper ul li a.avatar .avatar {
        margin-right: 8px;
        display: block;
        border-radius: 50px; }
  .link-list-wrapper ul li h3 {
    padding: 0 24px;
    font-size: 1em;
    color: #1d4735; }
    .link-list-wrapper ul li h3 a {
      padding: 0;
      font-size: 1em;
      line-height: inherit; }
  .link-list-wrapper ul .divider {
    margin: 8px 0;
    display: block;
    height: 1px;
    background: #e6e8e7; }
  .link-list-wrapper ul .toggles label {
    margin: 0;
    padding: 0 24px;
    justify-content: space-between;
    height: auto;
    font-size: 0.889em;
    font-weight: inherit;
    line-height: 2.3em; }
  .link-list-wrapper ul .toggles input[type=checkbox] + .lever {
    margin: 8px 0 0; }
  .link-list-wrapper ul .form-check.form-check-group {
    padding: 0 24px;
    box-shadow: none; }
    .link-list-wrapper ul .form-check.form-check-group [type=checkbox] + label {
      margin-bottom: 0;
      padding-left: 0;
      height: inherit;
      font-size: 0.889em;
      line-height: 2.3em; }
      .link-list-wrapper ul .form-check.form-check-group [type=checkbox] + label::after {
        right: 24px; }
      .link-list-wrapper ul .form-check.form-check-group [type=checkbox] + label::before {
        right: 35px; }
    .link-list-wrapper ul .form-check.form-check-group [type=checkbox][disabled] + label {
      color: #b1b3b2; }

@media (min-width: 576px) {
  .link-list-wrapper ul li a.large {
    font-size: 1em;
    line-height: 2.7em; } }

@media (min-width: 768px) {
  .link-list-wrapper ul li a.large {
    line-height: 2.7em; } }

@keyframes megamenuFadeIn {
  0% {
    margin-top: 16px;
    opacity: 0; }
  100% {
    margin-top: 16px;
    opacity: 1; } }

.header-social {
  font-size: 0.8em; }

.header .list-inline {
  margin-top: 0.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  text-align: center; }
  .header .list-inline .miur {
    margin-left: 1.85em;
    font-size: 0.83em; }
    .header .list-inline .miur a {
      display: block;
      cursor: pointer;
      color: #32465c;
      transform: scale(1);
      transition: all 200ms ease-in; }
      .header .list-inline .miur a:hover {
        box-shadow: 0 0 11px #eaf0f6;
        transform: scale(1.05);
        transition: all 200ms ease-in; }
  .header .list-inline .dircard {
    margin-left: 1.85em; }
    .header .list-inline .dircard a {
      display: block;
      cursor: pointer;
      transform: scale(1);
      transition: all 200ms ease-in; }
      .header .list-inline .dircard a:hover {
        box-shadow: 0 0 11px #eaf0f6;
        transform: scale(1.05);
        transition: all 200ms ease-in; }
    .header .list-inline .dircard img {
      height: auto;
      max-width: 200px; }

.carta-docenti {
  font-size: 0.85em;
  color: #32465c;
  text-align: left; }
  .carta-docenti a {
    padding: 0.55em 2em;
    display: block;
    border-radius: 25px;
    cursor: pointer;
    color: white;
    background: #06c;
    transform: scale(1);
    transition: all 200ms ease-in; }
    .carta-docenti a:hover {
      box-shadow: 0 0 11px #eaf0f6;
      transform: scale(1.05);
      transition: all 200ms ease-in; }
  .carta-docenti img {
    padding-left: 10px;
    height: auto;
    max-width: 100px; }

.logo-container {
  align-items: center !important;
  align-self: center !important; }

.navbar {
  z-index: 1;
  color: #55789e;
  background-color: white !important; }
  .navbar .navbar-toggler {
    font-size: 0.7em; }
  .navbar #navbarNavB {
    z-index: 10;
    padding: 0;
    /*lista menu*/ }
    .navbar #navbarNavB .menu-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 24px;
      pointer-events: all;
      font-size: 0.7em;
      font-weight: 700;
      text-transform: uppercase;
      background-color: white !important;
      overflow-y: auto; }
    .navbar #navbarNavB .navbar-nav {
      padding: 0 !important;
      flex-wrap: wrap; }
      .navbar #navbarNavB .navbar-nav li a.nav-link {
        position: relative;
        padding: 1em !important;
        font-size: 0.735rem;
        font-weight: 400;
        color: #55789e;
        line-height: 30px;
        text-transform: uppercase; }
        .navbar #navbarNavB .navbar-nav li a.nav-link.active {
          color: #32465c;
          border-left: 4px solid green; }
    .navbar #navbarNavB.expanded .menu-wrapper {
      transform: translateX(0); }
    .navbar #navbarNavB.expanded .close-div {
      transform: translateX(0); }
  .navbar .close-div {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    left: 48px;
    padding: 24px;
    background-color: white !important;
    transform: translateX(100%);
    transition: all 0.3s; }
    .navbar .close-div .close-menu {
      padding: 0;
      font-size: 0.75em;
      color: #32465c !important;
      color: white;
      text-align: center;
      text-transform: uppercase;
      background: transparent; }
      .navbar .close-div .close-menu span {
        display: block;
        font-size: 2em;
        text-align: center; }
        .navbar .close-div .close-menu span::before {
          margin-right: 0; }
  .navbar .dropdown-menu {
    box-shadow: none;
    background: transparent; }
    .navbar .dropdown-menu::before {
      display: none; }
    .navbar .dropdown-menu .link-list-wrapper {
      padding-right: 24px;
      padding-left: 24px; }
      .navbar .dropdown-menu .link-list-wrapper ul li a span {
        color: white; }
      .navbar .dropdown-menu .link-list-wrapper ul li a h3 {
        color: white; }
      .navbar .dropdown-menu .link-list-wrapper ul li h3 {
        color: white; }
  .navbar .dropdown-toggle {
    display: flex;
    justify-content: space-between; }
    .navbar .dropdown-toggle::after {
      content: '\e818';
      margin-right: 0.2em;
      display: inline-block;
      width: auto;
      height: auto;
      font-family: 'italia-icon-font';
      font-size: 0.8em;
      font-weight: normal;
      font-style: normal;
      line-height: 1em;
      line-height: 2em;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
      vertical-align: auto;
      transform-origin: center;
      transition: all 0.3s;
      speak: none;
      font-variant: normal;
      border-top: none;
      border-right: none;
      border-bottom: 0;
      border-left: none; }
    .navbar .dropdown-toggle[aria-expanded='true']::after {
      transform: scaleY(-1); }

.custom-navbar-toggler {
  border: none;
  color: #03a87c;
  background: none; }
  .custom-navbar-toggler span {
    font-size: 1.625em;
    color: #03a87c; }

.inline-menu .link-list-wrapper .link-list {
  position: relative; }
  .inline-menu .link-list-wrapper .link-list li a {
    position: relative; }
    .inline-menu .link-list-wrapper .link-list li a i {
      color: #6eebc9; }
    .inline-menu .link-list-wrapper .link-list li a::after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 2px;
      height: 100%;
      background: #03a87c;
      transform: scaleY(0);
      transform-origin: center;
      transition: all 0.3s; }
    .inline-menu .link-list-wrapper .link-list li a.active span {
      color: #03a87c;
      text-decoration: none; }
    .inline-menu .link-list-wrapper .link-list li a[aria-expanded='true']::after {
      transform: scaleY(1); }
  .inline-menu .link-list-wrapper .link-list::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 2px;
    height: 100%;
    background: linear-gradient(to bottom, #e6ecf2 0%, rgba(230, 236, 242, 0.3) 100%); }

@media (min-width: 992px) {
  .navbar {
    padding: 0;
    background: #03a87c; }
    .navbar .navbar-collapsable {
      z-index: auto;
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      display: block !important;
      background: none;
      transition: none;
      /*lista menu*/ }
      .navbar .navbar-collapsable .menu-wrapper {
        position: inherit;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        transform: none;
        transition: none;
        overflow-y: visible; }
      .navbar .navbar-collapsable .navbar-nav {
        margin-top: 0;
        padding: 0 24px; }
        .navbar .navbar-collapsable .navbar-nav li a.nav-link {
          font-weight: 400;
          text-transform: uppercase; }
          .navbar .navbar-collapsable .navbar-nav li a.nav-link::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0 auto;
            width: 0;
            height: 3px;
            background-color: transparent;
            transform: translate3d(0, 0, 0);
            transition: width 0.2s ease; }
          .navbar .navbar-collapsable .navbar-nav li a.nav-link:hover::after {
            width: 100%;
            background-color: #03a87c; }
          .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
            border-left: 0; }
          .navbar .navbar-collapsable .navbar-nav li a.nav-link.disabled {
            opacity: 0.5;
            cursor: not-allowed; }
        .navbar .navbar-collapsable .navbar-nav li.active a::after {
          width: 100%;
          background-color: #03a87c; }
    .navbar .close-div {
      display: none; }
    .navbar .dropdown-menu {
      border-radius: 4px;
      box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
      z-index: 1000;
      float: left;
      position: absolute;
      top: 100%;
      left: 0;
      margin: 0.125rem 0 0;
      padding: 0.5rem 0;
      list-style: none;
      border: 0 solid transparent;
      font-size: 1rem;
      color: #19191a;
      text-align: left;
      background-color: white;
      min-width: 10rem;
      background-clip: padding-box; }
      .navbar .dropdown-menu.show {
        top: calc(100% - 16px);
        animation: dropdownFadeIn forwards 0.3s; }
      .navbar .dropdown-menu::before {
        display: block; }
      .navbar .dropdown-menu .link-list-wrapper {
        padding-right: 0;
        padding-left: 0; }
        .navbar .dropdown-menu .link-list-wrapper ul li a span {
          color: #03a87c; }
        .navbar .dropdown-menu .link-list-wrapper ul li a h3 {
          color: #03a87c; }
        .navbar .dropdown-menu .link-list-wrapper ul li h3 {
          color: #19191a; }
    .navbar .dropdown-toggle {
      display: block;
      justify-content: inherit; }
      .navbar .dropdown-toggle::after {
        content: "";
        display: inline-block;
        width: auto;
        height: auto;
        border: none;
        font-family: 'italia-icon-font';
        font-size: 0.8rem;
        font-weight: normal;
        font-style: normal;
        vertical-align: 0;
        transition: transform 0.3s;
        speak: none; }
    .navbar.megamenu .navbar-collapsable {
      width: 100%; }
      .navbar.megamenu .navbar-collapsable .nav-item {
        position: static; }
        .navbar.megamenu .navbar-collapsable .nav-item a {
          position: relative; }
          .navbar.megamenu .navbar-collapsable .nav-item a::before {
            content: '';
            position: absolute;
            top: auto;
            bottom: -12px;
            left: 24px;
            width: 18px;
            height: 18px;
            border-radius: 4px;
            opacity: 0;
            background-color: white;
            transform: rotate(45deg);
            transition: opacity 0.3s; }
        .navbar.megamenu .navbar-collapsable .nav-item.show a::before {
          opacity: 1; }
      .navbar.megamenu .navbar-collapsable .dropdown-menu {
        right: 24px;
        left: 24px;
        min-width: auto; }
        .navbar.megamenu .navbar-collapsable .dropdown-menu::before {
          display: none; }
        .navbar.megamenu .navbar-collapsable .dropdown-menu.show {
          animation: megamenuFadeIn forwards 0.3s; }
  .custom-navbar-toggler {
    display: none; } }

nav.pagination.tecnodid-nextprevious ul {
  margin-top: 1em;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%; }
  nav.pagination.tecnodid-nextprevious ul li {
    display: inline-block;
    flex: 1; }
    nav.pagination.tecnodid-nextprevious ul li.previous a::before {
      content: '\00ab'; }
    nav.pagination.tecnodid-nextprevious ul li.next {
      text-align: right; }
    nav.pagination.tecnodid-nextprevious ul li.next a::after {
      content: '\00bb'; }

.collapse.in {
  display: block; }

.skiplinks {
  text-align: center;
  background-color: #0bd9d2; }
  .skiplinks a {
    padding: 0.5rem 0.5rem;
    display: inline-block;
    display: block;
    font-weight: 600;
    color: #647d72;
    text-decoration: underline; }

.news {
  margin: 5vh 0;
  padding: 0 0 1em; }
  .news .date {
    font-size: 0.75em;
    font-weight: 500; }
  .news .description {
    font-size: 0.75em;
    font-weight: 300; }
  .news .tileBody {
    line-height: 1.2; }

.tileItem {
  margin-bottom: 0.5em;
  padding: 1em 0; }

.tileHeadline {
  margin-bottom: 0.35em;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.3px; }
  .tileHeadline a {
    font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
    font-weight: 600;
    color: #32465c; }

.dizionario-normativo {
  position: relative;
  font-size: 0.75em;
  color: white;
  background: url("/assets/img/ribbon.png") repeat scroll 0 0 #03a87c;
  min-height: 400px;
  max-height: 400px; }
  .dizionario-normativo-text {
    position: absolute;
    bottom: 0;
    padding: 1em;
    width: 100%; }
  .dizionario-normativo a {
    color: white; }

.ribbon {
  position: absolute;
  top: 0;
  right: 50px;
  width: 20px;
  height: 150px;
  background-color: #fabc50; }
  .ribbon::before {
    content: '';
    z-index: 2;
    position: absolute;
    bottom: -10px;
    left: 0;
    border-left: 10px solid #fabc50;
    border-right: 10px solid #fabc50;
    border-bottom: 10px solid transparent; }

.dizionario-results {
  margin: 2em 0 1.5em; }
  .dizionario-results h5 {
    font-size: 1.5em;
    font-weight: 600; }
  .dizionario-results ul {
    max-height: 300px;
    overflow-y: auto; }
    .dizionario-results ul li a {
      display: block;
      width: 100%; }
    .dizionario-results ul a:hover a,
    .dizionario-results ul li.active a {
      padding-left: 10px;
      border-radius: 3px;
      color: white;
      background: #009963; }

.dizionario-norma {
  position: relative;
  margin: 0 0 1em;
  padding: 2em;
  border-radius: 5px;
  background: white; }
  .dizionario-norma.text-close::after {
    content: '';
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    min-height: 500px;
    background-image: -moz-linear-gradient(top, rgba(245, 248, 250, 0) 0%, #f5f8fa 65%, #f5f8fa 100%);
    background-image: -webkit-linear-gradient(top, rgba(245, 248, 250, 0) 0%, #f5f8fa 65%, #f5f8fa 100%);
    background-image: linear-gradient(to bottom, rgba(245, 248, 250, 0) 0%, #f5f8fa 65%, #f5f8fa 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f8fa', endColorstr='#f5f8fa',GradientType=0 ); }

.dizionario .register {
  z-index: 2;
  position: relative;
  margin: -150px auto 0;
  padding: 2em 1em;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  border: 1px solid #34598166;
  border-radius: 8px;
  background-color: transparent; }

.badge-secondary {
  background-color: #03a87c; }

.calendar {
  margin: 5vh 0;
  width: 100%; }
  .calendar p {
    font-size: 0.7rem;
    text-transform: uppercase; }
  .calendar h6 {
    font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
    font-weight: 600; }
    .calendar h6 a {
      color: #32465c; }
  .calendar .list-inline-item {
    font-size: 0.85rem; }
  .calendar.scadenze .badge-secondary {
    background-color: #d44a48; }

.row-striped:nth-of-type(odd) {
  background-color: #f3f3f3;
  border-left: 4px #32465c solid; }

.row-striped:nth-of-type(even) {
  background-color: white;
  border-left: 4px #efefef solid; }

.row-striped {
  padding: 15px 0; }

.box-inserimenti {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%; }
  .box-inserimenti .tileItem {
    margin-bottom: 0.5em;
    padding: 1em;
    width: 33%; }

.card {
  margin-right: 1vw;
  margin-right: 1vw;
  margin-bottom: 0;
  box-shadow: 0 0 11px #eaf0f6;
  border: none;
  border-radius: 5px;
  transition: 0.3s ease-in-out; }
  .card:hover {
    box-shadow: 0 10px 50px -5px rgba(51, 71, 91, 0.12);
    transform: scale(1.08); }
  .card .it-chevron-right {
    padding-left: 5px;
    font-size: 0.8em; }
  .card-body {
    padding: 1rem 2rem; }
  .card-img-top {
    border-radius: 5px 5px 0 0; }

.services {
  padding: 7vh 0; }
  .services .card-title {
    position: absolute;
    margin: 2em 1em 1em 0;
    padding-right: 30px;
    padding-left: 0.5em;
    display: inline-block;
    width: auto;
    height: 30px;
    font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
    font-size: 1rem;
    color: white;
    line-height: 30px;
    background: #d44a48; }
    .services .card-title::after {
      content: '';
      position: absolute;
      right: -30px;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #d44a48;
      border-right: 30px solid transparent; }

.in-catalogo {
  text-align: center; }
  .in-catalogo .card {
    position: relative;
    padding: 0;
    align-items: center;
    overflow: hidden;
    border-radius: 5px 5px 0 0; }
    .in-catalogo .card img {
      z-index: 0;
      width: 100%;
      height: auto; }
  .in-catalogo .card-title {
    z-index: 3;
    position: absolute;
    bottom: -18px;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    /* stylelint-disable */
    background-image: -webkit-linear-gradient(bottom, rgba(36, 44, 53, 0.75) 23%, rgba(46, 54, 64, 0.3) 80%, rgba(0, 0, 0, 0) 88%, rgba(0, 0, 0, 0) 100%);
    background-image: -moz-linear-gradient(bottom, rgba(36, 44, 53, 0.75) 23%, rgba(46, 54, 64, 0.3) 80%, rgba(0, 0, 0, 0) 88%, rgba(0, 0, 0, 0) 100%);
    background-image: linear-gradient(to top, rgba(36, 44, 53, 0.75) 23%, rgba(46, 54, 64, 0.3) 80%, rgba(0, 0, 0, 0) 88%, rgba(0, 0, 0, 0) 100%);
    min-height: 100px; }
    .in-catalogo .card-title a {
      width: 100%;
      color: #fff;
      text-align: center;
      line-height: 1.5; }

.second-column .card {
  margin: 1.5rem 0; }
  .second-column .card-title {
    padding: 0.5rem 1rem 0; }
  .second-column .card-body {
    padding: 1rem 1rem 0; }

#corsi {
  margin: 2em 0; }

@media (min-width: 576px) {
  .card-group > .card:not(:first-child),
  .card-group > .card:not(:last-child) {
    border-radius: 5px; } }

.green-card {
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: #cfe6df;
  padding: 1.5em; }

.grey-card {
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: #b5c5d7;
  padding: 1.5em; }

.white-card {
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: white;
  padding: 1.5em; }

.simple-page {
  font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
  font-weight: 300; }
  .simple-page .first-column {
    padding: 3em 2em;
    background-color: white; }
    .simple-page .first-column .documentFirstHeading {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.5; }
    .simple-page .first-column .related-contents {
      margin-top: 5vh; }
      .simple-page .first-column .related-contents .documentFirstHeading {
        font-size: 1em; }
    .simple-page .first-column h2,
    .simple-page .first-column h3,
    .simple-page .first-column h4,
    .simple-page .first-column h5 {
      font-family: "Rubik", Geneva, Tahoma, sans-serif;
      font-weight: 500;
      line-height: 1.55; }
    .simple-page .first-column .documentDescription {
      margin-bottom: 1em;
      font-size: 1.15rem;
      font-weight: 400;
      line-height: 1.55; }
    .simple-page .first-column #parent-fieldname-text {
      font-size: 1.05rem;
      line-height: 1.7; }
    .simple-page .first-column .documentByLine {
      font-family: "Rubik", Geneva, Tahoma, sans-serif;
      font-size: 12px;
      color: #55789e;
      letter-spacing: 1px; }
  .simple-page .second-column {
    padding: 2em;
    line-height: 1.35; }
    .simple-page .second-column .documentFirstHeading {
      margin-bottom: 1em;
      font-family: "Rubik", Geneva, Tahoma, sans-serif;
      font-size: 0.75rem;
      font-weight: 500;
      color: #b5c6d7;
      letter-spacing: 1px;
      line-height: 1.35;
      text-transform: uppercase; }
      .simple-page .second-column .documentFirstHeading a {
        color: #b5c6d7; }
        .simple-page .second-column .documentFirstHeading a::after {
          display: none; }
    .simple-page .second-column h2,
    .simple-page .second-column h3,
    .simple-page .second-column h4,
    .simple-page .second-column h5 {
      font-family: "Rubik", Geneva, Tahoma, sans-serif;
      font-weight: 500; }

.listing-card {
  padding: 1em 0.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-weight: 300;
  border-bottom: 3px solid #d6dfe9; }
  .listing-card p {
    margin-bottom: 5px; }
  .listing-card .author {
    padding: 7px 0;
    font-size: 0.75rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1.15; }
  .listing-card .date {
    margin: 7px 0;
    font-size: 0.65rem;
    font-weight: 300;
    color: #a5b9ce; }
  .listing-card a {
    margin: 5px 0;
    font-weight: 500;
    color: #32465c; }
  .listing-card .copertina {
    padding-bottom: 10px;
    max-width: 150px;
    min-width: 120px; }

.section-legislazione-e-dottrina .books,
.home .books,
.legislazione .books {
  width: 100%;
  height: auto; }
  .section-legislazione-e-dottrina .books .read-more,
  .home .books .read-more,
  .legislazione .books .read-more {
    display: none; }

.section-legislazione-e-dottrina .listing,
.home .listing,
.legislazione .listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%; }
  .section-legislazione-e-dottrina .listing-card,
  .home .listing-card,
  .legislazione .listing-card {
    align-self: flex-start;
    width: 150px;
    line-height: 1.25;
    border-bottom: none; }
    .section-legislazione-e-dottrina .listing-card .copertina,
    .home .listing-card .copertina,
    .legislazione .listing-card .copertina {
      height: 180px; }
    .section-legislazione-e-dottrina .listing-card .info,
    .home .listing-card .info,
    .legislazione .listing-card .info {
      min-width: 150px; }
      .section-legislazione-e-dottrina .listing-card .info a,
      .home .listing-card .info a,
      .legislazione .listing-card .info a {
        margin: 5px 0; }

.read-more {
  margin: 1.5em 0;
  display: block;
  font-family: "Rubik", Geneva, Tahoma, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: #b5c6d7;
  letter-spacing: 1px;
  line-height: 1.5;
  text-align: right; }
  .read-more:hover {
    text-decoration: none; }
  .read-more::after {
    content: '\f061';
    padding-left: 10px;
    display: inline-block;
    font-family: 'Fontawesome';
    font-size: 0.8em; }

.books {
  margin-bottom: 1em;
  padding: 1em;
  width: 100%;
  border: 3px solid #d6dfe9;
  font-family: "Rubik", Geneva, Tahoma, sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  color: #32465c; }
  .books.riviste {
    width: 100%;
    background-color: #f7f9fb; }
    .books.riviste .documentFirstHeading {
      padding-left: 1em; }
  .books .listing.horizontal {
    display: flex; }

.navbar.navbar-institutional {
  padding: 0 1.2rem;
  padding: 0 1.2rem;
  box-shadow: none;
  background-color: #32465c;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#32465c), to(#3673c3)) !important;
  background-image: linear-gradient(#32465c, #3673c3) !important;
  background-image: -webkit-gradient(linear, left top, right top, from(#32465c), to(#3673c3)) !important;
  background-image: linear-gradient(to right, #32465c, #3673c3) !important; }
  .navbar.navbar-institutional .navbar-brand {
    font-size: 12px;
    font-weight: 300; }
  .navbar.navbar-institutional .navbar-nav .nav-link {
    padding: 0 0.75rem;
    font-size: 12px;
    font-weight: 300;
    color: white; }
  .navbar.navbar-institutional .navbar-nav .nav-item {
    padding: 0.5rem 0; }
    @media (min-width: 768px) {
      .navbar.navbar-institutional .navbar-nav .nav-item.nav-item-w-divider .nav-link {
        border-right: 1px solid rgba(255, 255, 255, 0.5); } }
    .navbar.navbar-institutional .navbar-nav .nav-item:hover  {
      box-shadow: 0 0.25rem 0 0 white; }

.footer {
  padding-top: 1em;
  font-size: 0.75em;
  color: #a2b2c3;
  background: #32465c;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#32465c), to(#345981)) !important;
  background-image: linear-gradient(#32465c, #345981) !important;
  background-image: -webkit-gradient(linear, left top, right top, from(#32465c), to(#345981)) !important;
  background-image: linear-gradient(to right, #32465c, #345981) !important; }
  .footer h5 {
    color: white; }
  .footer h6 {
    margin-bottom: 10px !important;
    color: white;
    letter-spacing: 1px; }
  .footer .title {
    font-size: 1.1em;
    font-weight: 500; }
  .footer p.colophon {
    text-align: right; }
  .footer .list-group-item {
    padding: 0.45em 0 0.45em 1.45em;
    border: none;
    background-color: transparent; }
    .footer .list-group-item a {
      border: none;
      opacity: 0.75;
      color: #cbd6e2; }
      .footer .list-group-item a:hover {
        opacity: 1; }
  .footer a {
    color: white; }
  .footer .col.my-3 {
    padding: 0 3em 0 0; }
  .footer .list-inline {
    margin-top: 1em; }
    .footer .list-inline.info {
      text-align: right; }
    .footer .list-inline-item::after {
      content: '/';
      padding-left: 0.5em;
      width: 100%;
      height: 100%;
      color: #03a87c; }
    .footer .list-inline-item:last-child {
      margin-top: 1em; }
      .footer .list-inline-item:last-child::after {
        display: none; }

.it-chevron-right {
  color: #03a87c; }

.bd-footer {
  color: white; }
  @media (min-width: 576px) {
    .bd-footer {
      text-align: left; } }
  .bd-footer .footer-social {
    font-size: 0; }
    .bd-footer .footer-social [class^='it-'] {
      font-size: initial; }
  .bd-footer .team-logo--icon {
    width: 72px; }
  .bd-footer .team-logo--text {
    font-size: 16px;
    line-height: 1rem;
    max-width: 160px;
    text-transform: uppercase; }
  .bd-footer .small-prints {
    font-weight: 600;
    color: #00fff8; }

.navbar {
  padding: 0; }
  .navbar .nav-item.active {
    background-color: #03a87c; }
    .navbar .nav-item.active a {
      color: white !important; }

.border-portlet {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border: 3px solid #fff; }
  .border-portlet .documentFirstHeading a::after {
    display: none; }
  .border-portlet .column-title {
    margin-right: 3em; }
  .border-portlet h2 {
    min-width: 220px; }
  .border-portlet .column-link a {
    font-weight: 500; }
  .border-portlet .column-icon {
    margin-right: 1.5em; }
    .border-portlet .column-icon i {
      font-size: 3.5rem;
      color: #acd8cd; }
  .border-portlet .column-link p {
    margin: 0; }
    .border-portlet .column-link p.date {
      font-size: 12px; }

#edit-zone .plone-toolbar-main li.plonetoolbar-portlet-manager a[id^='portlet-manager-ContentWellPortlets'] {
  display: none; }

#search-norme {
  margin-bottom: 2em;
  padding: 15px;
  border-radius: 5px;
  font-size: 12px;
  background-color: #d6dfe9; }
  #search-norme .nav-tabs {
    margin-bottom: 0.5em;
    padding: 0;
    background-color: #d6dfe9; }
    #search-norme .nav-tabs .nav-item {
      margin-right: 5px; }
  #search-norme .nav-link {
    padding: 5px;
    color: #32465c; }
  #search-norme h4 {
    font-size: 20px; }
  #search-norme .form-group {
    padding-top: 0; }

.listing-items .listing-item {
  margin: 1em 0;
  border-bottom: 2px solid #d6dfe9; }

.listing-items .titles {
  font-family: "Rubik", Geneva, Tahoma, sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase; }

.listing-items .item {
  margin-bottom: 0.5em;
  padding: 0.5em 0 0; }
  .listing-items .item a {
    font-weight: 600; }
  .listing-items .item p {
    font-size: 0.85em; }

.tecnodid-registration {
  padding: 20px;
  border-radius: 25px;
  background-color: #f5f8fa; }
  .tecnodid-registration-head {
    text-align: center; }
    .tecnodid-registration-head i {
      font-size: 64px;
      color: #cbd6e2; }
  .tecnodid-registration .widget {
    width: 100%; }
  .tecnodid-registration label,
  .tecnodid-registration .label {
    width: 100%;
    font-weight: 400 !important;
    color: black; }
  .tecnodid-registration input {
    margin-bottom: 20px;
    padding-left: 15px;
    width: 100%;
    border: solid 1px gray;
    min-height: 40px;
    border-radius: 10px; }
  .tecnodid-registration .single-checkbox-bool-widget {
    min-height: 20px;
    width: 20px !important; }
  .tecnodid-registration .tecnodid-form > p {
    display: none; }
  .tecnodid-registration .formControls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between; }
  .tecnodid-registration #form-buttons-completa {
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 18px;
    color: white;
    line-height: 1.3333333;
    background-color: #03a87c;
    border-color: #03a87c; }
  .tecnodid-registration #form-buttons-annulla {
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 18px;
    color: white;
    line-height: 1.3333333;
    background-color: #88a6d4;
    border-color: #88a6d4; }
  .tecnodid-registration .error {
    margin-top: -20px;
    color: red; }

.componente-base {
  padding: 8px 8px;
  cursor: pointer;
  color: white;
  background-color: #12b961; }

.focus--keyboard {
  border-color: #e35452;
  box-shadow: 0 0 6px 2px #e35452;
  outline: none; }

.focus--mouse {
  border-color: inherit;
  box-shadow: none;
  outline: none; }

#top {
  z-index: 4;
  position: sticky;
  position: -webkit-ms-sticky;
  position: -webkit-o-sticky;
  position: -webkit-sticky;
  top: 0;
  height: 60px; }
  #top .left {
    background-color: #d6dfe9;
    border-bottom: solid 1px grey; }
    #top .left > div {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px; }
      #top .left > div .title {
        font-size: larger;
        font-weight: 500;
        color: #03a87c;
        text-transform: capitalize; }
  #top .rigth {
    background-color: #e3e7f3;
    border-bottom: solid 1px grey; }
    #top .rigth > div {
      padding-right: 10%;
      display: flex;
      justify-content: flex-end;
      font-weight: 400;
      color: #03a87c; }
      #top .rigth > div > span {
        margin-left: 20px;
        line-height: 60px; }

.safari #top {
  position: relative; }

.safari .left.menu {
  position: relative;
  top: 0;
  height: 100%; }

.safari #bottom {
  margin-bottom: 1em; }

#bottom .left .box {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 60px; }

#bottom .left .tab-pane ul li {
  border-bottom: none; }

#wishlist,
#comment {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 5px;
  font-size: 18px;
  background-color: #d6dfe9; }
  #wishlist > .title,
  #comment > .title {
    font-weight: 500;
    text-transform: capitalize; }
    #wishlist > .title + i,
    #comment > .title + i {
      margin-right: 0;
      padding-right: 0;
      font-weight: 600;
      min-width: 7vw;
      text-align: right; }

#ipertesto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px; }
  #ipertesto > .title {
    font-weight: 500;
    text-transform: capitalize; }
  #ipertesto .fa {
    margin-right: 0;
    padding-right: 0;
    min-width: 7vw;
    text-align: right; }

#search-norme {
  margin-bottom: 0;
  padding: 20px; }
  #search-norme .tab-container {
    margin: 20px 0 0; }
  #search-norme .tab-pane {
    padding: 1em 5px; }
  #search-norme.contract {
    height: 60px;
    overflow: hidden; }

.closed #bottom .left .box {
  height: 80px;
  overflow: hidden;
  box-sizing: border-box; }
  .closed #bottom .left .box .title {
    display: none; }

.closed #ipertesto {
  padding-bottom: 34px; }
  .closed #ipertesto .fa.search {
    padding-left: 4px;
    line-height: 33px; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show), .fade:not(.in) {
    opacity: 0; }
  .fade:not(.show).in, .fade:not(.in).show {
    opacity: 1; }

#dashboard {
  opacity: 1;
  /*formattazione per le norme*/
  /*tabelle norme*/ }
  #dashboard #top .title-responsive {
    display: none; }
  #dashboard.open #top .left {
    flex: 0 0 20%;
    max-width: 20%; }
  #dashboard.open #top .rigth {
    flex: 0 0 80%;
    max-width: 80%; }
  #dashboard.open #bottom .left {
    flex: 0 0 20%;
    max-width: 20%; }
  #dashboard.open #bottom .rigth {
    flex: 0 0 70%;
    max-width: 70%; }
  #dashboard.closed #top .left {
    flex: 0 0 10%;
    max-width: 10%; }
  #dashboard.closed #top .rigth {
    flex: 0 0 90%;
    max-width: 90%; }
  #dashboard.closed #bottom .left {
    flex: 0 0 10%;
    max-width: 10%; }
  #dashboard.closed #bottom .rigth {
    flex: 0 0 80%;
    max-width: 80%; }
  #dashboard .fa,
  #dashboard .fas,
  #dashboard .far {
    cursor: pointer; }
  #dashboard #top .left .fas {
    font-size: 1.5rem; }
  #dashboard .text {
    min-height: 576px;
    position: relative;
    margin: 20px;
    padding: 40px;
    background-color: white; }
  #dashboard .locked::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white); }
  #dashboard .locked-popup-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 12vh;
    background: white;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #f5f8fa 44%); }
    #dashboard .locked-popup-container > div {
      margin: 20px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: solid 1px #b0c0d0;
      border-radius: 8px;
      background: white;
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #f5f8fa 54%); }
      #dashboard .locked-popup-container > div p {
        font-weight: 500; }
  #dashboard .evidenzia {
    font-weight: bold; }
  #dashboard .strutture {
    margin-top: 25px;
    font-weight: bold;
    text-align: center; }
  #dashboard .articolo {
    margin-top: 25px;
    text-indent: 0 !important; }
  #dashboard .section-legislazione-e-dottrina #content-core p {
    text-align: justify;
    text-indent: 15px; }
  #dashboard .section-legislazione-e-dottrina .documentDescription {
    font-weight: bold; }
  #dashboard .select-normative {
    max-width: 19vw;
    font-size: 10px; }
    #dashboard .select-normative option {
      max-width: 50vw; }
  #dashboard table.tab1 {
    border-collapse: collapse !important;
    border: 1px solid grey !important;
    background-color: #ffffff !important; }
  #dashboard table.tab1 td {
    padding: 4px !important;
    border: 1px dotted darkgray !important; }
  #dashboard table.tab1 th {
    padding: 5px !important;
    border: 1px solid gray !important;
    font-weight: bold !important;
    background-color: #eeeeff !important; }
  #dashboard table.tab1 tr:hover {
    color: #111 !important;
    background: #eaecee !important; }
  #dashboard table.tab1 p {
    margin: 0 !important; }
  #dashboard table.tab2 {
    border-collapse: collapse !important;
    margin-bottom: 20px;
    border: 1px solid grey !important;
    font-size: 8pt;
    background-color: #ffffff !important; }
  #dashboard table.tab2 td {
    padding: 1px !important;
    border: 1px dotted darkgray !important; }
  #dashboard table.tab2 th {
    padding: 2px !important;
    border: 1px solid gray !important;
    font-weight: bold !important;
    background-color: #eeeeff !important; }
  #dashboard table.tab2 tr:hover {
    color: #111 !important;
    background: #eaecee !important; }
  #dashboard table.tab2 p {
    margin: 0 !important; }
  #dashboard a.linknormativa {
    border-bottom: 1px dotted navy;
    background: #f5fffa; }
  #dashboard a.linknormativa:hover {
    color: black;
    background-color: yellow !important; }
  #dashboard .rifrep {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 11px;
    text-indent: 0;
    background-color: #fff;
    border-left: 1px solid #ffa500;
    border-bottom: 5px solid #ffa500; }
  #dashboard .rif_nota {
    font-size: 70%;
    vertical-align: super; }
  #dashboard .notandr {
    font-size: 80%; }
  #dashboard .note {
    border-top: 1px solid gray;
    padding-top: 10px;
    font-size: 80%; }
  #dashboard .note p {
    margin-top: 0;
    margin-bottom: 0; }
  #dashboard .nota {
    margin-top: 10px; }
  #dashboard .corpo_abrogato {
    border-left: 2px solid red;
    padding-left: 10px; }

/* .section-legislazione-e-dottrina main, */
.legislazione main {
  z-index: 3;
  position: sticky;
  position: -webkit-ms-sticky;
  position: -webkit-o-sticky;
  position: -webkit-sticky; }
  .legislazione main main {
    margin-bottom: 150px; }
  .legislazione main .documentFirstHeading {
    padding-top: 20px; }

.left.menu {
  z-index: 3;
  position: sticky;
  top: 60px;
  padding: 0 6px 20px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box; }
  .left.menu::-webkit-scrollbar {
    display: none; }
  .left.menu #articolo-per-estremi-selected,
  .left.menu .search-results {
    padding: 0;
    list-style: none; }
    .left.menu #articolo-per-estremi-selected > li,
    .left.menu .search-results > li {
      border-top: solid 1px gray;
      padding: 10px 0;
      font-size: 16px;
      line-height: 125%; }
    .left.menu #articolo-per-estremi-selected a.active,
    .left.menu .search-results a.active {
      padding-left: 5px;
      display: block;
      width: 100%;
      border-radius: 5px;
      color: white;
      background-color: #03a87c; }
  .left.menu .nav-tabs .nav-item.show .nav-link,
  .left.menu .nav-tabs .nav-link.active,
  .left.menu .nav-tabs .nav-item.in .nav-link,
  .left.menu .nav-tabs .nav-item.active .nav-link {
    background-color: #fff;
    border-color: #b1b1b3 #b1b1b3 #fff; }
  .left.menu .paginate-pagination ul {
    padding: 0;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
    list-style: none; }
    .left.menu .paginate-pagination ul a {
      margin-left: 10px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: transparent;
      background-color: white; }
      .left.menu .paginate-pagination ul a.active {
        background-color: #03a87c; }
    .left.menu .paginate-pagination ul > li:first-child,
    .left.menu .paginate-pagination ul > li:last-child {
      display: none; }

#search-result {
  margin: 20px 0;
  display: block;
  font-size: 21px;
  font-weight: 600; }

/*media queries*/
@media screen and (max-width: 1400px) {
  #dashboard #top .title-responsive {
    display: none; }
  #dashboard #top .title {
    display: block; }
  #dashboard.open #top .left {
    flex: 0 0 30%;
    max-width: 30%; }
  #dashboard.open #top .rigth {
    flex: 0 0 70%;
    max-width: 70%; }
  #dashboard.open #bottom .left {
    padding: 0;
    flex: 0 0 30%;
    max-width: 30%; }
  #dashboard.open #bottom .rigth {
    flex: 0 0 70%;
    max-width: 70%; }
  #dashboard.closed #top .left {
    flex: 0 0 10%;
    max-width: 10%; }
  #dashboard.closed #top .rigth {
    flex: 0 0 90%;
    max-width: 90%; }
  #dashboard.closed #bottom .left {
    padding: 0;
    flex: 0 0 10%;
    max-width: 10%; }
  #dashboard.closed #bottom .rigth {
    flex: 0 0 90%;
    max-width: 90%; } }

@media screen and (max-width: 1000px) {
  #dashboard .rigth,
  #dashboard .left {
    transition: visibility 0s, opacity 0.5s linear; }
  #dashboard .content > .container-fluid {
    padding-right: 0; }
  #dashboard #top .title {
    display: none; }
  #dashboard #top .title-responsive {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    color: #03a87c;
    line-height: 1;
    text-shadow: 0 1px 0 #fff; }
  #dashboard.open #top .left {
    flex: 0 0 100%;
    max-width: 100%; }
  #dashboard.open #top .rigth {
    height: 0;
    visibility: hidden;
    opacity: 0; }
  #dashboard.open #bottom .left {
    flex: 0 0 100%;
    max-width: 100%; }
  #dashboard.open #bottom .rigth {
    display: none;
    /*
          display none qui, evita che la pagina abbia una lunghezza ingiustificata
          ma annulla l'effetto della transizione alla chiusura della sidebar
          */
    height: 0;
    visibility: hidden;
    opacity: 0; }
  #dashboard.closed #top .rigth {
    height: 0;
    visibility: hidden;
    opacity: 0; }
  #dashboard.closed #top .left {
    flex: 0 0 100%;
    max-width: 100%; }
  #dashboard.closed #bottom .left {
    height: 0;
    visibility: hidden;
    opacity: 0; }
  #dashboard.closed #bottom .rigth {
    padding-left: 0%;
    flex: 0 0 100%;
    max-width: 100%; } }

body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: left;
  overflow-x: hidden; }

ol {
  font-size: 1.15em;
  font-weight: 600;
  color: #b5c5d7; }
  ol a {
    font-weight: 400; }

#content {
  /*min-height: 55vh;*/
  display: inline-block;
  width: 100%; }

#hamburger {
  display: flex; }

#carouselExampleIndicators {
  margin-bottom: 20px; }
  #carouselExampleIndicators .carousel-caption {
    z-index: 1; }

.formazione #corsi .card {
  margin-bottom: 40px; }

.column {
  padding: 0 1em; }

#books-amministrazione {
  margin: 20px 0 0;
  padding: 0;
  list-style: none; }
  #books-amministrazione li {
    margin-bottom: 50px;
    padding-bottom: 10px;
    border-bottom: 3px solid #d6dfe9; }
    #books-amministrazione li img {
      width: 100%; }

.gestione #books-amministrazione .info {
  display: none; }

.catalogo-internal #books-amministrazione .approfondimenti,
.catalogo-internal #books-amministrazione .enter-books {
  display: none; }

body:not(.home) main {
  padding-top: 0;
  padding-bottom: 0; }

#navbarNavB .navbar-nav {
  position: relative; }
  #navbarNavB .navbar-nav > .nav-item:hover {
    background-color: #03a87c; }
    #navbarNavB .navbar-nav > .nav-item:hover > a {
      color: white !important; }

#navbarNavB .nav-item.dropdown.show {
  background-color: #5c6f82; }
  #navbarNavB .nav-item.dropdown.show a.dropdown-toggle {
    color: white !important; }

#navbarNavB .dropdown {
  position: static; }
  #navbarNavB .dropdown .dropdown-menu::before {
    content: '';
    opacity: 0; }
  #navbarNavB .dropdown .dropdown-menu.show {
    position: absolute;
    right: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    border-radius: 0;
    border-top: solid 2px #5c6f82;
    background: #fff; }
  #navbarNavB .dropdown .dropdown-menu a:hover {
    color: #647d72 !important;
    text-decoration: underline; }

.btn-secondary.btn-tooltip-round {
  z-index: 0; }

#sidebar-nav,
#archivio-storico {
  margin-bottom: 40px; }

.info-order {
  padding: 50px;
  border-radius: 4px;
  color: white;
  background-color: #06c; }
  .info-order p.h4 {
    color: white; }
  .info-order img {
    width: 30%; }

.full-page {
  margin-top: 2em; }
  .full-page .listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .full-page .listing-card {
      border-bottom: none; }
    .full-page .listing .read-more {
      display: none; }

.unstyled {
  padding: 0.5rem;
  list-style-type: none; }
  .unstyled li {
    margin-bottom: 0.35em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
    border-bottom: solid 1px #c5d2e0; }
    .unstyled li:last-child {
      border-bottom: none; }
    .unstyled li span {
      align-self: center;
      font-size: 13px;
      text-align: right; }
    .unstyled li a {
      padding: 0.25em 0;
      font-family: "Roboto Slab", Georgia, Cambria, Times, serif;
      font-weight: 500; }

@media screen and (max-width: 768px) {
  .header > div.container {
    max-width: 100%; }
  .header #hamburger {
    justify-content: flex-end; } }

#target {
  position: absolute;
  top: 2000px;
  width: 20px;
  height: calc(100% - 2000px); }

#goUp {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  visibility: hidden;
  font-size: 36px;
  color: white;
  line-height: 65px;
  text-align: center;
  background-color: #03a87c; }
  #goUp.show {
    visibility: visible; }
    #goUp.show:hover {
      opacity: 0.85;
      cursor: pointer; }

@media screen and (max-width: 575px) {
  #carouselExampleIndicators .carousel-item {
    height: 300px; }
  #carouselExampleIndicators .carousel-caption-content {
    max-width: 90vw; }
  .dizionario-normativo {
    min-height: 200px; }
    .dizionario-normativo .ribbon {
      height: 40px; }
  .card-deck#catalogo .card {
    margin-bottom: 15px;
    border-radius: 15px;
    max-height: 70px; }
    .card-deck#catalogo .card img {
      opacity: 1; }
    .card-deck#catalogo .card h5 {
      bottom: 22px;
      margin: 0 auto;
      padding: 0;
      font-size: 1.3em;
      font-weight: 900;
      background-image: none; }
  .services .card-deck .card {
    margin-right: 0;
    margin-bottom: 30px; }
  .home .books .listing .listing-card {
    width: 50%; }
  .news.legislazione-e-dottrina {
    padding: 10px; }
  .border-portlet {
    display: block; } }

@media screen and (max-width: 767px) {
  .logo-container img {
    padding: 10px;
    width: 200px; }
  .box-inserimenti {
    flex-direction: column; }
    .box-inserimenti .tileItem {
      width: 100%; } }

@media screen and (max-width: 1199px) {
  .main-nav .nav-link {
    font-size: 10px !important; } }

@media screen and (max-width: 991px) and (min-width: 576px) {
  .header-utils a .list-inline-item {
    width: 28%; }
  .header-utils a img {
    width: 80px; } }
