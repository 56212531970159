// Simple Page

.simple-page {
  font-family: $font-family-serif;
  font-weight: 300;

  .first-column {
    padding: 3em 2em;
    background-color: $white;

    .documentFirstHeading {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.5;
    }

    .related-contents {
      margin-top: 5vh;

      .documentFirstHeading {
        font-size: 1em;
      }
    }

    h2,
    h3,
    h4,
    h5 {
      font-family: $font-family-sans-serif;
      font-weight: 500;
      line-height: 1.55;
    }

    .documentDescription {
      margin-bottom: 1em;
      font-size: 1.15rem;
      font-weight: 400;
      line-height: 1.55;
    }

    #parent-fieldname-text {
      font-size: 1.05rem;
      line-height: 1.7;
    }

    .documentByLine {
      font-family: $font-family-sans-serif;
      font-size: 12px;
      color: lighten($blue, 20%);
      letter-spacing: 1px;
    }
  }

  .second-column {
    padding: 2em;
    line-height: 1.35;

    .documentFirstHeading {
      margin-bottom: 1em;
      font-family: $font-family-sans-serif;
      font-size: 0.75rem;
      font-weight: 500;
      color: lighten($blue, 50%);
      letter-spacing: 1px;
      line-height: 1.35;
      text-transform: uppercase;

      a {
        color: lighten($blue, 50%);

        &::after {
          display: none;
        }
      }
    }

    h2,
    h3,
    h4,
    h5 {
      font-family: $font-family-sans-serif;
      font-weight: 500;
    }
  }
}

.listing {
  &-card {
    padding: 1em 0.5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-weight: 300;
    border-bottom: 3px solid lighten($blue, 60%);

    p {
      margin-bottom: 5px;
    }

    .author {
      padding: 7px 0;
      font-size: 0.75rem;
      font-weight: 400;
      font-style: italic;
      line-height: 1.15;
    }

    .date {
      margin: 7px 0;
      font-size: 0.65rem;
      font-weight: 300;
      color: lighten($blue, 45%);
    }

    a {
      margin: 5px 0;
      font-weight: 500;
      color: $blue;
    }

    .copertina {
      padding-bottom: 10px;
      max-width: 150px;
      min-width: 120px;
    }
  }
}

.section-legislazione-e-dottrina,
.home,
.legislazione {
  .books {
    width: 100%;
    height: auto;

    .read-more {
      display: none;
    }
  }

  .listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;

    &-card {
      align-self: flex-start;
      width: 150px;
      line-height: 1.25;
      border-bottom: none;

      .copertina {
        height: 180px;
      }

      .info {
        min-width: 150px;

        a {
          margin: 5px 0;
        }
      }
    }
  }
}

.read-more {
  margin: 1.5em 0;
  display: block;
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: lighten($blue, 50%);
  letter-spacing: 1px;
  line-height: 1.5;
  text-align: right;

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: '\f061';
    padding-left: 10px;
    display: inline-block;
    font-family: 'Fontawesome';
    font-size: 0.8em;
  }
}
