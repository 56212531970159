// Custom search
#searchForm {
  input[type='text'] {
    margin-right: 0;
    height: 2rem;
    border: 1px solid $light-grey;
    border-radius: 25px;
    font-size: 0.7em;
    font-weight: 400;
    color: $blue;
    letter-spacing: 1px;
    background-color: $white;
    min-width: 250px;
  }
}

.btn.btn-search {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 35px;
  height: 35px;
  min-width: 35px;
  box-shadow: 0 0 11px #eaf0f6;
  border-radius: 50%;
  text-align: center;
  background-color: $white;

  i {
    font-size: 0.95em;
    font-weight: 700;
    color: $secondary-color;
    background-color: transparent;
  }
}

#catalogo-search {
  margin-bottom: 40px;
  padding: 1.5em;
  border-radius: 5px;
  background-color: $light-grey;

  form {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .form-control {
    margin-right: 0;
    height: 2rem;
    border: 1px solid $light-grey;
    border-radius: 25px;
    font-size: 0.7em;
    font-weight: 400;
    color: #32465c;
    letter-spacing: 1px;
    background-color: white;
    min-width: 40%;
  }
}

.form-control,
.custom-select {
  border: 1px solid darken($light-grey, 10%);
}

.btn-full {
  display: block;
  width: 100%;
}
