.dizionario-normativo {
  position: relative;
  font-size: 0.75em;
  color: $white;
  background: url('/assets/img/ribbon.png') repeat scroll 0 0 $color-primary;
  min-height: 400px;
  max-height: 400px;

  &-text {
    position: absolute;
    bottom: 0;
    padding: 1em;
    width: 100%;
  }

  a {
    color: $white;
  }
}


.ribbon {
  position: absolute;
  top: 0;
  right: 50px;
  width: 20px;
  height: 150px;
  background-color: $yellow;

  &::before {
    content: '';
    z-index: 2;
    position: absolute;
    bottom: -10px;
    left: 0;
    border-left: 10px solid $yellow;
    border-right: 10px solid $yellow;
    border-bottom: 10px solid transparent;
  }
}

.dizionario {
  &-results {
    margin: 2em 0 1.5em;

    h5 {
      font-size: 1.5em;
      font-weight: 600;
    }

    ul {
      max-height: 300px;
      overflow-y: auto;

      li a {
        display: block;
        width: 100%;
      }

      a:hover,
      li.active {
        a {
          padding-left: 10px;
          border-radius: 3px;
          color: $white;
          background: darken($green, 10%);
        }
      }
    }
  }

  &-norma {
    position: relative;
    margin: 0 0 1em;
    padding: 2em;
    border-radius: 5px;
    background: $white;

    &.text-close {
      &::after {
        content: '';
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        min-height: 500px;
        background-image: -moz-linear-gradient(top, rgba(245,248,250,0) 0%, rgba(245,248,250,1) 65%, rgba(245,248,250,1) 100%);
        background-image: -webkit-linear-gradient(top, rgba(245,248,250,0) 0%,rgba(245,248,250,1) 65%,rgba(245,248,250,1) 100%);
        background-image: linear-gradient(to bottom, rgba(245,248,250,0) 0%,rgba(245,248,250,1) 65%,rgba(245,248,250,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f8fa', endColorstr='#f5f8fa',GradientType=0 );
      }
    }
  }

  .register {
    z-index: 2;
    position: relative;
    margin: -150px auto 0;
    padding: 2em 1em;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    border: 1px solid #34598166;
    border-radius: 8px;
    background-color: transparent;
  }
}
