.news {
  margin: 5vh 0;
  padding: 0 0 1em;

  .date {
    font-size: 0.75em;
    font-weight: 500;
  }

  .description {
    font-size: 0.75em;
    font-weight: 300;
  }

  .tileBody {
    line-height: 1.2;
  }
}

.tileItem {
  margin-bottom: 0.5em;
  padding: 1em 0;
}

.tileHeadline {
  margin-bottom: 0.35em;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.3px;

  a {
    font-family: $font-family-serif;
    font-weight: 600;
    color: $blue;
  }
}
