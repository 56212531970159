body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: left;
  overflow-x: hidden;
}

ol {
  font-size: 1.15em;
  font-weight: 600;
  color: darken($light-grey, 10%);

  a {
    font-weight: 400;
  }
}

#content {
  /*min-height: 55vh;*/
  display: inline-block;
  width: 100%;
}

#hamburger {
  display: flex;
}

#carouselExampleIndicators {
  margin-bottom: 20px;

  .carousel-caption {
    z-index: 1;
  }
}

.formazione #corsi {
  .card {
    margin-bottom: 40px;
  }
}

.column {
  padding: 0 1em;
}

#books-amministrazione {
  margin: 20px 0 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 50px;
    padding-bottom: 10px;
    border-bottom: 3px solid $light-grey;

    img {
      width: 100%;
    }
  }
}

.gestione {
  #books-amministrazione {
    .info {
      display: none;
    }
  }
}

.catalogo-internal {
  #books-amministrazione {
    .approfondimenti,
    .enter-books {
      display: none;
    }
  }
}

body:not(.home) main {
  padding-top: 0;
  padding-bottom: 0;
}

#navbarNavB {
  .navbar-nav {
    position: relative;

    > .nav-item:hover {
      background-color: $secondary-color;

      > a {
        color: white !important;
      }
    }
  }

  .nav-item.dropdown.show {
    background-color: #5c6f82;

    a.dropdown-toggle {
      color: white !important;
    }
  }

  .dropdown {
    position: static;

    .dropdown-menu {
      &::before {
        content: '';
        opacity: 0;
      }

      &.show {
        position: absolute;
        right: 0;
        left: 0;
        padding: 20px;
        width: 100%;
        border-radius: 0;
        border-top: solid 2px #5c6f82;
        background: #fff;
      }

      a:hover {
        color: #647d72 !important;
        text-decoration: underline;
      }
    }
  }
}

.btn-secondary.btn-tooltip-round {
  z-index: 0;
}

#sidebar-nav,
#archivio-storico {
  margin-bottom: 40px;
}

.info-order {
  padding: 50px;
  border-radius: $border-radius;
  color: white;
  background-color: #06c;

  p.h4 {
    color: white;
  }

  img {
    width: 30%;
  }
}

.full-page {
  margin-top: 2em;

  .listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &-card {
      border-bottom: none;
    }

    .read-more {
      display: none;
    }
  }
}

.unstyled {
  padding: 0.5rem;
  list-style-type: none;

  li {
    margin-bottom: 0.35em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
    border-bottom: solid 1px darken($light-grey, 5%);

    &:last-child {
      border-bottom: none;
    }

    span {
      align-self: center;
      font-size: 13px;
      text-align: right;
    }

    a {
      padding: 0.25em 0;
      font-family: $font-family-serif;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    > div.container {
      max-width: 100%;
    }

    #hamburger {
      justify-content: flex-end;
    }
  }
}

#target {
  position: absolute;
  top: 2000px;
  width: 20px;
  height: calc(100% - 2000px);
}

#goUp {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  visibility: hidden;
  font-size: 36px;
  color: white;
  line-height: 65px;
  text-align: center;
  background-color: $secondary-color;

  &.show {
    visibility: visible;

    &:hover {
      opacity: 0.85;
      cursor: pointer;
    }
  }
}
