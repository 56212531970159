@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500,500i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700');


// funzioni e variabili colore
@import 'functions';
@import 'utilities/colors_vars';

//variables
@import 'variables';

//classi colore
// @import "utilities/colors";

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

// mixins
@import 'node_modules/bootstrap/scss/mixins';
@import 'custom/mixins/buttons';
@import 'custom/mixins/text-emphasis';

@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/print';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/jumbotron';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/media';
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/utilities';

// Customizzazioni e nuovi componenti
@import 'custom/type';
@import 'custom/grid';
@import 'custom/tables';
@import 'custom/breadcrumb';
@import 'custom/modal';
@import 'custom/collapse';
@import 'custom/carousel';
@import 'custom/list-group';
@import 'custom/alert';
@import 'custom/buttons';
@import 'custom/callout';
@import 'custom/forms';
@import 'custom/password';
@import 'custom/dropdown';
@import 'custom/pager';
@import 'custom/offcanvas';
@import 'custom/tab';
@import 'custom/cookiebar';
@import 'custom/hero';
@import 'custom/forward';
@import 'custom/back-to-top';
@import 'custom/autocomplete';
@import 'custom/linklist';
@import 'custom/navigation';
@import 'custom/skiplinks';
@import 'custom/news';
@import 'custom/dizionario-normativo';
@import 'custom/calendar';
@import 'custom/card';
@import 'custom/simple-page';
@import 'custom/books';
// @import 'custom/megamenu';
@import 'custom/brandhead';
@import 'custom/footer';
@import 'custom/navbar';
@import 'custom/border-portlet';
@import 'custom/search_norme';
@import 'custom/listing';
//signup
@import 'custom/signup';

// componente di esempio
@import 'custom/componente-base';

//gestione dei focus
@import 'utilities/focus';

//dashboard
@import 'custom/dashboard';

@import 'style';
@import 'responsive';
@import 'italia-icon-font.css';
