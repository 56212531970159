.forward {
  display: block;
  font-size: $font-size-base * 3;
  text-align: center;
  transition: $transition-base;

  &:hover {
    text-decoration: none;
  }
}
