.books {
  margin-bottom: 1em;
  padding: 1em;
  width: 100%;
  border: 3px solid lighten($blue, 60%);
  font-family: $font-family-sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  color: $blue;

  &.riviste {
    width: 100%;
    background-color: lighten($blue, 70%);

    .documentFirstHeading {
      padding-left: 1em;
    }
  }

  .listing.horizontal {
    display: flex;
  }
}
