.card {
  margin-right: 1vw;
  margin-right: 1vw;
  margin-bottom: 0;
  box-shadow: 0 0 11px #eaf0f6;
  border: none;
  border-radius: 5px;
  transition: 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 10px 50px -5px rgba(51,71,91,0.12);
    transform: scale(1.08);
  }

  .it-chevron-right {
    padding-left: 5px;
    font-size: 0.8em;
  }

  &-body {
    padding: 1rem 2rem;
  }

  &-img-top {
    border-radius: 5px 5px 0 0;
  }
}

.services {
  padding: 7vh 0;

  .card-title {
    position: absolute;
    margin: 2em 1em 1em 0;
    padding-right: 30px;
    padding-left: 0.5em;
    display: inline-block;
    width: auto;
    height: 30px;
    font-family: $font-family-serif;
    font-size: 1rem;
    color: $white;
    line-height: 30px;
    background: $red;

    &::after {
      content: '';
      position: absolute;
      right: -30px;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-bottom: 30px solid $red;
      border-right: 30px solid transparent;
    }
  }
}

.in-catalogo {
  text-align: center;

  .card {
    position: relative;
    padding: 0;
    align-items: center;
    overflow: hidden;
    border-radius: 5px 5px 0 0;

    img {
      z-index: 0;
      width: 100%;
      height: auto;
    }
  }

  .card-title {
    z-index: 3;
    position: absolute;
    bottom: -18px;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    /* stylelint-disable */
    background-image: -webkit-linear-gradient(bottom,rgba(36,44,53,0.75) 23%,rgba(46,54,64,0.3) 80%,rgba(0,0,0,0) 88%,rgba(0,0,0,0) 100%);
    background-image: -moz-linear-gradient(bottom,rgba(36,44,53,0.75) 23%,rgba(46,54,64,0.3) 80%,rgba(0,0,0,0) 88%,rgba(0,0,0,0) 100%);
    background-image: linear-gradient(to top,rgba(36,44,53,0.75) 23%,rgba(46,54,64,0.3) 80%,rgba(0,0,0,0) 88%,rgba(0,0,0,0) 100%);
    min-height: 100px;

    a {
      width: 100%;
      color: #fff;
      text-align: center;
      line-height: 1.5;
    }
  }
}

.second-column {
  .card {
    margin: 1.5rem 0;

    &-title {
      padding: 0.5rem 1rem 0;
    }

    &-body {
      padding: 1rem 1rem 0;
    }
  }
}

#corsi {
  margin: 2em 0;
}

@media (min-width: 576px) {
  .card-group>.card:not(:first-child),
  .card-group>.card:not(:last-child) {
    border-radius: 5px;
  }
}

.green-card {
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: #cfe6df;
  padding: 1.5em;
}

.grey-card {
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: darken($light-grey, 10%);
  padding: 1.5em;
}

.white-card {
  margin-bottom: 1em;
  border-radius: 5px;
  background-color: $white;
  padding: 1.5em;
}
