.navbar.navbar-institutional {
  padding: 0 1.2rem;
  padding: 0 1.2rem;
  box-shadow: none;
  background-color: $blue;
  background-image: -webkit-gradient(linear,left top, left bottom,from($blue),to(#3673c3))!important;
  background-image: linear-gradient($blue,#3673c3)!important;
  background-image: -webkit-gradient(linear,left top, right top,from($blue),to(#3673c3))!important;
  background-image: linear-gradient(to right,$blue,#3673c3)!important;


  .navbar-brand {
    font-size: 12px;
    font-weight: 300;
  }

  .navbar-nav .nav-link {
    padding: 0 0.75rem;
    font-size: 12px;
    font-weight: 300;
    color: $white;
  }

  .navbar-nav .nav-item {
    @include media-breakpoint-up(md) {
      &.nav-item-w-divider .nav-link {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
    padding: 0.5rem 0;

    &:hover  {
      box-shadow: 0 0.25rem 0 0 $white;
    }
  }
}
