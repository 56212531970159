//menu on bottom animation
@keyframes dropdownFadeIn {
  0% {
    margin-top: 0;
    opacity: 0;
  }

  100% {
    margin-top: $dropdown-menu-vertical-shift;
    opacity: 1;
  }
}
//menu on top animation
@keyframes dropdownFadeInTop {
  0% {
    margin-top: 0;
    opacity: 0;
  }

  100% {
    margin-top: -$dropdown-menu-vertical-shift;
    opacity: 1;
  }
}

.dropdown .btn-group {
  //menu fade in
  &.show {
    .dropdown-menu {
      //menu on bottom
      &[x-placement='bottom-start'] {
        animation: dropdownFadeIn forwards $dropdown-menu-animation-speed;
      }
      //menu on top
      &[x-placement='top-start'] {
        animation: dropdownFadeInTop forwards $dropdown-menu-animation-speed;
      }
    }
  }
}

.btn-dropdown {
  padding: $dropdown-custom-button-padding;
  border-radius: 0;
  font-size: $dropdown-custom-button-font-size;
  color: $dropdown-custom-button-color;
  background-color: $dropdown-custom-button-background;

  &:not(:disabled):not(.disabled):active {
    box-shadow: none;
  }
  //button caret
  &::after {
    content: $dropdown-custom-button-caret-content;
    display: inline-block;
    width: auto;
    height: auto;
    border: none;
    font-family: 'italia-icon-font';
    font-size: $dropdown-custom-button-caret-font-size;
    font-weight: normal;
    font-style: normal;
    vertical-align: 0;
    transition: transform $dropdown-menu-animation-speed;
    speak: none;
  }
  //expanded
  &[aria-expanded=true] {
    &::after {
      transform: scaleY(-1);
    }
  }
}

.dropdown-menu {
  border-radius: 0 0 $dropdown-menu-radius $dropdown-menu-radius;
  //fullwidth menu
  &.full-width {
    width: 100%;

    .link-list li {
      display: inline-block;
      width: auto;

      &:hover,
      &:focus {
        text-decoration: underline;
        background: none;
      }
    }
  }

  //link list finetuning
  .link-list {
    margin-bottom: 0;
    //header
    h3 {
      margin-bottom: 0;
      line-height: $link-list-line-height;
    }
  }

  //notch
  &::before {
    content: '';
    position: absolute;
    top: -$dropdown-menu-notch-base-size;
    left: $dropdown-menu-notch-position-x;
    width: $dropdown-menu-notch-base-size*3;
    height: $dropdown-menu-notch-base-size*3;
    border-radius: $dropdown-menu-radius;
    background-color: $dropdown-bg;
    transform: rotate(45deg);
  }

  //top position
  &[x-placement='top-start'] {
    border-radius: $dropdown-menu-radius $dropdown-menu-radius 0 0;
    //notch
    &::before {
      top: auto;
      bottom: -$dropdown-menu-notch-base-size;
    }
  }

  //dark version
  &.dark {
    background-color: $neutral-1-a8;

    .link-list-wrapper ul {
      span.divider {
        background: #2e465e;
      }

      li {
        //link list colors and hovers
        a span,
        a:hover span,
        h3,
        i {
          color: #fff;
        }

        a:not(.active):not(.disabled):hover i {
          color: $analogue-2-a4;
        }

        a.disabled span {
          color: $neutral-1-a4;
        }
      }
    }
    //notch
    &::before {
      background-color: $neutral-1-a8;
    }
  }
}
