// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

ul.list-group{
  margin-bottom: 40px;
}

.list-group-item {
  padding: $list-group-item-padding-y;

  &.disabled,
  &:disabled {
    cursor: $list-group-disabled-cursor;
  }
}

.list-group-item-action {
  // Hover state
  @include hover-focus {
    text-decoration: underline;
    background-color: $list-group-hover-bg;
  }

  &.disabled,
  &:disabled {
    @include hover-focus {
      border-top-color: transparent;
      text-decoration: none;
      background-color: transparent;
    }
  }
}

.list-group-item.active {
  @include hover-focus {
    background-color: $list-group-active-bg;
  }
}
