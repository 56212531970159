.hero {
  @include border-radius($reset-border-radius);

  @include media-breakpoint-up(sm) {
    padding: $hero-padding;
  }
  margin-bottom: $hero-padding; // TODO - no paddings for margins!
  padding: $hero-padding;
  background-color: $hero-bg;

  .hero-heading { // TODO - this class shouldn't set colors and font properties
    margin-bottom: 0.8rem;
    font-size: 2rem;
    font-weight: 700;
    color: #00264d;
  }
}

.hero-fluid {
  @include border-radius(0);
  padding-right: 0;
  padding-left: 0;
}
