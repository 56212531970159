.carousel {
  padding: 0;
  background-color: $carousel-bg;

  &-item {
    max-height: 400px;

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }

  a {
    color: $white;
  }

  .documentFirstHeading::after {
    display: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  top: 1em;
  align-items: flex-start;
}

.carousel-control-prev {
  right: 6em;
  left: auto;
}

.carousel-control-next {
  right: 3em;
}

.carousel-caption {
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 0.75em;
  text-align: left;
  background: $blue;
  background: linear-gradient(to right,rgba(45, 105, 169, 0.71),rgba(50,71,93,0.48)) !important;
  background-color: $blue;
  background-image: linear-gradient(to right,rgba(45, 105, 169, 0.71),rgba(50,71,93,0.48)) !important;

  &-content {
    margin: 2vh auto;
    max-width: 40vw;

    h5 {
      color: $white;

      a {
        color: $white;
      }
    }

    p {
      margin-bottom: 0;
      font-weight: 300;
      color: $white;

      span {
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }
}

.carousel-indicators li {
  background-color: rgba(18,185,97,0.7);
}

.carousel-indicators .active {
  background-color: $carousel-control-color;
}
