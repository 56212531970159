#search-norme {
  margin-bottom: 2em;
  padding: 15px;
  border-radius: 5px;
  font-size: 12px;
  background-color: $light-grey;

  .nav-tabs {
    margin-bottom: 0.5em;
    padding: 0;
    background-color: $light-grey;

    .nav-item {
      margin-right: 5px;
    }
  }

  .nav-link {
    padding: 5px;
    color: $blue;
  }

  h4 {
    font-size: 20px;
  }

  .form-group {
    padding-top: 0;
  }
}
