.navbar {
  padding: 0;

  .nav-item.active {
    background-color: $secondary-color;

    a {
      color: white !important;
    }
  }
}
